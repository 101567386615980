/* eslint-disable unused-imports/no-unused-vars */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  HomeIcon, MagnifyingGlassIcon, Squares2X2Icon, VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { ROUTES } from '@viize/common';
import {
  AppLogo, Input, useAppContext,
} from '@viize/views';
import { useNavigate } from 'react-router-dom';

export type SiderProps = {
  /**
   * Sider component.
   */
  right?: boolean;
  hidden?: boolean;
  mini?: boolean;
  openStreamDialog?: () => void;
  opentProjectDialog?: () => void;
};

const Sider = ({ hidden = false, opentProjectDialog }: SiderProps) => {
  const navigate = useNavigate();
  const {
    authContext, organizationContext, streamContext, projectContext, groupContext,
  } = useAppContext();

  const { state: authState } = authContext;
  const { state: organizationState } = organizationContext;

  const {
    state: streamState, selectStreamById, setSelectedStream, getStreams,
  } = streamContext;

  const { state: projectState, selectProjectById } = projectContext;
  const { state: groupState, setSelectedGroup } = groupContext;
  const { user } = authState;

  const currentStream = useMemo(
    () => ((streamState?.selected
    && selectStreamById) ? selectStreamById(streamState.selected) : undefined),
    [selectStreamById, streamState?.selected],
  );

  const project = useMemo(
    () => ((projectState.selected && selectProjectById)
      ? selectProjectById(projectState.selected) : undefined),
    [projectState.selected, selectProjectById],
  );

  const organization = useMemo(() => {
    if (authState.user && authState.user.organizations
      && Object.keys(authState.user.organizations).length) {
      const orgId = organizationState.selected ?? currentStream?.organization_id;
      if (orgId) {
        return authState.user.organizations[orgId];
      }
      return Object.values(authState.user.organizations)[0];
    }
    return undefined;
  }, [authState.user, currentStream?.organization_id, organizationState.selected]);

  const [searchQuery, setSearchQuery] = useState('');
  const [streamsLoaded, setStreamsLoaded] = useState(false);

  const handleSelectStream = useCallback((id: string) => {
    if (currentStream && currentStream.id !== id && setSelectedStream) {
      setSelectedStream(id);
      const nextStream = streamState.streams[id];
      navigate(`${ROUTES.live.base}/${nextStream.slug ?? id}`, { replace: true });
    }
  }, [currentStream, setSelectedStream, streamState.streams, navigate]);

  useEffect(() => {
    if (streamsLoaded || !getStreams || !organization || streamState.isLoading
      || streamState.error || projectState.isLoading || groupState.isLoading
    || !currentStream || !project) return;

    setStreamsLoaded(true);
    getStreams(organization.id, project?.id).then(() => { });
  }, [getStreams, groupState.isLoading, organization, project?.id, projectState.isLoading,
    streamState.error, streamState.isLoading, streamsLoaded, currentStream, project]);

  return (
    <div className={`${hidden ? 'w-0' : 'w-1/5'} dark:bg-base-300 dark:text-gray-200 hidden md:flex flex-col h-full overflow-y-hidden overflow-x-hidden transition transform ease-out delay-1000`}>
      <div className="menu menu-sm menu-horizontal flex flex-row p-0 pt-1 justify-between w-full h-[6vh] sticky top-0 z-50 dark:bg-base-200 border-b border-b-gray-200 dark:border-transparent">
        <button onClick={opentProjectDialog} className="select select-sm select-ghost inline-flex ml-1 items-center gap-2 bg-gray-50 dark:bg-inherit hover:bg-gray-200 dark:hover:bg-secondary dark:text-gray-200 w-4/5">
          <AppLogo tiny link="#" width={26} titleClass="text-lg hidden lg:block" subtitleClass="text-lg hidden lg:block" />
          <h4 className="font-semibold">{ user && (user.firstname ?? user.username) }</h4>
        </button>
        <div className="self-end ml-auto inline-flex">
          <a href={ROUTES.welcome.path} className="btn btn-sm btn-circle btn-ghost text-sm">
            <HomeIcon className="h5 w-5" />
          </a>
        </div>
        <Input
          required
          type="text"
          placeholder="Find"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="input-sm dark:text-black bg-transparent input-bordered dark:bg-opacity-90 dark:focus-within:text-white"
          labelClasses="inset-x-0 dark:text-white bg-white px-1 py-0 mt-2 dark:bg-base-300"
          endIcon={<MagnifyingGlassIcon className="w-5 h-5 opacity-70" />}
        />
      </div>
      <ul className="flex flex-col h-[94%] w-full overflow-y-auto overflow-x-hidden pt-14 px-0 gap-2 dark:bg-inherit dark:text-inherit text-sm">

        {/* <li className="hidden hover-bordered">
        <a onClick={openStreamDialog} role="button" tabIndex={0} onKeyDown={undefined}>
          <span className="lg:block text-xs hidden text-gray-500">
            Streams
          </span>
          <span className="inline-flex ml-auto text-gray-500">
            <PlusCircleIcon className="w-4 h-5" />
            <VideoCameraIcon className="w-5 h-5" />
          </span>
        </a>
      </li> */}
        <li className="inline-flex items-center border-b border-b-gray-200 dark:border-b-gray-600 px-2 text-secondary dark:text-gray-400 font-semibold text-xs">
          Sources
          <button className="btn btn-sm btn-circle btn-ghost ml-auto" onClick={() => { }}>
            <Squares2X2Icon className={`h-5 w-5 ${streamState.isLoading ? 'loading' : ''}`} />
          </button>
        </li>
        {Object.values(streamState.streams).reverse().map((stream) => (
          <li
            key={stream.id}
            className={`hover:bg-gray-200 dark:hover:bg-secondary cursor-pointer ${stream.id === currentStream?.id
              ? 'bg-gray-100 dark:bg-secondary' : ''} px-2`}
          >
            <a
              tabIndex={0}
              role="button"
              onKeyDown={undefined}
              className="inline-flex items-center gap-1 rounded-none py-2 w-full"
              // onClick={() => handleSelectStream(stream.id)}
              href={`${ROUTES.live.base}/${stream.slug ?? stream.id}`}
            >
              <VideoCameraIcon className="w-5 h-5" />
              {stream.name}
            </a>
          </li>
        ))}

        <li className="mt-auto border-b border-b-gray-200 dark:border-b-gray-600 px-1 py-0 text-secondary dark:text-gray-400 font-semibold text-xs">Projects</li>
        <li className="hover:bg-gray-200 dark:hover:bg-secondary px-1 cursor-pointer">
          <a onClick={opentProjectDialog} tabIndex={0} role="button" onKeyDown={undefined} className="rounded-none py-1 px-0">
            {project && project.name}
          </a>
        </li>

        <li className="border-b border-b-gray-200 dark:border-b-gray-600 px-1 py-0 text-secondary dark:text-gray-400 font-semibold text-xs">Organization</li>
        <li className="hover:bg-gray-200 dark:hover:bg-secondary px-1 cursor-pointer">
          <a onClick={opentProjectDialog} tabIndex={0} role="button" onKeyDown={undefined} className="rounded-none py-1 px-0">
            {organization && organization.name}
          </a>
        </li>

        <div className="py-0 px-1 bg-transparent">
          <p className="text-xs text-gray-500 dark:text-gray-300">ViizeTraffic v.0.2 </p>
        </div>
      </ul>
    </div>
  );
};

export default Sider;
