import React from 'react';

import { normalizeKey } from '@viize/common';
import {
  AppLogo, LocaleSwitch, LogoutBtn,
} from '@viize/views';
import { useTranslation } from 'react-i18next';

export type DashboardHeaderProps = {
  /**
   * the header component component.
   */
  titleKey?: string
  menuPress?: () => void;
};

export const DashboardHeader = ({ titleKey }: DashboardHeaderProps) => {
  const { t } = useTranslation();
  return (
    <header className="navbar dark:bg-base-200 dark:text-white h-15 min-h-0 py-0 gap-0 border-b border-y-gray-200 w-full">
      <div className="flex gap-2 lg:pr-7">
        <AppLogo width={26} titleClass="text-lg hidden lg:block" subtitleClass="text-lg hidden lg:block" />
      </div>
      <div className="max-w-xs justify-start items-start">
        <a href="" className="text-sm font-normal text-gray-600 dark:text-gray-300">{ titleKey && t(normalizeKey(`dashboard.${titleKey}`))}</a>
      </div>
      <div className="flex-none ml-auto gap-3">
        <LocaleSwitch />
        <LogoutBtn />
      </div>
    </header>
  );
};
