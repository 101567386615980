import {
  setHeaders, createStreamClient, config, delay,
} from '@viize/common';

const { API_URL } = config;

export const uploadMedia = async (organization_id: string, media: File): Promise<string> => {
  const body = new FormData();
  body.append('organization_id', organization_id);
  body.append('media', media);
  return fetch(`${API_URL}/medias`, {
    method: 'POST',
    headers: setHeaders({}),
    body,
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data.id))
    .catch((e) => Promise.reject(e));
};

export const previewStream = async (
  streamId: string,
  wait = false,
): Promise<Response> => fetch(`${API_URL}/streams/${streamId}/preview&wait=${wait}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res.json();
})
  .catch((e) => Promise.reject(e));

export const streamMedia = async (
  streamId: string, // clientid:streamid
  file: File | Blob,
  cbs: {
    onStart?: () => void,
    onEnd?: () => void,
    onErr?: () => void,
    onProgress?: (status: string | number) => void,
  },
): Promise<void> => {
  const streamSleep = 1000;
  const chunkSize = 1024 * 1024 * 2; // 2MB chunk sizes.
  const seek = 0;
  const url = `${`ws_stream/${streamId}&chunk_size=${chunkSize}`}`;
  createStreamClient(streamId, { url }, {
    onError: cbs.onErr,
    onOpen: (socket) => {
      const reader = new FileReader();
      const sendChunck = (start: number) => {
        const nextSlice = start + chunkSize + 1;
        const blob = file.slice(start, nextSlice);

        reader.onloadend = (ev) => {
          if (ev.target?.readyState !== FileReader.DONE || !ev.target.result) {
            return;
          }
          // delay(() => sendChunck(start, Math.min(start + chunkSize, file.size)), streamSleep);
          socket.send(ev.target.result);

          const sizeDone = start + chunkSize;
          const percentDone = Math.floor((sizeDone / file.size) * 100);

          if (nextSlice < file.size) {
            if (cbs.onProgress) cbs.onProgress(`${percentDone}%`);
            sendChunck(nextSlice);
          } else {
            // Update upload progress
            if (cbs.onProgress) cbs.onProgress('100%');
            socket.send(new Blob());
            // socket.send('');
            // socket.close();
            if (cbs.onProgress) cbs.onProgress('checking stream...');
          }
        };
        reader.readAsArrayBuffer(blob);
        // reader.readAsDataURL(blob);
      };
      sendChunck(seek);
      if (cbs.onStart) cbs.onStart();
    },
    onClose: () => {
      if (cbs.onProgress) cbs.onProgress('done !');
      delay(() => cbs.onEnd && cbs.onEnd(), streamSleep);
      // socket.close();
    },
  });
};
