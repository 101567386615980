import React from 'react';

export type ServiceCardProps = {
  /**
   * the service card component.
   */
  name: string,
  icon?: React.ReactChild,
  onClick?: () => void
  disabled?: boolean,
};

const ServiceCard = ({
  name, icon, onClick, disabled,
}: ServiceCardProps) => (
  <a href="#" onClick={disabled ? onClick : undefined} className={` ${disabled ? 'cursor-not-allowed' : 'hover:bg-base-300'} border-b-2 border-b-secondary-content hover:border-secondary flex flex-row gap-1 justify-center align-middle items-center bg-white text-base max-w-fit py-1 px-3 h-7 shadow-md`}>
    <div>
      {icon}
    </div>
    <p>
      {name}
    </p>
  </a>
);

export const ServiceCardNano = ({
  name, icon, onClick, disabled,
}: ServiceCardProps) => (
  <a href="#" onClick={disabled ? onClick : undefined} className={` ${disabled ? 'cursor-not-allowed' : 'hover:bg-base-300'} border-b border-b-secondary-content hover:border-gray-500 flex flex-row gap-1 justify-center align-middle items-center text-sm max-w-fit py-1 px-3 shadow-sm`}>
    <div>
      {icon}
    </div>
    <p>
      {name}
    </p>
  </a>
);

export default ServiceCard;
