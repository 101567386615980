import React from 'react';

import { isKnown } from '@viize/common';

import styles from './.module.css';

export type HistoryItemProps = {
  /**
   * a component to display a detection object
   */
  id?: string | number;
  item: DetectionObject;
  miniature: any;
  onPress?: () => void;
  showImg?: (item: DetectionObject) => void;
};

const HistoryItem = (historyItem: HistoryItemProps) => {
  const {
    id, item, miniature, showImg,
  } = historyItem;
  return (
    <div className="stat  border-t-4 border-t-gray-300 text-xs col-auto py-1 px-2">
      <div className="stat-figure text-info dark:text-black">
        <div className={`avatar ${isKnown(item) ? 'online' : 'offline'}`}>
          <a
            role="button"
            tabIndex={0}
            onKeyDown={undefined}
            className="w-16 h-16 self-end rounded-none"
            onClick={() => showImg && showImg(item)}
          >
            <img
              className={styles.miniature}
              src={miniature}
              alt={String(item.name).substring(0, 16)}
            />
          </a>
        </div>
      </div>
      <div className="stat-desc w-24 text-xs inline-block _stat-value dark:text-black">
        <span className="self-start dark:text-black">{id}</span>
        <span className="px-2 self-end dark:text-black">
          {String(item.conf).substring(0, 4)}
          %
        </span>
        {item.rid && (<span className="text-xs self-end dark:text-black">{item.rid}</span>)}
      </div>
      <div className="stat-desc text-xs inline-block dark:text-black">
        <b>{String(item.name.replace('_', ' ')).substring(0, 20)}</b>
      </div>
      <div className="stat-title text-xs dark:text-black">
        <span>{item.time}</span>
      </div>
    </div>
  );
};

export default HistoryItem;
