export type OrganizationState = {
  organization?: Organization;
  selected?: string;
  isLoading?: boolean;
  error?: any;
};

export enum OrganizationActionsTypes {
  GET_ORGANIZATION = 'GET_ORGANIZATION',
  UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',
  SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  RESET_STATE = 'RESET_STATE',
}

export type OrganizationActionType = {
  type: OrganizationActionsTypes;
  payload?: { [id: string]: OrganizationBase } |
  Partial<Organization> | Partial<OrganizationBase> | string | { id: string } | boolean;
};

export const initialOrganizationState = (): OrganizationState => ({
  organization: undefined,
  selected: undefined,
  isLoading: false,
  error: undefined,
});

export const organizationReducer = (
  state: OrganizationState,
  action: OrganizationActionType,
): OrganizationState => {
  console.debug(action.type, action.payload);
  switch (action.type) {
    case OrganizationActionsTypes.GET_ORGANIZATION: {
      const organization = action.payload as Organization;
      return {
        ...state,
        organization,
        isLoading: false,
      };
    }
    case OrganizationActionsTypes.UPDATE_ORGANIZATION: {
      const organization = action.payload as Organization;
      if (!organization.id) return state;
      return {
        ...state,
        organization: {
          ...state.organization,
          ...organization,
        },
        selected: organization.id,
        isLoading: false,
      };
    }
    case OrganizationActionsTypes.SET_SELECTED_ORGANIZATION: {
      return {
        ...state,
        selected: action.payload as string,
      };
    }
    case OrganizationActionsTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload as boolean, error: undefined };
    case OrganizationActionsTypes.SET_ERROR:
      return { ...state, isLoading: false, error: action.payload as any };
    case OrganizationActionsTypes.RESET_STATE:
      return initialOrganizationState();
    default:
      return state;
  }
};

export interface OrganizationContextStore {
  state: OrganizationState;
  updateOrganization?: (values: Partial<OrganizationBase>, persist?: boolean) => Promise<void>;
  getOrganization?: (id: string) => Promise<OrganizationBase | undefined>;
  setSelectedOrganization?: (id: string) => void;
  setIsLoading?: (loading?: boolean) => void;
  setError?: (error?: any) => void;
  resetState?: () => void;
}
