import React from 'react';

import { useTranslation } from 'react-i18next';

const LocalSwitch = () => {
  const { t, i18n } = useTranslation();
  const changeLocale = ((e: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.target.value);
  });
  return (
    <select name="locale" onChange={changeLocale} value={i18n.language} className="btn btn-ghost select-sm active:bg-transparent bg-transparent active:border-none font-normal  py-2 px-2 border-none rounded-none">
      <option value="en">{t('locales.en')}</option>
      <option value="fr">{t('locales.fr')}</option>
    </select>
  );
};

export default LocalSwitch;
