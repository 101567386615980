import { sortActions } from '@viize/common';

export type StreamState = {
  selected?: string;
  selectedActionId?: ActionId;
  actionsIds?: ActionId[];
  selectedReport?: string,
  streams: { [id: string]: Stream }
  isLoading?: boolean;
  isPlaying?: boolean;
  error?: string;
};

export enum StreamActionsTypes {
  GET_STREAMS = 'GET_STREAMS',
  CREATE_STREAM = 'CREATE_STREAM',
  UPDATE_STREAM = 'UPDATE_STREAM',
  SAVE_SESSION = 'SAVE_SESSION',
  SET_SELECTED_STREAM = 'SET_SELECTED_STREAM',
  SET_SELECTED_ACTION = 'SET_SELECTED_ACTION',
  SET_SELECTED_REPORT = 'SET_SELECTED_REPORT',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_IS_PLAYING = 'SET_IS_PLAYING',
  RESET_STATE = 'RESET_STATE',
}

export type StreamActionType = {
  type: StreamActionsTypes;
  payload?: { [id: string]: Project } | Partial<Stream>
  | string | { id: string } | boolean | ActionId;
};

export const initialStreamState = (): StreamState => ({
  streams: {},
  selected: undefined,
  selectedActionId: undefined, // 'object_detection'
  actionsIds: undefined, // 'object_detection'
  selectedReport: undefined,
  isLoading: false,
  isPlaying: false,
  error: undefined,
});

export const streamReducer = (
  state: StreamState,
  action: StreamActionType,
): StreamState => {
  console.debug(action.type, action.payload);
  switch (action.type) {
    case StreamActionsTypes.GET_STREAMS: {
      const streams = action.payload as { [id: string]: Stream };
      const selected = (Object.keys(streams).length && !state.selected)
        ? Object.keys(streams)[0] : state.selected;
      return {
        ...state,
        streams,
        selected,
        isLoading: false,
      };
    }
    case StreamActionsTypes.CREATE_STREAM: {
      const stream = action.payload as Stream;
      if (!stream.id) return state;
      return {
        ...state,
        streams: {
          ...state.streams,
          [stream.id]: stream,
        },
        selected: stream.id,
        isLoading: false,
      };
    }
    case StreamActionsTypes.UPDATE_STREAM: {
      const stream = action.payload as Stream;
      if (!stream.id) return state;
      const actionsIds = sortActions(stream.actions);
      const actionId = actionsIds[actionsIds.length - 1];
      const selectedActionId = state.selectedActionId ?? actionId;
      const selectedReport = stream.reports?.filter(
        (r) => r.action_name === actionId,
      ).length
        ? stream.reports[0].id : undefined;
      return {
        ...state,
        streams: {
          ...state.streams,
          [stream.id]: {
            ...state.streams[stream.id],
            ...stream,
          },
        },
        selected: stream.id,
        selectedReport: state.selectedReport ?? selectedReport,
        selectedActionId,
        actionsIds,
        isLoading: false,
        error: undefined,
      };
    }
    case StreamActionsTypes.SAVE_SESSION: return state;
    case StreamActionsTypes.SET_SELECTED_STREAM: {
      return {
        ...state,
        selected: action.payload as string,
        // isPlaying: false,
      };
    }
    case StreamActionsTypes.SET_SELECTED_ACTION: {
      return {
        ...state,
        selectedActionId: action.payload as ActionId,
      };
    }
    case StreamActionsTypes.SET_SELECTED_REPORT: {
      return {
        ...state,
        selectedReport: action.payload as string,
      };
    }
    case StreamActionsTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload as boolean, error: undefined };
    case StreamActionsTypes.SET_ERROR:
      return { ...state, isLoading: false, error: action.payload as any };
    case StreamActionsTypes.SET_IS_PLAYING:
      return { ...state, isPlaying: action.payload as boolean };
    case StreamActionsTypes.RESET_STATE:
      return initialStreamState();
    default:
      return state;
  }
};

export interface StreamContextStore {
  state: StreamState;
  createStream?: (values: Partial<Stream>)
  => Promise<{ id: string, dispatch: () => void }>;
  updateStream?: (values: Partial<Stream>, persist?: boolean)
  => Promise<void>;
  getStreamById?: (id: string) => Promise<Stream | undefined>;
  getStreams?: (organizationId: string, projectId?: string, groupId?: string)
  => Promise<{ [id: string]: Stream } | undefined>;
  selectStreamById?: (id: string) => Stream | undefined;
  setSelectedStream?: (id: string) => void;
  setSelectedAction?: (id: ActionId) => void;
  setSelectedReport?: (id: string) => void;
  getStreamReports?: (streamId: string, actionId: ActionId) => Promise<StreamReport[] | []>;
  getStreamReport?: (streamId: string, reportId: string,
    actionId: ActionId | undefined) => Promise<StreamReport | undefined>;
  saveStreamSession?: (streamId: string) => Promise<void>;
  setIsLoading?: (loading?: boolean) => void;
  setError?: (error?: any) => void;
  setIsPlaying?: (playing?: boolean) => void;
  resetState?: () => void;
}
