/* eslint-disable unused-imports/no-unused-vars */
import React from 'react';

import {
  ArrowTopRightOnSquareIcon, PlayIcon, VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { ROUTES } from '@viize/common/config';
import { Link } from 'react-router-dom';

export type SourceCardProps = {
  /**
   * the source card component.
   */
  source: Stream,
  imgSrc?: string,
  onClick?: (id: string) => void
  disabled?: boolean,
};

export const SourceCard = ({
  source, imgSrc, onClick = (id: string) => {}, disabled,
}: SourceCardProps) => (
  <div
    role="button"
    tabIndex={0}
    onKeyDown={undefined}
    onClick={() => onClick(source.id)}
    className={` ${disabled ? 'cursor-not-allowed' : ''} h-2/3 w-3/12 bg-primary-content cursor-default border-b-2 border-b-secondary-content hover:border-secondary flex flex-row flex-wrap gap-1 justify-start align-middle items-center text-black py-3 px-3 shadow-sm`}
  >
    <span className="h-7 w-7 p-1 flex items-center align-middle border rounded-full border-gray-300">
      <VideoCameraIcon className="h-6 w-6 animate-pulse" />
    </span>
    <div className="flex flex-col">
      <p className="text-sm font-semibold">
        {source.name}
      </p>
    </div>
    <Link
      to={`${ROUTES.live.base}/${source.slug ?? source.id}`}
      target="_blank"
      className="ml-auto z-10 btn btn-circle btn-ghost border border-gray-300 hover:bg-gray-300 btn-md"
    >
      <ArrowTopRightOnSquareIcon className="h-6 w-6" />
    </Link>
  </div>
);

export const SourceCardMini = ({
  source, imgSrc, onClick = (id: string) => {}, disabled,
}: SourceCardProps) => (
  <div
    role="button"
    tabIndex={0}
    onKeyDown={undefined}
    onClick={() => onClick(source.id)}
    className={` ${disabled ? 'cursor-not-allowed' : ''} h-full w-full dark:text-gray-200 cursor-pointer dark:hover:bg-[#111111] border-b border-b-gray-500 rounded-none flex flex-col items-center justify-center align-middle text-black _hover:text-white py-0 px-0 shadow-md`}
  >
    {/* <figure className="w-full h-32 bg-secondary">
      <img src="/android-chrome-512x512.png" className="bg-contain w-auto h-32" alt="" />
    </figure> */}
    <div className="flex flex-row flex-wrap gap-1 justify-start items-start w-full px-2">
      <span className="h-6 w-6 p-1 flex items-center align-middle border rounded-full border-gray-300">
        <VideoCameraIcon className="h-6 w-6" />
      </span>
      <p className="text-sm font-semibold">
        {source.name}
      </p>
      <h5 className="ml-auto font-normal text-xs"><PlayIcon className="h-5 w-5" /></h5>
    </div>
  </div>
);
