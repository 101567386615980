/* eslint-disable unused-imports/no-unused-vars */
import React, { useMemo, CSSProperties } from 'react';

import {
  MagnifyingGlassIcon, ChevronUpDownIcon, CalculatorIcon, Square2StackIcon,
} from '@heroicons/react/24/outline';
import {
  getTotalCounts, getTotalKms, getTotalUnknowns, LabelNames,
  formatReportDetections, camelCase, CountsUnits,
  getAverageSpeed, getAverageDuration, getAverageConf,
} from '@viize/common';
import { countingIcons, useAppContext } from '@viize/views';

export type StatsProps = {
/**
 * Stats main component
 */
  style?: CSSProperties,
};

const zeros = '00';
const timeRange = 3;

const noNaN = (n: number) => (Number.isNaN(n) ? zeros : n);

const Stats = (props: StatsProps) => {
  const { streamContext, countingContext } = useAppContext();
  const {
    state: streamState, selectStreamById,
  } = streamContext;
  const { state: countingState } = countingContext;
  const selectedReport = streamState?.selectedReport;
  const isLoading = streamState?.isLoading;
  const isError = streamState?.error;
  const streamId = streamState?.selected;
  const stream = useMemo(
    () => ((streamId && selectStreamById)
      ? selectStreamById(streamId) : undefined),
    [selectStreamById, streamId],
  );
  const selectedActionId = useMemo(
    () => streamState?.selectedActionId,
    [streamState],
  );
  const headActionId = useMemo(
    () => streamState?.actionsIds && streamState?.actionsIds[0],
    [streamState],
  );
  const reports = useMemo(() => stream?.reports ?? [], [stream?.reports]);
  const report = useMemo(
    () => (
      reports.length ? reports.find((r) => r.id === selectedReport) : undefined),
    [reports, selectedReport],
  );

  const reportDets: DetectionObject[] = useMemo(() => {
    let dets: DetectionObject[] = [];
    if (report && report.data && selectedActionId && headActionId) {
      const detsToFormat = Array.isArray(report.data[selectedActionId]?.dets)
        ? report.data[selectedActionId].dets : report.data[headActionId]?.dets ?? [];
      dets = formatReportDetections(detsToFormat, report.session_start);
    }
    return dets;
  }, [report, selectedActionId, headActionId]);

  const totalCounts = useMemo(
    () => (report && selectedActionId
      ? getTotalCounts(report, selectedActionId) : {}),
    [report, selectedActionId],
  );

  const totalDistance = useMemo(
    () => (report ? getTotalKms(reportDets) : 0),
    [reportDets, report],
  );

  const avgSpeed = useMemo(
    () => (report ? getAverageSpeed(reportDets) : 0),
    [reportDets, report],
  );

  const avgDuration = useMemo(
    () => (report ? getAverageDuration(reportDets) : 0),
    [reportDets, report],
  );

  const avgConf = useMemo(
    () => (report ? getAverageConf(reportDets) : 0),
    [reportDets, report],
  );

  const totalUnknowns = useMemo(
    () => (report ? getTotalUnknowns(reportDets) : 0),
    [reportDets, report],
  );

  const totalDetections = useMemo(
    () => Math.ceil((Object.values(
      totalCounts,
    ).reduce((prev, curr) => prev + curr, 0)) * 100) / 100,
    [totalCounts],
  );

  const countUnit = selectedActionId && CountsUnits[selectedActionId];

  return (
    <div
      className="h-[80vh] w-full lg:px-4 lg:py-4 _grid _grid-flow-row-dense dark:bg-base-content bg-gray-50"
    >
      <div className="form-control hidden">
        <div className="input-group ">
          <input type="text" placeholder="Search an event" className="input text-xs w-full shadow-none rounded-none border-none" />
          <button className="btn btn-square btn-ghost">
            <MagnifyingGlassIcon className="h-6 w-6 px-0 dark:text-gray-500" />
          </button>
        </div>
      </div>

      <div className="h-auto w-full _stats _shadow bg-transparent grid grid-cols-4 gap-2 p-0 rounded-none">
        <div className="stat bg-white min-w-[12em] h-[8em] pt-4 m-0 shadow border-0">
          <div className="stat-figure">
            {countingIcons[LabelNames.CAR].icon}
          </div>
          <div className="stat-title dark:text-black">Cars</div>
          <div className="stat-value font-normal text-3xl dark:text-gray-700">{ totalCounts[LabelNames.CAR] ?? zeros }</div>
          <div className="stat-desc dark:text-gray-700">{ countUnit }</div>
        </div>
        <div className="stat bg-white min-w-[12em] h-[8em] pt-4 m-0 shadow border-0">
          <div className="stat-figure">
            {countingIcons[LabelNames.TRUCK].icon}
          </div>
          <div className="stat-title dark:text-black">Trucks</div>
          <div className="stat-value font-normal text-3xl dark:text-gray-700">{ totalCounts[LabelNames.TRUCK] ?? zeros }</div>
          <div className="stat-desc dark:text-gray-700">{ countUnit }</div>
        </div>
        <div className="stat bg-white min-w-[12em] h-[8em] pt-4 m-0 shadow border-0">
          <div className="stat-figure">
            {countingIcons[LabelNames.BUS].icon}
          </div>
          <div className="stat-title dark:text-black">Bus</div>
          <div className="stat-value font-normal text-3xl dark:text-gray-700">{ totalCounts[LabelNames.BUS] ?? zeros }</div>
          <div className="stat-desc dark:text-gray-700">{ countUnit }</div>
        </div>
        <div className="stat bg-white min-w-[12em] h-[8em] pt-4 m-0 shadow border-0">
          <div className="stat-figure">
            {countingIcons[LabelNames.UNKNOWN].icon}
          </div>
          <div className="stat-title dark:text-black">Unknowns</div>
          <div className="stat-value font-normal text-3xl dark:text-gray-700">{ totalCounts[LabelNames.UNKNOWN] ?? zeros }</div>
          <div className="stat-desc dark:text-gray-700" />
        </div>
        <div className="stat bg-white min-w-[12em] h-[8em] pt-4 m-0 shadow border-0">
          <div className="stat-figure">
            {countingIcons[LabelNames.PERSON].icon}
          </div>
          <div className="stat-title dark:text-black">Persons</div>
          <div className="stat-value font-normal text-3xl dark:text-gray-700">{ totalCounts[LabelNames.PERSON] ?? zeros }</div>
          <div className="stat-desc dark:text-gray-700">{ countUnit }</div>
        </div>

        <div className="stat bg-white min-w-[12em] h-[8em] pt-4 m-0 shadow border-0">
          <div className="stat-figure">
            {countingIcons[LabelNames.BICYLE].icon}
          </div>
          <div className="stat-title dark:text-black">Bicycle</div>
          <div className="stat-value font-normal text-3xl dark:text-gray-700">{ totalCounts[LabelNames.BICYLE] ?? zeros }</div>
          <div className="stat-desc dark:text-gray-700">{ countUnit }</div>
        </div>
        <div className="stat bg-white min-w-[12em] h-[8em] pt-4 m-0 shadow border-0">
          <div className="stat-figure">
            {countingIcons[LabelNames.MOTORCYCLE].icon}
          </div>
          <div className="stat-title dark:text-black">Motorcyle</div>
          <div className="stat-value font-normal text-3xl dark:text-gray-700">{ totalCounts[LabelNames.MOTORCYCLE] ?? zeros }</div>
          <div className="stat-desc dark:text-gray-700">{ countUnit }</div>
        </div>
        <div className="stat min-w-[12em] h-[8em] pt-3 m-0 justify-self-end shadow bg-white">
          <div className="stat-figure">
            <CalculatorIcon className="h-6 w-6 px-0 dark:text-gray-500" />
          </div>
          <div className="stat-title dark:text-black text-black font-semibold pb-2 animate-pulse">
            Total
            {countUnit ? ` (${countUnit})` : ''}
          </div>
          <div className="stat-value text-2xl min-h-12 mt-1">{totalDetections ?? zeros}</div>
          <div className="stat-desc dark:text-gray-700 hidden _test-error">↗︎ 0 (100%)</div>
          <div className="stat-desc dark:text-gray-700">
            {selectedActionId && camelCase(selectedActionId).replace('_', ' ')}
            (s)
          </div>
        </div>
        <div className="stat min-w-[12em] h-[5.5em] pt-2 m-0 shadow bg-gray-50 place-items-center place-content-center">
          <div className="stat-figure">
            <ChevronUpDownIcon className="h-6 w-6 px-0 dark:text-gray-500 rotate-90" />
          </div>
          <div className="stat-title dark:text-black">
            eqvt. distance
          </div>
          <div className="stat-value animate-pulse font-thin text-3xl dark:text-gray-500">{ noNaN(totalDistance) }</div>
          <div className="stat-desc dark:text-gray-700">km ≈ covered</div>
          <div className="stat-desc dark:text-gray-700 hidden text-error">↘︎ 0 (100%)</div>
        </div>
        <div className="stat min-w-[12em] h-[5.5em] pt-2 m-0 shadow bg-gray-50 place-items-center place-content-center">
          <div className="stat-figure">
            <span>⏱</span>
          </div>
          <div className="stat-title dark:text-black">
            avg. duration
          </div>
          <div className="stat-value animate-pulse font-thin text-3xl dark:text-gray-500">{noNaN(avgDuration)}</div>
          <div className="stat-desc dark:text-gray-700">≈ second(s)</div>
        </div>
        <div className="stat min-w-[12em] h-[5.5em] pt-2 m-0 shadow  bg-gray-50 place-items-center place-content-center">
          <div className="stat-figure">
            <span>≈</span>
          </div>
          <div className="stat-title dark:text-black">
            avg. speed
          </div>
          <div className="stat-value animate-pulse font-thin text-3xl dark:text-gray-500">{noNaN(avgSpeed)}</div>
          <div className="stat-desc dark:text-gray-700">≈ km/h</div>
        </div>
        <div className="stat min-w-[12em] h-[5.5em] pt-2 m-0 shadow bg-gray-50 place-items-center place-content-center">
          <div className="stat-figure">
            <Square2StackIcon className="h-6 w-6 px-0 dark:text-gray-500 rotate-90" />
          </div>
          <div className="stat-title dark:text-black">
            avg. precision
          </div>
          <div className="stat-value animate-pulse font-thin text-3xl dark:text-gray-500">{ noNaN(avgConf) }</div>
          <div className="stat-desc dark:text-gray-700">≈ %</div>
          <div className="stat-desc dark:text-gray-700 hidden text-error">↘︎ 0 (100%)</div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
