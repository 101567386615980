import {
  config, setHeaders, getLocalStorage, setLocalStorage, delLocalStorage, ApiStatusCodes,
} from '@viize/common';

const { API_ROOT_URL } = config;

export const getAuthToken = () => getLocalStorage('authToken');

export const setAuthToken = (token: string, callback?: () => void) => {
  setLocalStorage('authToken', token);
  if (callback) callback();
};

export const login = async (auth: AuthCredential): Promise<LoginResponse> => fetch(`${API_ROOT_URL}/token`, {
  method: 'POST',
  body: new URLSearchParams(auth).toString(),
  headers: setHeaders({ formData: true, authHeader: false }),
})
  .then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
  .then((json) => Promise.resolve(json))
  .catch((e) => Promise.reject(e));

export const logout = (callback?: () => void) => {
  delLocalStorage('authToken');
  if (callback) callback();
  console.info('Logged out');
};

export const getAuthUser = async (): Promise<User> => fetch(`${API_ROOT_URL}/user`, {
  method: 'GET',
  headers: setHeaders({}),
})
  .then(async (res) => {
    if (!res.ok) {
      if (res.status === ApiStatusCodes.NOT_AUTHORIZED) {
        const authError = { status: res.status, detail: res.statusText, severe: true };
        return Promise.reject(authError);
      }
      return Promise.reject(await res.json());
    }
    return res.json();
  })
  .then((json) => Promise.resolve(json.data))
  .catch((e) => Promise.reject(e));

export const updateAuthUser = async (auth: Partial<UserBase>): Promise<string> => fetch(`${API_ROOT_URL}/user`, {
  method: 'POST',
  body: new URLSearchParams(auth).toString(),
  headers: setHeaders({}),
})
  .then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
  .then((json) => Promise.resolve(json.data.token))
  .catch((e) => Promise.reject(e));

export const register = async (values: Partial<UserBase>): Promise<ApiResponse> => fetch(`${API_ROOT_URL}/register`, {
  body: new URLSearchParams(values).toString(),
  method: 'POST',
  headers: setHeaders({ formData: true, authHeader: false }),
})
  .then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
  .then((json) => Promise.resolve(json.data.id))
  .catch((e) => Promise.reject(e));
