import { config, setHeaders } from '@viize/common';

const { API_URL } = config;

export const getProjects = (
  organizationId: string,
): Promise<Project[]> => fetch(`${API_URL}/projects?organization_id=${organizationId}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then((res) => res.json())
  .then((json) => Promise.resolve(json.data))
  .catch((e) => Promise.reject(e));

export const getProject = (
  id: string,
): Promise<Project> => fetch(`${API_URL}/projects/${id}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res.json();
})
  .then((json) => Promise.resolve(json))
  .catch((e) => Promise.reject(e));

export const createProject = async (
  values: Partial<Project>,
): Promise<string> => {
  const body = JSON.stringify(values);
  return fetch(`${API_URL}/projects`, {
    body,
    method: 'POST',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data.id))
    .catch((e) => Promise.reject(e));
};

export const updateProject = async (values: Partial<Project>): Promise<Partial<Project>> => {
  const body = JSON.stringify(values);
  return fetch(`${API_URL}/projects/${values.id}`, {
    body,
    method: 'POST',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data))
    .catch((e) => Promise.reject(e));
};
