import React from 'react';

import { isKnown } from '@viize/common';

import styles from '../.module.css';

export type ActivityItemProps = {
  /**
   * a component to display a detection object
   */
  id?: string | number;
  item: DetectionObject;
  miniature: any;
  onPress?: () => void;
  showImg?: (item: DetectionObject) => void;
};

const ActivityItem = ({
  id, item, miniature, showImg,
}: ActivityItemProps) => (
  <div className="stat bg-opacity-80 dark:text-white border-b-2 dark:border-b-gray-500 text-xs w-full py-1">
    <div className="stat-figure text-info">
      <div className={`avatar ${isKnown(item) ? 'online' : 'offline'}`}>
        <a
          role="button"
          tabIndex={0}
          onKeyDown={undefined}
          className="w-16 h-full self-end rounded-none"
          onClick={() => showImg && showImg(item)}
        >
          <img
            className={styles.miniature}
            src={miniature}
            alt={String(item.name).substring(0, 16)}
          />
        </a>
      </div>
    </div>
    <div className="stat-desc w-24 text-xs inline-block _stat-value">
      <span className="self-start">{id}</span>
      <span className="px-2 self-end">
        {String(item.conf).substring(0, 4)}
        %
      </span>
      { item.rid && (<span className="text-xs self-end">{item.rid}</span>) }
    </div>
    <div className="stat-desc text-xs inline-block">
      <b>{String(item.name.replace('_', ' ')).substring(0, 20)}</b>
    </div>
    <div className="stat-title text-xs">
      <span>{item.time}</span>
    </div>
  </div>
);

export default ActivityItem;
