import React from 'react';

import { PlayIcon, PauseIcon } from '@heroicons/react/24/outline';

export type ScreenTabProps = {
  /**
   * Screen layout tab component
   */
  active?: boolean;
  ajust?: boolean;
  children: React.ReactNode;
};

export const ScreenTab = ({ active, children }: ScreenTabProps) => (
  <div className={`w-full h-full ${active ? 'block' : 'hidden'}`}>
    {children}
  </div>
);

export type ScreenControlsProps = {
  /**
   * Screen controls component.
   */
  isPlaying?: boolean;
};

export const ScreenControls = ({ isPlaying } : ScreenControlsProps) => (
  <div className="flex mt-auto _justify-between justify-center py-1 border-b border-b-gray-200">
    <a><kbd className="kbd bg-transparent text-black bg-gray-100 border-0 rounded-none">◀︎</kbd></a>
    { isPlaying ? (
      <a><kbd className="kbd bg-transparent text-black bg-gray-100 border-0 mx-2 rounded-none"><PauseIcon className="h-6 w-6" /></kbd></a>
    ) : (
      <a><kbd className="kbd bg-transparent text-black bg-gray-100 border-0 mx-2 rounded-none"><PlayIcon className="h-6 w-6" /></kbd></a>
    )}
    <a><kbd className="kbd bg-transparent text-black bg-gray-100 border-0 rounded-none">▶︎</kbd></a>
  </div>
);

export type ScreenProps = {
  /**
   * Screen layout component
   */
  children?: React.ReactNode;
  sider?: React.ReactNode;
  bottom?: React.ReactNode;
  ajust?: boolean;
};

const Screen = ({
  children, sider, bottom,
}: ScreenProps) => (
  <div className="flex flex-row flex-nowrap w-full relative h-full overflow-y-auto overflow-x-hidden">
    <section className="w-4/5 grow flex flex-col h-full relative">
      <div className="h-full w-full">
        {children && children}
      </div>
      <div className="w-full relative mt-auto">
        {bottom && bottom }
      </div>
    </section>
    {sider && (<div className="hidden md:block w-1/5 h-full">{sider}</div>)}
  </div>
);

export default Screen;
