export type CountingState = {
  stats: Countings;
  detections: Activity;
  isCounting?: boolean;
  currentSession?: string;
};

export enum CountingActionsTypes {
  SYNC_COUNTING_STATS = 'SYNC_COUNTING_STATS',
  SYNC_ACTIVITIES = 'SYNC_ACTIVITIES',
  SET_CURRENT_SESSION = 'SET_CURRENT_SESSION',
  SET_IS_COUNTING = 'SET_IS_COUNTING',
  RESET_STATE = 'RESET_STATE',
}

export type CountingActionType = {
  type: CountingActionsTypes;
  payload?: Partial<CountingState> | Countings | CountingLabel[] | boolean | string;
};

export const initialCountingState = (): CountingState => ({
  stats: {},
  detections: {},
  currentSession: undefined,
  isCounting: false,
});

export const countingReducer = (
  state: CountingState,
  action: CountingActionType,
): CountingState => {
  // console.log(action.type, action.payload);
  switch (action.type) {
    case CountingActionsTypes.SYNC_ACTIVITIES: {
      if (!action.payload) return state;
      const { detections, currentSession } = action.payload as CountingState;
      return {
        ...state,
        currentSession,
        detections,
      };
    }
    case CountingActionsTypes.SYNC_COUNTING_STATS: {
      if (!action.payload) return state;
      const { stats, currentSession } = action.payload as CountingState;
      return {
        ...state,
        currentSession,
        stats,
      };
    }
    case CountingActionsTypes.SET_CURRENT_SESSION:
      return {
        ...state,
        currentSession: action.payload as string,
      };
    case CountingActionsTypes.SET_IS_COUNTING:
      return {
        ...state,
        isCounting: action.payload as boolean,
      };
    case CountingActionsTypes.RESET_STATE:
      return initialCountingState();
    default:
      return state;
  }
};

export interface CountingContextStore {
  state: CountingState;
  updateStats?: (results: ActionResults, actionsIds: ActionId[]) => Promise<void>;
  setIsCounting?: (value?: boolean) => void;
  resetState?: () => void;
}
