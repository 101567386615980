import { diff } from 'deep-diff';

export const getROIName = (r: ROI): string => (r.name ?? r.id);

export const getTotalCounts = (r: StreamReport, actionName: string): CountingValues => {
  const totals: CountingValues = {};
  if (!r.data || !r.data[actionName]?.counts) return {};
  Object.values(r.data[actionName].counts).forEach((
    vals,
  ) => Object.entries(vals).forEach(([label, total]) => {
    if (!['in', 'out'].includes(label)) totals[label] = Math.ceil(((totals[label] ?? 0) + total) * 100) / 100;
  }));
  return totals;
};

export const filterDuplicatesDets = (
  r: DetectionObject[],
  compare = 'duration',
): DetectionObject[] => r.filter((d) => (d as any)[compare] !== undefined)
  .filter((obj, index, self) => self.findIndex(
    (t) => t.id === obj.id && ((t as any)[compare] ?? 0) > ((obj as any)[compare] ?? 0),
  ) !== -1 || index === self.findIndex((t) => t.id === obj.id));

export const getTotalknowns = (r: DetectionObject[]):
number => filterDuplicatesDets(r).filter((v) => v.name !== 'unknown').length;

export const getTotalKms = (r: DetectionObject[]):
number => Math.ceil(filterDuplicatesDets(r)
  .reduce((acc, curr) => acc + (curr.speed ?? 0) * (curr.duration ?? 0 / 3600), 0) * 100) / 100;

export const getAverageSpeed = (r: DetectionObject[]):
number => {
  const dets = filterDuplicatesDets(r, 'speed');
  return Math.ceil((dets
    .reduce((acc, curr) => acc + (curr.speed ?? 0), 0) / dets.length ?? 1) * 100) / 100;
};

export const getAverageDuration = (r: DetectionObject[]):
number => {
  const dets = filterDuplicatesDets(r);
  return Math.ceil((dets
    .reduce((acc, curr) => acc + (curr.duration ?? 0), 0) / dets.length ?? 1) * 100) / 100;
};

export const getAverageConf = (r: DetectionObject[]):
number => {
  const dets = filterDuplicatesDets(r, 'conf');
  return Math.ceil((dets
    .reduce((acc, curr) => acc + (
      (parseFloat(String(curr.conf))) ?? 0), 0) / dets.length ?? 1) * 100);
};

export const getTotalUnknowns = (r: DetectionObject[]): number => r.filter((v) => v.name === 'unknown').length;

export const hasChanged = (o1: any, o2: any) => diff(o1, o2) !== undefined;

export const isKnown = (item: DetectionObject) => item.conf >= 0.5 && item.name !== 'unknown';
