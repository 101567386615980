import { createContext, useReducer } from 'react';

import {
  OrganizationActionsTypes, OrganizationContextStore, organizationReducer, initialOrganizationState,
} from '@viize/common';
import * as organizationActions from '@viize/common/api/organizations';

export const useOrganizations = () => {
  const [state, dispatch] = useReducer(organizationReducer, initialOrganizationState());

  const setIsLoading = (loading?: boolean) => dispatch({
    type: OrganizationActionsTypes.SET_IS_LOADING, payload: loading,
  });

  const setError = (error?: any) => dispatch({
    type: OrganizationActionsTypes.SET_ERROR, payload: String(error),
  });

  const setSelectedOrganization = (id: string) => dispatch({
    type: OrganizationActionsTypes.SET_SELECTED_ORGANIZATION, payload: id,
  });

  const resetState = () => dispatch({ type: OrganizationActionsTypes.RESET_STATE });

  const updateOrganization = async (values: Partial<OrganizationBase>, persist?: boolean):
  Promise<void> => {
    setIsLoading(true);
    try {
      if (persist) await organizationActions.updateOrganization(values);
      dispatch({
        type: OrganizationActionsTypes.UPDATE_ORGANIZATION,
        payload: values,
      });
    } catch (error) {
      setError(error);
      throw new Error(`Unable to update organization ${String(error)}`);
    }
  };

  const getOrganization = async (
    id: string,
  ): Promise<OrganizationBase | undefined> => {
    setIsLoading(true);
    try {
      const organization = await organizationActions.getOrganization(id);
      dispatch({ type: OrganizationActionsTypes.UPDATE_ORGANIZATION, payload: organization });
      return organization;
    } catch (error) { setError(error); return undefined; }
  };

  return {
    state,
    setSelectedOrganization,
    updateOrganization,
    getOrganization,
    setIsLoading,
    setError,
    resetState,
  };
};

export const OrganizationContext = createContext<Partial<OrganizationContextStore>>({});

export default useOrganizations;
