import React from 'react';

import { isKnown } from '@viize/common';
import { directions } from '@viize/views';
import Typewriter from 'typewriter-effect';

import styles from './.module.css';

export type HistoryItemPreviewProps = {
  /**
   * a component to display a detection object
   */
  id?: string | number;
  item: DetectionObject;
  miniature: any;
  onPress?: () => void;
};

const HistoryItemPreview = (historyItem: HistoryItemPreviewProps) => {
  const {
    id, item, miniature,
  } = historyItem;
  return (
    <div className="stat text-xs col-auto py-1 px-7">
      <div className="overflow-x-auto w-full">
        <table className="table table-xs w-full">
          <tbody>
            <tr>
              <td className="normal-case px-0 pt-0 pb-3">
                No:
                {' '}
                {id}
              </td>
              <td className="normal-case px-0 pt-0 pb-3">
                is:
                {' '}
                {item.name}
              </td>

              <td className="normal-case px-0 pt-0 pb-3">
                at:
                {' '}
                {item.time}
              </td>
              <td className="normal-case px-0 pt-0 pb-3">
                zone:
                {' '}
                {item.rid}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="indicator avatar w-3/5 h-auto mt-3">
        <span className={`indicator-item border-none badge badge-secondary text-white ${isKnown(item) ? 'bg-green-700' : ''}`}>
          {String(item.conf).substring(0, 4)}
          %
        </span>
        <img
          className={styles.preview}
          src={miniature}
          alt={item.name}
          loading="lazy"
        />
      </div>
      <div className="overflow-x-auto w-full">
        <table className="table table-zebra table-xs w-full">
          <tbody>
            <tr>
              <th className="px-0">
                Details (
                { item.name }
                )
              </th>
              <td className="px-0">
                Duration
                {' '}
                <span className="animate-pulse">
                  {item.duration}
                  ⏱s
                </span>
              </td>
            </tr>
            <tr>
              <td className="px-0 pt-0 pb-1">
                Speed (Estimated)
              </td>
              <td className="px-0 pt-0 pb-1">
                {item.speed}
                {' '}
                km/h
              </td>
            </tr>
            <tr>
              <td className="px-0 pt-0 pb-1">Number plate (VIN)</td>
              <td className="px-0 pt-0 pb-1"><span className="self-right">Not Ready (NA)</span></td>
            </tr>
            <tr>
              <td className="px-0 pt-0 pb-1">Carbon emission (Estimated)</td>
              <td className="px-0 pt-0 pb-1">
                <span className="self-right animate-pulse">
                  {item.co2}
                  {' '}
                  gCO2e ☁
                </span>
              </td>
            </tr>
            <tr>
              <td className="px-0 pt-0 pb-1">Carbon emission (Estimated per VIN)</td>
              <td className="px-0 pt-0 pb-1"><span className="self-right">Not Ready (NA)</span></td>
            </tr>
            {
              item.direction?.length === 2 && (
                <tr>
                  <td className="px-0 pt-0 pb-1">Direction (Estimated)</td>
                  <td className="px-0 pt-0 pb-1 inline-flex w-full">
                    {directions[item.direction[1]].icon('h-4 w-4')}
                    {directions[item.direction[0]].icon('h-4 w-4')}
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
        <hr className="my-3" />
        <h4>
          Description (as
          {' '}
          {item.name}
          ):
        </h4>
        <div className="py-2 italic">
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString('No description available or Not ready (Status: Not Applicable)')
                .start();
            }}
            options={{
              delay: 20,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryItemPreview;
