/* eslint-disable unused-imports/no-unused-vars */
import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';

import { ROUTES, Status, getMiniatureUrl } from '@viize/common';
import {
  LiveHeader as Header, Sider, Monitor, Camvas, Stats, History,
  StreamDialog, Timeline, useSignaling, useAppContext, Dialog,
} from '@viize/views';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

const {
  Menu, Screen, Counter, Activity, ScreenTab, ActivityItemPreview,
} = Monitor;

let streamLoaded = false;
let projectLoaded = false;
let groupLoaded = false;

const Live: FC = () => {
  const { streamId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    projectContext, groupContext, streamContext, camvasContext,
  } = useAppContext();

  const {
    state: projectState, selectProjectById, getProjectById,
  } = projectContext;

  const {
    state: groupState, selectGroupById, getGroupById, setSelectedGroup, getGroups,
  } = groupContext;

  const {
    state: streamState, selectStreamById, updateStream, getStreamById, getStreams,
  } = streamContext;

  const headActionName = useMemo(
    () => streamState?.actionsIds && streamState.actionsIds[0],
    [streamState],
  );

  const { streamConnection, isConnexionOpened } = useSignaling();
  const { state: camvasState, loadROIs } = camvasContext;

  const [sider, setSider] = useState(true);
  const [activeTab, setActiveTab] = useState(parseInt(new URLSearchParams(location.search).get('tab') ?? '0', 10));
  const [activity, toggleActivity] = useState(true);
  const [streamDialogOpen, setStreamDialog] = useState(false);

  const stream = useMemo(
    () => ((streamId && selectStreamById)
      ? selectStreamById(streamId) : undefined),
    [selectStreamById, streamId],
  );
  const projectId = stream?.project_id;
  const groupId = stream?.group_id;

  const project = useMemo(
    () => ((projectId && selectProjectById)
      ? selectProjectById(projectId) : undefined),
    [projectId, selectProjectById],
  );
  const group = useMemo(
    () => ((groupId && selectGroupById)
      ? selectGroupById(groupId) : undefined),
    [groupId, selectGroupById],
  );

  const actionId = useMemo(
    () => (streamState && streamState?.actionsIds
      ? streamState?.actionsIds[streamState.actionsIds.length - 1] : undefined),
    [streamState],
  );

  const isProjectLoading = projectState?.isLoading;
  const isGroupLoading = groupState?.isLoading;
  const isStreamLoading = streamState?.isLoading;
  const isProjectError = projectState?.error;
  const isGroupError = groupState?.error;
  const isStreamError = streamState?.error;

  useEffect(() => {
    async function fetchData() {
      if (isStreamError || streamLoaded || isStreamLoading || !getStreamById
        || !getProjectById) return;
      if (!streamId) {
        navigate(ROUTES.welcome.path);
        return;
      }
      await getStreamById(streamId);
      streamLoaded = true;
    }
    fetchData();
  }, [getProjectById, getStreamById, isStreamError, isStreamLoading, navigate, streamId]);

  useEffect(() => {
    async function fetchData() {
      if (isProjectError || isProjectLoading || isStreamLoading || !projectId || projectLoaded
        || !getProjectById) return;
      await getProjectById(projectId);
      projectLoaded = true;
    }
    fetchData();
  }, [getProjectById, isProjectError, isProjectLoading, isStreamLoading, projectId]);

  useEffect(() => {
    async function fetchData() {
      if (isGroupError || groupLoaded || isStreamLoading || isProjectLoading
        || isGroupLoading || !groupId || groupLoaded || !getGroupById) return;
      await getGroupById(groupId);
      groupLoaded = true;
    }
    fetchData();
  }, [getGroupById, groupId, isGroupError, isGroupLoading, isProjectLoading, isStreamLoading]);

  const editable = stream && stream.is_editable
    && stream.is_active && !streamState?.isLoading;

  const toggleStreamDialog = useCallback(() => setStreamDialog((old) => !old), []);

  const handleTabChange = useCallback((t: number) => {
    setActiveTab(t);
    navigate(`?tab=${t}`);
  }, [navigate]);

  const [detectionObject, setSetectionObject] = useState<DetectionObject>();

  const saveStreamOnEdit = useCallback((data?: Partial<Stream>, persist?: boolean) => {
    if (!updateStream || !camvasState || !streamConnection) return;
    if (!stream || !stream.actions || !headActionName) return;
    let rois = stream.actions[headActionName].counter_config?.rois ?? [];
    rois = data && data.actions
      ? data.actions[headActionName].counter_config?.rois ?? [] : rois;
    const update = {
      ...stream,
      actions: stream.actions ? {
        ...stream.actions,
        [headActionName]: {
          ...stream.actions[headActionName],
          counter_config: {
            ...stream.actions[headActionName].counter_config,
            rois,
          },
        },
      } : stream.actions,
    };
    if (persist && isConnexionOpened()) {
      streamConnection.sendData({
        type: Status.UPDATE_STREAM,
        data: update,
      });
    }
    updateStream(update, false);
  }, [updateStream, headActionName, camvasState, streamConnection, stream, isConnexionOpened]);

  const setupCamvas = useCallback(() => {
    if (!stream || !stream.actions || !loadROIs || !updateStream || !headActionName) return;
    loadROIs(stream, headActionName);
    updateStream(stream, false);
  }, [stream, loadROIs, updateStream, headActionName]);

  return (
    <div className="container flex flex-row flex-nowrap h-screen w-screen dark:bg-base-200 overflow-hidden">
      <Sider hidden={!sider} openStreamDialog={toggleStreamDialog} />
      <main className="flex flex-col flex-nowrap grow h-full w-4/5">
        <Header>
          <Menu
            activeTab={activeTab}
            editable={editable && activeTab === 0}
            toggleTab={(t) => t !== activeTab && handleTabChange(t)}
            toggleActivity={() => { toggleActivity((old) => !old); }}
            toggleHeader={() => { setSider((old) => !old); }}
            onEdit={saveStreamOnEdit}
            showLogo={!sider}
          />
        </Header>
        <Screen
          sider={activity ? (<Activity showDectection={setSetectionObject} />) : null}
          ajust={!sider && !activity}
          bottom={(
            <>
              { activeTab === 0 && (<Counter editable={editable} onEdit={saveStreamOnEdit} />) }
              { activeTab !== 0 && (<Timeline />)}
            </>
            )}
        >
          <ScreenTab active={activeTab === 0}>
            <Camvas onSetup={setupCamvas} />
          </ScreenTab>
          <ScreenTab active={activeTab === 1}>
            <Stats />
          </ScreenTab>
          <ScreenTab active={activeTab === 2}>
            <History />
          </ScreenTab>
        </Screen>

      </main>
      <Dialog
        title={detectionObject?.name}
        visible={detectionObject !== undefined}
        onClose={() => setSetectionObject(undefined)}
      >
        {streamId && detectionObject && actionId && (
          <ActivityItemPreview
            id={detectionObject.id}
            item={detectionObject}
            miniature={getMiniatureUrl(
              streamId,
              actionId,
              detectionObject,
              detectionObject === undefined,
            )}
          />
        ) }
      </Dialog>
      <StreamDialog visible={streamDialogOpen} onClose={toggleStreamDialog} />
    </div>
  );
};

export default Live;
