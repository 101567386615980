import {
  createContext, useCallback, useReducer, useState,
} from 'react';

import {
  AuthActionsTypes, AuthContextStore, authReducer, handleAPIError, initialAuthState,
} from '@viize/common';
import * as authActions from '@viize/common/api/auth';

export const useAuth = () => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState());

  const setIsLoading = (loading?: boolean) => dispatch({
    type: AuthActionsTypes.SET_IS_LOADING, payload: loading,
  });

  const setError = (error?: string) => dispatch({
    type: AuthActionsTypes.SET_ERROR, payload: String(error),
  });

  const resetState = () => dispatch({ type: AuthActionsTypes.RESET_STATE });

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = authActions.getAuthToken();
    return token !== undefined;
  });

  const login = async (values: AuthCredential): Promise<void> => {
    setIsLoading(true);
    try {
      const loginResponse: LoginResponse = await authActions.login(values);
      const token = loginResponse.access_token;
      authActions.setAuthToken(token);
      setIsAuthenticated(true);
      setIsLoading(false);
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`${detail}`);
    }
  };

  const register = async (values: Partial<UserBase>): Promise<void> => {
    setIsLoading(true);
    try {
      await authActions.register(values);
      setIsLoading(false);
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`${detail}`);
    }
  };

  const logout = (): void => {
    authActions.logout();
    setIsAuthenticated(false);
  };

  const setAuthUser = (user: User) => dispatch({
    type: AuthActionsTypes.SET_AUTH_USER, payload: user,
  });

  const getAuthUser = useCallback(async (): Promise<void> => {
    if (!isAuthenticated) return;
    setIsLoading(true);
    try {
      const user = await authActions.getAuthUser();
      if (Array.isArray(user.organizations)) {
        const orgs: { [id: string]: Organization } = {};
        user.organizations.forEach((o) => { orgs[o.id] = o; });
        user.organizations = orgs;
      }
      setAuthUser(user);
    } catch (error: ApiErrorResponse) {
      const { severe, detail } = handleAPIError(error, setError);
      console.log('servere', severe, detail);
      if (severe) {
        logout();
      }
      throw new Error(`${detail}`);
    }
  }, [isAuthenticated]);

  return {
    state,
    login,
    register,
    logout,
    isAuthenticated,
    setIsAuthenticated,
    getAuthUser,
    setAuthUser,
    setIsLoading,
    setError,
    resetState,
  };
};

export const AuthContext = createContext<Partial<AuthContextStore>>({});

export default useAuth;
