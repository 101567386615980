import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  PencilSquareIcon, PresentationChartBarIcon, CheckIcon,
  TrashIcon, ChevronLeftIcon, EyeIcon, EyeSlashIcon,
} from '@heroicons/react/24/outline';
import {
  CountingState, CountsUnits, getROIName, hasChanged, StreamState,
} from '@viize/common';
import { countingIcons, directions, useAppContext } from '@viize/views';

export type CounterProps = {
  /**
   * Counter component
   */
  editable?: boolean;
  onEdit?: (data?: Partial<Stream>, persist?: boolean) => void;
};

let previousRoiId: string | undefined;
let shouldUpdate = false;
let currentStreamId: string | undefined;
let currentHeadActionName: ActionId;
const defaultDirection = 1; // TB
const defaultRoiEnabled = true;

const Counter = ({ editable = false, onEdit }: CounterProps) => {
  const { streamContext, camvasContext, countingContext } = useAppContext();
  const { state, selectStreamById } = streamContext;
  const {
    state: camvasState, setSelectedROI, selectROIById,
    updateROI, deleteROI, setCursorType, loadROIs,
  } = camvasContext;

  const { selected: selectedStreamId, selectedActionId, actionsIds } = state as StreamState;
  const stream = useMemo(() => selectStreamById && selectedStreamId
    && selectStreamById(selectedStreamId), [selectStreamById, selectedStreamId]);

  const {
    state: countingState,
  } = countingContext;
  const { stats } = countingState as CountingState;
  const selectedROiId = camvasState?.selectedROiId;
  const rois = useMemo(() => camvasState?.rois, [camvasState]);

  const [isEditing, setIsEditing] = useState(false);
  const [roiName, setRoiName] = useState<string>('');
  const [direction, setCurrentDirection] = useState<number>(defaultDirection);
  const [enabled, setEnaled] = useState<boolean>(defaultRoiEnabled);

  const editedRoiName = useMemo(() => roiName ?? (selectROIById
    && selectedROiId && selectROIById(selectedROiId)), [roiName, selectROIById, selectedROiId]);
  const resetInputs = (roi?: ROI) => {
    if (roi) {
      setRoiName(getROIName(roi));
      setCurrentDirection(roi?.direction ?? defaultDirection);
      setEnaled(roi.enabled !== undefined ? roi.enabled : defaultRoiEnabled);
      setIsEditing(false);
      previousRoiId = roi.id;
      return;
    }
    setRoiName('');
    setCurrentDirection(defaultDirection);
    setEnaled(defaultRoiEnabled);
    setIsEditing(false);
    previousRoiId = undefined;
  };

  const handleSelectChange = useCallback(
    (e) => {
      if (!stream || !setSelectedROI) return;
      const roiId = e.target.value;
      setSelectedROI(roiId);
    },
    [setSelectedROI, stream],
  );
  const handleUpdateName = useCallback((e) => setRoiName && setRoiName(e.target.value), []);

  const toggleROIEnabled = useCallback(() => setEnaled((old) => (!old)), []);
  const toggleCountingLabel = useCallback(() => {
    // TODO
  }, []);

  const toggleEdit = useCallback((edit: boolean) => {
    setIsEditing(edit);
    if (setCursorType) setCursorType(edit ? 'polygon' : 'None');
  }, [setCursorType]);

  const handleEditSave = useCallback(() => {
    if (typeof updateROI === 'function' && selectedROiId && selectROIById) {
      const selectedROI = selectROIById(selectedROiId);
      if (!selectedROI) return;
      const values: ROI = {
        ...selectedROI,
        direction,
        enabled,
      };
      values.name = editedRoiName ?? getROIName(selectedROI);
      updateROI(values);
      shouldUpdate = true;
      resetInputs(values);
    }
  }, [updateROI, selectedROiId, selectROIById, direction, enabled, editedRoiName]);

  const handleDelete = useCallback(() => {
    if (typeof deleteROI === 'function' && selectedROiId !== undefined) {
      deleteROI(selectedROiId);
      resetInputs();
      shouldUpdate = true;
    }
  }, [deleteROI, selectedROiId]);

  const handleDirectionChange = useCallback(() => {
    setCurrentDirection((old) => (old < directions.length - 1 ? old + 1 : 0));
  }, []);

  useEffect(() => {
    if (!stream || !stream.actions || !rois || !loadROIs || !actionsIds) return;
    const headActionName = actionsIds[0];
    if (stream.id !== currentStreamId || headActionName !== currentHeadActionName) {
      loadROIs(stream, headActionName);
      currentStreamId = stream.id;
      currentHeadActionName = headActionName;
    }
  }, [loadROIs, rois, actionsIds, stream]);

  useEffect(() => {
    if (selectedROiId === undefined || !selectROIById || isEditing) return;
    if (selectedROiId !== previousRoiId) {
      const selectedROI = selectROIById(selectedROiId);
      if (selectedROI) {
        resetInputs(selectedROI);
        shouldUpdate = true;
      }
    }
  }, [isEditing, selectROIById, selectedROiId]);

  useEffect(() => {
    if (!stream || !stream.actions || !rois
      || !shouldUpdate || !onEdit || !actionsIds) return;
    const headActionName = actionsIds[0];
    if (!hasChanged(rois, stream.actions[headActionName].counter_config?.rois ?? [])) return;
    if (onEdit) {
      onEdit({
        actions: {
          [headActionName]: {
            counter_config: { rois },
          },
        },
      });
    }
    shouldUpdate = false;
  }, [onEdit, rois, actionsIds, stream]);

  return (
    <ul className="bg-base-200 dark:bg-base-200 dark:text-white flex flex-nowrap grow menu menu-compact menu-sm menu-horizontal rounded-none overflow-x-auto overflow-y-hidden h-14 min-h-0 items-center gap-1 px-0">
      {(isEditing && selectedROiId !== undefined && rois?.length) ? (
        <>
          <li className="max-w-min">
            <a className="btn btn-ghost rounded-none px-3 py-2" onClick={() => toggleEdit(false)} role="button" tabIndex={0} onKeyDown={undefined}>
              <ChevronLeftIcon className="h-6 w-6 mr-0" />
            </a>
          </li>
          <li className="">
            <a className="btn btn-ghost btn-md border bg-gray-100 border-gray-300 dark:bg-transparent dark:border-gray-50  rounded-none px-0 py-0">
              <input
                type="text"
                placeholder="Enter counter name"
                className={`w-full min-w-max min-h-max rounded-none dark:text-white dark:focus:text-white bg-transparent input-ghost input-md text-xs text-center ${editedRoiName ? 'border-none' : 'border-error'}`}
                onChange={handleUpdateName}
                value={editedRoiName}
                required
              />
            </a>
          </li>
        </>
      )
        : (
          <li className="min-w-max">
            <a className="rounded-none px-0 py-0">
              <select
                className="select rounded-none border-none shadow-none bg-opacity-0 select-md w-full max-w-xs text-center"
                value={selectedROiId ?? -1}
                onChange={handleSelectChange}
              >
                <option disabled value={-1}>Select area</option>
                {rois?.map((r) => (
                  <option key={r.id} value={r.id}>
                    {`${r.id} - ${getROIName(r)}`}
                  </option>
                ))}
              </select>
            </a>
          </li>
        )}

      {isEditing && selectedROiId && (
      <>
        {directions[direction] && (
        <li>
          <a className="btn btn-ghost rounded-none px-3 py-2" onClick={handleDirectionChange} role="button" tabIndex={0} onKeyDown={undefined}>
            {directions[direction].icon()}
            <span className="hidden text-xs lg:block md:block">{ directions[direction].name }</span>
          </a>
        </li>
        )}
        <li>
          <a className="btn btn-ghost rounded-none px-3 py-2" onClick={toggleROIEnabled} role="button" tabIndex={0} onKeyDown={undefined}>
            <input
              type="checkbox"
              checked={enabled}
              onChange={() => {}}
              className="toggle rounded-none toggle-sm dark:[--tglbg:#111] hover:bg-primary dark:bg-white"
            />
            {enabled ? <EyeIcon className="h-4 w-4 px-0 ml-1" /> : <EyeSlashIcon className="h-4 w-4 px-0 ml-1" />}
          </a>
        </li>
      </>
      )}

      {
          selectedActionId && selectedROiId
          && stats[selectedActionId] && stats[selectedActionId][selectedROiId]
          && Object.entries(stats[selectedActionId][selectedROiId]).map(([label, stat]) => (
            <li key={label} className="py-0 px-0">
              <a>
                {isEditing && (
                <input
                  type="checkbox"
                  onChange={() => toggleCountingLabel()}
                  checked={stat.enabled}
                  className="checkbox rounded-none checkbox-sm disabled dark:checkbox-primary"
                />
                )}
                {countingIcons[label] && (
                  <>
                    {countingIcons[label].icon}
                    { !isEditing && (stat.count ?? countingIcons[label].default) }
                  </>
                )}
              </a>
            </li>
          ))
        }

      {!isEditing && selectedActionId && (
      <li>
        <a aria-disabled className="text-xs px-1 bg-transparent cl" role="button" tabIndex={0} onKeyDown={undefined}>
          {CountsUnits[selectedActionId]}
        </a>
      </li>
      ) }

      {(editable && selectedROiId !== undefined && !isEditing) && (
        <li>
          <a className="btn btn-ghost rounded-none px-3 py-2" onClick={() => toggleEdit(true)} role="button" tabIndex={0} onKeyDown={undefined}>
            <PencilSquareIcon className="h-6 w-6 px-0" />
          </a>
        </li>
      )}

      {isEditing && selectedROiId && (
      <>
        <li>
          <a className="btn btn-ghost rounded-none px-3 py-2" onClick={handleDelete} role="button" tabIndex={0} onKeyDown={undefined}>
            <TrashIcon className="h-6 w-6 px-0 text-primary" />
          </a>
        </li>
        <li>
          <a className="btn btn-ghost rounded-none px-3 py-2 hover:bg-primary bg-primary text-white" onClick={handleEditSave} role="button" tabIndex={0} onKeyDown={undefined}>
            <CheckIcon className="h-6 w-6 px-0 " />
          </a>
        </li>
      </>
      )}

      { !isEditing && selectedROiId !== undefined && (
      <li>
        <a className="btn btn-ghost rounded-none px-3 py-2">
          <PresentationChartBarIcon className="h-6 w-6 mr-1" />
        </a>
      </li>
      ) }

    </ul>
  );
};

export default Counter;
