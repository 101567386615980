import { createContext, useReducer } from 'react';

import {
  GroupActionsTypes, GroupContextStore, groupReducer, handleAPIError, initialGroupState,
} from '@viize/common';
import * as groupActions from '@viize/common/api/groups';

export const useGroups = () => {
  const [state, dispatch] = useReducer(groupReducer, initialGroupState());

  const setIsLoading = (loading?: boolean) => dispatch({
    type: GroupActionsTypes.SET_IS_LOADING, payload: loading,
  });

  const setError = (error?: any) => dispatch({
    type: GroupActionsTypes.SET_ERROR, payload: String(error),
  });

  const resetState = () => dispatch({ type: GroupActionsTypes.RESET_STATE });

  const createGroup = async (values: Partial<Group>):
  Promise<{ id: string, dispatch: () => void }> => {
    setIsLoading(true);
    try {
      const group = { // according to the API specs
        name: values.name,
        description: values.description,
      } as Group;
      const id = await groupActions.createGroup(group);

      return {
        id,
        dispatch: () => dispatch({
          type: GroupActionsTypes.CREATE_GROUP,
          payload: { ...group, id },
        }),
      };
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`Unable to create group: ${detail}`);
    }
  };

  const updateGroup = async (values: Partial<Group>, persist?: boolean):
  Promise<void> => {
    setIsLoading(true);
    try {
      if (persist) await groupActions.updateGroup(values);
      dispatch({
        type: GroupActionsTypes.UPDATE_GROUP,
        payload: values,
      });
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`Unable to update group: ${detail}`);
    }
  };

  const getGroups = async (projectId: string): Promise<{ [id: string]: Group } | undefined> => {
    setIsLoading(true);
    try {
      const res = await groupActions.getGroups(projectId);
      const vals: { [id: string]: Group } = {};
      res.forEach((v) => { vals[v.id!] = v; });
      dispatch({ type: GroupActionsTypes.GET_GROUPS, payload: vals });
      return vals;
    } catch (error: ApiErrorResponse) {
      handleAPIError(error, setError);
      return undefined;
    }
  };

  const getGroupById = async (
    id: string,
  ): Promise<Group | undefined> => {
    setIsLoading(true);
    try {
      const group = await groupActions.getGroup(id);
      dispatch({ type: GroupActionsTypes.UPDATE_GROUP, payload: group });
      return group;
    } catch (error: ApiErrorResponse) {
      handleAPIError(error, setError);
      return undefined;
    }
  };

  const setSelectedGroup = (id: string) => dispatch({
    type: GroupActionsTypes.SET_SELECTED_GROUP, payload: id,
  });

  const selectGroupById = (
    id: string,
  ): Group | undefined => Object.values(state.groups)
    .find((v: Group) => v.id === id || v.slug === id);

  return {
    state,
    createGroup,
    updateGroup,
    selectGroupById,
    setSelectedGroup,
    getGroupById,
    getGroups,
    setIsLoading,
    setError,
    resetState,
  };
};

export const GroupContext = createContext<Partial<GroupContextStore>>({});

export default useGroups;
