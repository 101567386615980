import React from 'react';

import {
  useAuth, useOrganizations, useProjects, useGroups, useStreams,
  useCamvas, useCounting,
} from '@viize/views';
import { Outlet } from 'react-router-dom';

const App: React.FC = () => {
  const authContext = useAuth();
  const organizationContext = useOrganizations();
  const projectContext = useProjects();
  const groupContext = useGroups();
  const streamContext = useStreams();
  const camvasContext = useCamvas();
  const countingContext = useCounting();
  return (
    <Outlet context={{
      authContext,
      organizationContext,
      projectContext,
      groupContext,
      streamContext,
      camvasContext,
      countingContext,
    }}
    />
  );
};

export default App;
