import { createContext, useReducer } from 'react';

import {
  DrawingActionsTypes, DrawingContextStore, drawingReducer, initialDrawingState, MAX_ROIS,
} from '@viize/common';

export const useCamvas = () => {
  const [state, dispatch] = useReducer(drawingReducer, initialDrawingState());

  const loadROIs = (stream: Stream, actionId: ActionId): ROI[] => {
    const rois = stream.actions ? stream.actions[actionId].counter_config?.rois ?? [] : [];
    dispatch({ type: DrawingActionsTypes.LOAD_ROIS, payload: rois });
    return rois;
  };

  const createROI = (values: Partial<ROI>) => {
    dispatch({ type: DrawingActionsTypes.CREATE_ROI, payload: values });
  };

  const updateROI = (values: Partial<ROI>) => {
    dispatch({ type: DrawingActionsTypes.UPDATE_ROI, payload: values });
  };

  const deleteROI = (id: string) => {
    dispatch({ type: DrawingActionsTypes.DELETE_ROI, payload: id });
  };

  const setSelectedROI = (id?: string) => {
    dispatch({ type: DrawingActionsTypes.SET_SELECTED_ROI, payload: id });
  };

  const setCursorType = (cursorType?: DrawType) => {
    dispatch({ type: DrawingActionsTypes.SET_CURSOR_TYPE, payload: cursorType });
  };

  const resetState = () => {
    dispatch({ type: DrawingActionsTypes.RESET_STATE });
  };

  const checkIfCanDraw = () => state.rois.length < MAX_ROIS;

  const selectROIById = (id: string): ROI | undefined => state.rois.find(
    (r) => r.id === id,
  );

  return {
    state,
    loadROIs,
    createROI,
    updateROI,
    deleteROI,
    selectROIById,
    setCursorType,
    setSelectedROI,
    resetState,
    checkIfCanDraw,
  };
};

// for now we just have the drawing context inside the camvas
export const CamvasContext = createContext<Partial<DrawingContextStore>>({});

export default useCamvas;
