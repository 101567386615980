import { useEffect, createContext } from 'react';

import { createStreamClient, sleep } from '@viize/common';

export type StreamConnexion = {
  socket: WebSocket;
  sendData: (data: any) => void;
} | undefined;

export interface SignalingContextType {
  streamConnection: StreamConnexion;
  initConnection: (streamId: string | undefined, customParams?: any, callbacks?: any,
    reset?: boolean) => Promise<void>;
  closeConnection: () => void;
  isConnexionOpened: () => boolean;
  isConnexionClosed: () => boolean;
}

let streamConnection: { socket: WebSocket, sendData: (data: any) => void } | undefined;
let currentStreamId: string | undefined;

export const useSignaling = () => {
  const closeConnection = () => {
    if (streamConnection) streamConnection.socket.close();
    streamConnection = undefined;
    console.log('stream connection closed');
  };

  const isConnexionOpened = () => streamConnection !== undefined
    && (streamConnection?.socket.readyState === WebSocket.OPEN
      || streamConnection?.socket.readyState === WebSocket.CONNECTING);

  const isConnexionClosed = () => streamConnection !== undefined
    && (streamConnection?.socket.readyState === WebSocket.CLOSED
      || streamConnection?.socket.readyState === WebSocket.CLOSING);

  const initConnection = async (
    streamId?: string,
    customParams = {},
    callbacks = {},
    reset = false,
  ) => {
    if (currentStreamId) await sleep(15000);
    if (streamId && (currentStreamId !== streamId || reset)) {
      // closeConnection(); // close current connection really?
      console.log('stream connexion attemping...');
      streamConnection = createStreamClient(streamId, customParams, callbacks);
      if (streamConnection) currentStreamId = streamId;
    }
  };

  useEffect(() => () => closeConnection(), []);

  return {
    streamConnection, initConnection, closeConnection, isConnexionOpened, isConnexionClosed,
  };
};

export const SignalingContext = createContext<Partial<SignalingContextType>>({});

export default useSignaling;
