import { config, setHeaders, loadSecureImageFromUrl } from '@viize/common';

const { API_URL } = config;

export const getStreams = async (
  organizationId: string,
  projectId?: string,
  groupId?: string,
): Promise<Stream[]> => {
  let query = `${API_URL}/streams?organization_id=${organizationId}`;
  if (projectId) query += `&project_id=${projectId}`;
  if (groupId) query += `&group_id=${groupId}`;
  return fetch(query, {
    method: 'GET',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data))
    .catch((e) => Promise.reject(e));
};

export const getStream = async (
  id: string,
): Promise<Stream> => fetch(`${API_URL}/streams/${id}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res.json();
})
  .then((json) => Promise.resolve(json))
  .catch((e) => Promise.reject(e));

export const getReports = async (
  streamId: string,
  actionId: ActionId,
): Promise<StreamReport[]> => {
  let url = `${API_URL}/streams/${streamId}/reports`;
  url += `${actionId ? `?action_name=${actionId}` : ''}`;
  return fetch(url, {
    method: 'GET',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data))
    .catch((e) => Promise.reject(e));
};

export const getReport = async (
  streamId: string,
  reportId: string,
  actionId: ActionId | undefined,
): Promise<StreamReport> => {
  let url = `${API_URL}/streams/${streamId}/reports/${reportId}`;
  url += `${actionId ? `?action_name=${actionId}` : ''}`;
  return fetch(url, {
    method: 'GET',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data))
    .catch((e) => Promise.reject(e));
};

export const saveSession = async (
  streamId: string,
): Promise<StreamReport[]> => fetch(`${API_URL}/streams/${streamId}/save`, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res.json();
})
  .then((json) => Promise.resolve(json.data))
  .catch((e) => Promise.reject(e));

export const getMiniature = async (
  streamId: string,
  actionName: string,
  fileName: string,
): Promise<Response> => fetch(`
  ${API_URL}/streams/${streamId}/miniature?action_name=${actionName}&file_name=${fileName}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res;
})
  .catch((e) => Promise.reject(e));

export const getUrlFromB64 = (b64: string, format = 'image/jpeg') => `data:${format};base64,${b64}`;

export const getMiniatureUrl = (
  streamId: string,
  actionName: string,
  detection: DetectionObject,
  lazy = true,
): string => {
  const fileName = detection.file_name;
  if (lazy && detection.img) return getUrlFromB64(detection.img, 'image/webp');
  return `${API_URL}/streams/${streamId}/miniature?action_name=${actionName}&file_name=${fileName}`;
};

export const getSecureMiniatureUrl = (
  streamId: string,
  actionName: string,
  detection: DetectionObject,
  lazy = true,
): string | null => {
  const fileName = detection.file_name;
  if (lazy && detection.img) return getUrlFromB64(detection.img, 'image/webp');
  const url = `${API_URL}/streams/${streamId}/miniature?action_name=${actionName}&file_name=${fileName}`;
  let secUrl = null;
  loadSecureImageFromUrl(url).then((src) => {
    secUrl = src;
  });
  return secUrl;
};

export const createStream = async (
  values: Partial<Stream>,
): Promise<string> => {
  const body = JSON.stringify(values);
  return fetch(`${API_URL}/streams`, {
    body,
    method: 'POST',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data.id))
    .catch((e) => Promise.reject(e));
};

export const updateStream = async (values: Partial<Stream>): Promise<string> => {
  const body = JSON.stringify(values);
  return fetch(`${API_URL}/streams/${values.id}`, {
    body,
    method: 'POST',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data))
    .catch((e) => Promise.reject(e));
};
