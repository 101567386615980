import React, { useMemo } from 'react';

import { getMiniatureUrl } from '@viize/common';
import { useAppContext } from '@viize/views';

import styles from './.module.css';
import ActivityItem from './ActivityItem';

export type ActivityProps = {
  /**
   * a component to render action activity.
   */
  right?: boolean;
  hidden?: boolean;
  mini?: boolean;
  showDectection?: (d: DetectionObject) => void;
};

const Activity = ({ hidden = false, showDectection }: ActivityProps) => {
  const { streamContext, countingContext } = useAppContext();
  const {
    state: streamState,
  } = streamContext;
  const streamId = streamState?.selected;
  const actionId = useMemo(
    () => (streamState && streamState?.actionsIds
      ? streamState?.actionsIds[streamState.actionsIds.length - 1] : undefined),
    [streamState],
  );
  const headActionId = useMemo(
    () => (streamState && streamState?.actionsIds
      ? streamState?.actionsIds[0] : undefined),
    [streamState],
  );
  // const selectedActionId = useMemo(
  //   () => streamState?.selectedActionId,
  //   [streamState],
  // );
  const { state: countingState } = countingContext;
  const detections = countingState?.detections;

  return (
    <aside className={`${hidden ? 'hidden' : 'block'} _bg-base-300 dark:bg-base-300 h-full p-0 transition transform ease-out delay-1000 overflow-hidden`}>
      <nav className="flex menu py-2 text-center compact shadow-sm">
        <p className="text-xs font-semibold text-gray-500 dark:text-gray-300">Activity</p>
      </nav>
      <div className="bg-inherit grid grid-flow-row-dense gap-1 stats h-full w-full snap-proximity justify-center place-content-stretch snap-y overflow-y-auto overflow-x-auto">
        {streamId && actionId && headActionId && detections && detections[headActionId]
          && detections[headActionId].map((det) => (
            <div key={det.file_name} className={styles.detection}>
              <ActivityItem
                id={det.id}
                item={det}
                miniature={getMiniatureUrl(
                  streamId,
                  actionId,
                  det,
                  true,
                )}
                showImg={showDectection}
              />
            </div>
          ))}

        <footer className="mt-auto py-2 mb-7 text-center hidden lg:block">
          <p className="text-xs">(c) Buttertech Inc.</p>
        </footer>
      </div>
    </aside>
  );
};

export default Activity;
