import React, { useCallback } from 'react';

export default function useResizeObserver(
  el: HTMLElement,
  onResize: (size: WidthHeight, initialSize?: WidthHeight) => void,
) {
  const [size, setSize] = React.useState<WidthHeight | undefined>(undefined);

  const observer = React.useRef(
    new ResizeObserver(useCallback((entries) => {
      // Only care about the first element, we expect one element ot be watched
      const newSize: WidthHeight = [entries[0].contentRect.width, entries[0].contentRect.height];
      if (!size) {
        setSize(newSize);
        onResize(newSize, newSize);
      }
      onResize(newSize, size);
    }, [onResize, size])),
  );

  React.useEffect(() => {
    const o = observer.current;
    if (el) observer.current.observe(el);
    return () => {
      if (el) o.unobserve(el);
    };
  }, [el, observer]);

  return { size, setSize };
}
