import React from 'react';

export type BreadcrumbItem = {
  id: string,
  name: string,
  icon?: React.ReactChild,
  link?: string
};

interface BreadcrumbProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string,
  items: BreadcrumbItem[],
  onItemClick?: () => void,
}

export const Breadcrumb = ({
  title, items, onItemClick, ...rest
}: BreadcrumbProps) => (
  <div className="text-base breadcrumbs" {...rest}>
    <ul className="flex max-w-fit flex-wrap">
      {title && (<p className="mr-3 text-sm">{title}</p>)}
      {items.map((item) => (
        <li key={item.id}>
          <a onClick={onItemClick} className="hover:bg-gray-300 font-thin text-blue-900 p-1" href={item.link ?? '#'}>
            { item.icon && item.icon}
            {item.name}
          </a>
        </li>
      )) }
    </ul>
  </div>
);
