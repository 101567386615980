import React from 'react';

import styles from './.module.css';

export type LoaderProps = {
  /**
   * a loader component to use as activity indicator
   */
  visible?: boolean,
  status?: string,
  children?: React.ReactChild
};

const Loading = ({ visible = true, status, children }: LoaderProps) => (
  <>
    {children && children}
    <div
      id="loading-spinner"
      className={`absolute top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center 
      bg-opacity-90 z-50 gap-3 ${visible ? 'visible' : 'hidden'}`}
    >
      <div
        className={`${styles.loadingSpinner} 
        ease-linear rounded-full border-2 border-gray-600 h-10 w-10`}
      />
      <p className="w-1/3 text-center text-gray-600">{ status ?? 'Please wait...' }</p>
    </div>
  </>
);

export default Loading;
