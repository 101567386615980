import React, {
  useCallback, useEffect, useMemo,
} from 'react';

import {
  PlusIcon, Bars3CenterLeftIcon, CheckIcon, SignalIcon,
  ChartBarSquareIcon, DocumentMagnifyingGlassIcon, NoSymbolIcon, Bars3Icon,
} from '@heroicons/react/24/outline';
import { camelCase } from '@viize/common';
import { useAppContext, Select } from '@viize/views';

import { ReactComponent as Spinner } from '../../../assets/loader.svg';

export type MenuProps = {
  /**
   * The menu on top of the camvas
   */
  editable?: boolean;
  activeTab?: number;
  showLogo?: boolean;
  toggleTab?: (index: number) => void;
  toggleActivity?: () => void;
  toggleHeader?: () => void;
  onEdit?: (data?: Partial<Stream>, persist?: boolean) => void;
};

const Menu = ({
  editable = false, activeTab = 0, showLogo, toggleTab = () => { },
  toggleActivity = () => { },
  toggleHeader = () => { },
  onEdit,
}: MenuProps) => {
  const { streamContext, camvasContext } = useAppContext();
  const { state: streamState, setSelectedAction, selectStreamById } = streamContext;
  const { state, checkIfCanDraw, setCursorType } = camvasContext;
  const streamId = streamState?.selected;
  const stream = useMemo(
    () => ((streamId && selectStreamById)
      ? selectStreamById(streamId) : undefined),
    [selectStreamById, streamId],
  );
  const selectedActionId = useMemo(
    () => streamState?.selectedActionId,
    [streamState],
  );
  const actionsIds = useMemo(
    () => streamState?.actionsIds,
    [streamState],
  );
  const cursorType = state?.cursorType;
  const isDrawing = cursorType && cursorType !== 'None';

  const handleMenuAction = useCallback((_event, cursor, save?: boolean) => {
    if (save && onEdit) {
      onEdit(stream, true);
    }
    if (cursor !== cursorType && setCursorType) setCursorType(cursor);
  }, [cursorType, onEdit, setCursorType, stream]);

  const handleActionChange = useCallback((
    value: string,
  ) => setSelectedAction && setSelectedAction(value as ActionId), [setSelectedAction]);

  useEffect(() => {
    if (!streamState?.selected && cursorType !== 'None' && setCursorType) setCursorType('None');
  }, [cursorType, setCursorType, streamState?.selected]);

  return (
    <ul className="flex flex-row gap-2 overflow-x-auto menu horizontal font-semibold px-0">
      {showLogo && (
        <li className="">
          <a className="btn-square rounded-none hover:bg-transparent btn btn-ghost cursor-default" role="button" tabIndex={0} onKeyDown={undefined}>
            <Spinner className="h-7 w-7" />
          </a>
        </li>
      )}
      <li>
        <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={toggleHeader} role="button" tabIndex={0} onKeyDown={undefined}>
          <Bars3Icon className="h-6 w-6" />
        </a>
      </li>
      <li className={`${activeTab === 0 ? 'bg-gray-100 dark:bg-transparent dark:text-blue-400' : ''}`}>
        <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={() => toggleTab(0)} role="button" tabIndex={0} onKeyDown={undefined}>
          <SignalIcon className="h-6 w-6 text-red-500 animate-pulse" />
          Live
        </a>
      </li>
      <li className={`${activeTab === 1 ? 'bg-gray-100 dark:bg-transparent dark:text-blue-400' : ''}`}>
        <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={() => toggleTab(1)} role="button" tabIndex={0} onKeyDown={undefined}>
          <ChartBarSquareIcon className="h-6 w-6" />
          Stats
        </a>
      </li>
      <li className={`${activeTab === 2 ? 'bg-gray-100 dark:bg-transparent dark:text-blue-400' : ''}`}>
        <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={() => toggleTab(2)} role="button" tabIndex={0} onKeyDown={undefined}>
          <DocumentMagnifyingGlassIcon className="h-6 w-6" />
          History
        </a>
      </li>

      {streamId && (
      <li>
        <a className="rounded-none px-0 py-0">
          <Select
            title="Select a view"
            onChange={handleActionChange}
            value={selectedActionId}
            items={actionsIds ?? []}
            displayKeyFunc={(i: ActionId) => camelCase(i).replace('_', ' ')}
          />
        </a>
      </li>
      )}
      { editable && isDrawing && (
        <li className="hidden lg:block xl:block 2xl:block active bg-primary text-white">
          <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={(e) => handleMenuAction(e, 'None', true)} role="button" tabIndex={0} onKeyDown={undefined}>
            <CheckIcon className="h-6 w-6" />
          </a>
        </li>
      )}
      { editable && checkIfCanDraw && checkIfCanDraw() && (
        <li>
            {isDrawing ? (
              <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={(e) => handleMenuAction(e, 'None', false)} role="button" tabIndex={0} onKeyDown={undefined}>
                <NoSymbolIcon className="h-6 w-6" />
              </a>
            ) : (
              <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={(e) => handleMenuAction(e, 'polygon', false)} role="button" tabIndex={0} onKeyDown={undefined}>
                <PlusIcon className="h-6 w-6" />
              </a>
            )}
        </li>
      )}
      <li>
        <a className="btn btn-ghost rounded-none border-none px-3 py-2" onClick={toggleActivity} role="button" tabIndex={0} onKeyDown={undefined}>
          <Bars3CenterLeftIcon className="h-6 w-6" />
        </a>
      </li>
    </ul>
  );
};

export default Menu;
