import React, { CSSProperties } from 'react';

export type SelectProps = {
  /**
   * a select component
   */
  title?: string,
  value?: string,
  onChange: (value: any) => void,
  items: any[],
  idKey?: string,
  valKey?: string,
  displayKey?: any,
  displayKeyFunc?: (item: any) => string,
  style?: CSSProperties
  className?: string,
};

const Select = ({
  title, value, onChange, items,
  idKey, valKey, displayKey, displayKeyFunc, style, className,
}: SelectProps) => (
  <select
    onChange={(e) => onChange(e.target.value)}
    value={value}
    className={`select select-ghost dark:bg-inherit border-none rounded-none 
    shadow-none bg-opacity-0 select-sm text-xs w-[150px] text-inherit ${className}`}
    style={style}
  >
    {title && (<option disabled>{title}</option>)}
    {items.map((item) => (
      <option key={idKey ? item[idKey] : item} value={valKey ? item[valKey] : item}>
        {displayKeyFunc && displayKeyFunc(item)}
        {!displayKeyFunc && displayKey && item[displayKey]}
        {!displayKeyFunc && displayKey === undefined && item}
      </option>
    ))}
  </select>
);

export default Select;
