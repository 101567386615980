import React from 'react';

import FilePlayer, { FilePlayerProps } from 'react-player/file';

import { hlsOptions } from './config';
import './.module.css';

export const playerOptions: FilePlayerProps = {
  playing: true,
  stopOnUnmount: true,
  height: 'inherit',
  width: 'inherit',
  muted: true,
  loaded: 0,
  controls: false,
  loop: true,
  config: {
    hlsOptions,
    // forceVideo: true,
    // forceHLS: true,
    // forceDASH: true,
  },
};
interface StreamProps {
  media?: string
  src: string
  type?: string
}

export type StreamObject = string | string[] | StreamProps[] | MediaStream | undefined;

export type VideoPlayerProps = {
  children?: React.ReactNode,
  options?: FilePlayerProps,
  onPlayerReady?: (player: FilePlayer | null) => void
};

export const playerActions = {
  load: (player: FilePlayer | null, stream?: StreamObject) => {
    player?.setState({
      url: stream, played: 0, loaded: 0, pip: false, playing: player.props.playing,
    });
  },
  play: (player: FilePlayer | null) => {
    player?.setState({ playing: !player?.state.playing });
  },
  stop: (player: FilePlayer | null) => {
    player?.setState({ url: null, playing: false });
  },
};

export const VideoPlayer = React.forwardRef(
  ({ options }: VideoPlayerProps, ref: React.Ref<FilePlayer>) => (
    <FilePlayer
      ref={ref}
      className="react-player"
      {...playerOptions}
      {...options}
    />
  ),
);

export default VideoPlayer;
