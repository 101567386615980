import React from 'react';

import { VideoPlayer } from '@viize/views/components/VideoPlayer/ReactPlayer';
import { useTranslation } from 'react-i18next';

export const Overview = () => {
  const { t } = useTranslation();
  return (
    <div id="overview" className="flex flex-col justify-center items-center gap-3">
      <div className="flex flex-col font-thin gap-y-3 mb-3 px-5 text-center">
        <p className="text-4xl font-bold">{ t('landing.title-1')}</p>
        <p className="text-2xl mt-3">{ t('landing.subtitle-1')}</p>
      </div>
      <VideoPlayer options={{ url: '/videos/welcome1.mp4' }} />
      <div className="flex flex-col font-thin gap-y-3 mt-3 px-5 text-center">
        <p className="text-4xl font-bold">{ t('landing.title-2')}</p>
        <p className="text-2xl mt-3">{ t('landing.subtitle-2')}</p>
      </div>
    </div>
  );
};
