import { ApiStatusCodes } from '@viize/common';
import { t } from 'i18next';

export const handleAPIError = (
  error: ApiErrorResponse,
  callback?: (detail: string) => void,
  from?: string,
): { severe: boolean, detail: string } => {
  console.error(`${from ?? 'ErrorHandler'}`, JSON.stringify(error));
  let { detail, severe } = error;

  if (error.status === ApiStatusCodes.NOT_AUTHORIZED) {
    detail = t('error.401');
  }
  if (!detail) {
    detail = t('error.unavailable');
    severe = true;
  }

  if (callback) callback(detail);
  return { severe, detail };
};
