import React, { FC, useMemo, useState } from 'react';

import {
  AdjustmentsHorizontalIcon,
  ArrowRightIcon, ArrowUpRightIcon, ChartPieIcon, Cog6ToothIcon, CreditCardIcon,
  DocumentChartBarIcon, EyeIcon, MagnifyingGlassIcon, PlusCircleIcon, PlusIcon,
  RectangleStackIcon, RssIcon, TvIcon,
} from '@heroicons/react/24/outline';
import {
  AppContext, Breadcrumb, BreadcrumbItem, Dialog,
  ServiceCard, ServiceCardNano, Switcher,
} from '@viize/views';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import SourcesSection from './sections/sources';

const Welcome: FC = () => {
  const { t } = useTranslation();
  const {
    authContext, organizationContext, projectContext, streamContext,
  } = useOutletContext<AppContext>();
  const { user } = authContext.state;
  const { state: organizationState } = organizationContext;
  const { state: projectState } = projectContext;
  const { state: streamsState } = streamContext;

  const organization = useMemo(
    () => ((organizationState.selected && user?.organizations)
      ? user.organizations[organizationState.selected] : undefined),
    [organizationState.selected, user?.organizations],
  );
  const project = useMemo(
    () => (projectState.selected ? projectState.projects[projectState.selected] : undefined),
    [projectState.projects, projectState.selected],
  );

  const streams = streamsState?.streams;
  const isLoading = streamsState?.isLoading;

  const activeLinks: BreadcrumbItem[] = useMemo(
    () => {
      const links = [];
      if (organization) {
        links.push({ id: organization.id, name: organization.slug ?? organization.name });
      }
      if (project) {
        links.push({ id: project.id, name: project.slug ?? project.name });
      }
      return links;
    },
    [organization, project],
  );

  const [projectDialog, toggleProjectDialog] = useState(false);

  return (
    <div className="flex flex-col w-full min-h-full gap-8 p-3">
      <div className="flex flex-row flex-wrap w-full min-h-14 overflow-hidden -mb-5">
        {activeLinks.length ? (<Breadcrumb title={t('dashboard.breadcrumb')} items={activeLinks} onItemClick={() => toggleProjectDialog(true)} />) : null}
      </div>
      <div className="flex flex-col w-full gap-x-2 gap-y-4 border-b border-gray-200 min-h-60">
        {!activeLinks.length ? (<h1 className="text-xs font-normal">{t('dashboard.menu')}</h1>) : null}
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-4">
          <ServiceCard disabled name={t('dashboard.services.resume')} icon={<EyeIcon className="h-4 w-4" />} />
          <ServiceCard disabled name={t('dashboard.services.find')} icon={<MagnifyingGlassIcon className="h-4 w-4" />} />
          <ServiceCard disabled name={t('dashboard.services.reports')} icon={<DocumentChartBarIcon className="h-4 w-4" />} />
          <ServiceCard disabled name={t('dashboard.services.analytics')} icon={<ChartPieIcon className="h-4 w-4" />} />
          <ServiceCard disabled name={t('dashboard.services.devices')} icon={<PlusIcon className="h-4 w-4" />} />
          <ServiceCard disabled name={t('dashboard.services.billing')} icon={<CreditCardIcon className="h-4 w-4" />} />
          <ServiceCard disabled name={t('dashboard.services.settings')} icon={<Cog6ToothIcon className="h-4 w-4" />} />
          <ServiceCard disabled name={t('dashboard.services.more')} icon={<ArrowRightIcon className="h-4 w-4" />} />
        </div>
      </div>
      <div className="flex flex-col w-full gap-x-2 gap-y-4 py-3 border-t border-gray-300 min-h-60">
        <h1 className="text-xs font-semibold inline-flex gap-1">
          <TvIcon className="h-4 w-4" />
          { t('dashboard.live.live') }
          <RssIcon className={`h-4 w-4 text-primary ${isLoading ? 'loading' : 'animate-pulse'}`} />
        </h1>
        <div className="flex flex-row flex-wrap">
          <ServiceCardNano disabled name={t('dashboard.live.view-all')} icon={<ArrowUpRightIcon className="h-4 w-4" />} />
          <ServiceCardNano disabled name={t('dashboard.live.sources')} icon={<PlusCircleIcon className="h-4 w-4" />} />
          {!Object.keys(streams).length && (<p className="mt-2 px-2 text-xs text-center">{t('dashboard.live.empty')}</p>)}
        </div>
        <SourcesSection organizationId={organization?.id} projectId={project?.id} />
      </div>
      <div className="flex flex-col w-full gap-x-2 gap-y-4 py-3 border-t border-gray-300 min-h-60">
        <h1 className="text-xs font-semibold inline-flex gap-1">
          <RectangleStackIcon className="h-4 w-4" />
          {t('dashboard.activities.activities')}
        </h1>
        <div className="flex flex-row flex-wrap">
          <ServiceCardNano disabled name={t('dashboard.activities.focus-center')} icon={<AdjustmentsHorizontalIcon className="h-4 w-4" />} />
          <p className="mt-2 px-2 text-xs text-center">{t('dashboard.activities.empty')}</p>
        </div>
      </div>
      <Dialog visible={projectDialog} onClose={() => toggleProjectDialog(false)}>
        <Switcher onSwitch={() => toggleProjectDialog(false)} />
      </Dialog>
    </div>
  );
};

export default Welcome;
