const ENV_PREFIX = 'REACT_APP_';

export const setLocalStorage = (key: string, val: string) => localStorage.setItem(key, val);
export const getLocalStorage = (key:
string): string | undefined => localStorage.getItem(key) ?? undefined;
export const delLocalStorage = (key: string) => localStorage.removeItem(key);

export const getConfig = () => {
  const config = { ...process.env };
  const useHTTPS = config.REACT_APP_ENV === 'prod';
  Object.entries(process.env).forEach(([k, v]) => {
    const key = k.startsWith(ENV_PREFIX) ? k.substring(0, 9) ?? k : k;
    const value = (useHTTPS && typeof v === 'string')
      ? v.replaceAll(/^http:\/\//ig, 'https://')
        .replace(/:8080/ig, '')
        .replace(/:80/ig, '')
      : v; // (http|https)
    config[k.substring(ENV_PREFIX.length, k.length) ?? key] = value;
  });
  config.WS_PREFIX = useHTTPS ? 'wss' : 'ws';
  config.STREAM_TYPE = config.STREAM_TYPE ?? 'm3u8';
  config.LOCALE = getLocalStorage('LOCALE') ?? config.LOCALE ?? 'en';
  return config;
};

export const LOGGER_NAME = 'common';

export const config = getConfig();
