import { getLocalStorage } from '../config';

export type CustomHeaders = {
  contentType?: string;
  bearer?: string;
  userAgent?: string;
  authHeader?: boolean;
  formData?: boolean;
};

export const getBearerToken = () => getLocalStorage('authToken');

export const setHeaders = ({
  contentType = 'application/json',
  authHeader = true,
  bearer = undefined,
  formData = false,
  userAgent = undefined,
}: CustomHeaders) => {
  const headers: HeadersInit = {
    'Content-Type': formData ? 'application/x-www-form-urlencoded' : contentType,
    accept: 'application/json',
  };
  if (authHeader) {
    const bearerHeader = bearer ?? getBearerToken();
    if (!bearerHeader) throw new Error('Requested auth header, with missing bearer');
    headers.Authorization = `Bearer ${bearerHeader}`;
  }
  if (userAgent) {
    headers.userAgent = userAgent;
    // 'UserBase-Agent': 'ViizeSdk',
  }
  return headers;
};
