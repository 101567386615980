import React, { useCallback } from 'react';

import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';
import { useAppContext } from '@viize/views';

export const LogoutBtn = () => {
  const { logout } = useAppContext().authContext;
  const handleLogout = useCallback(() => {
    if (logout) logout();
  }, [logout]);
  return (
    <button
      onClick={handleLogout}
      className="btn btn-circle btn-ghost"
    >
      <ArrowLeftStartOnRectangleIcon className="h-6 w-6" />
    </button>
  );
};
