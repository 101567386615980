import { config, setHeaders } from '@viize/common';

const { API_URL } = config;

export const getGroups = async (
  projectId: string,
): Promise<Group[]> => fetch(`${API_URL}/groups&project_id=${projectId}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res.json();
})
  .then((json) => Promise.resolve(json.data))
  .catch((e) => Promise.reject(e));

export const getGroup = async (
  id: string,
): Promise<Group> => fetch(`${API_URL}/groups/${id}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res.json();
})
  .then((json) => Promise.resolve(json))
  .catch((e) => Promise.reject(e));

export const createGroup = async (
  values: Partial<Group>,
): Promise<string> => {
  const body = JSON.stringify(values);
  return fetch(`${API_URL}/groups`, {
    body,
    method: 'POST',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data.id))
    .catch((e) => Promise.reject(e));
};

export const updateGroup = async (values: Partial<Group>): Promise<string> => {
  const body = JSON.stringify(values);
  return fetch(`${API_URL}/groups/update`, {
    body,
    method: 'POST',
    headers: setHeaders({}),
  }).then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
    .then((json) => Promise.resolve(json.data))
    .catch((e) => Promise.reject(e));
};
