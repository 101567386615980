import {
  config, setHeaders,
} from '@viize/common';

const { API_ROOT_URL } = config;

export const sendContactInfos = async (values: Partial<ContactInfos>): Promise<ApiResponse> => fetch(`${API_ROOT_URL}/contact`, {
  body: new URLSearchParams(values).toString(),
  method: 'POST',
  headers: setHeaders({ formData: true, authHeader: false }),
})
  .then(async (res) => {
    if (!res.ok) return Promise.reject(await res.json());
    return res.json();
  })
  .then((json) => Promise.resolve(json.data))
  .catch((e) => Promise.reject(e));
