import React, { useCallback, useEffect, useState } from 'react';

import { AppContext, SourceCard } from '@viize/views';
import { useOutletContext } from 'react-router-dom';

type SourcesSectionProps = {
  organizationId?: string,
  projectId?: string,
};

const SourcesSection = ({ organizationId, projectId }: SourcesSectionProps) => {
  const { streamContext } = useOutletContext<AppContext>();
  const { state, getStreams } = streamContext;
  const streams = state?.streams;
  const isLoading = state?.isLoading;

  const [loaded, setLoaded] = useState<string | undefined>(projectId);

  const handleSourceClick = useCallback((streamId: string) => {
    console.log(streamId);
  }, []);

  useEffect(() => {
    // load streams
    if (isLoading || loaded === projectId || !organizationId || !projectId || !getStreams) return;
    setLoaded(projectId);
    getStreams(organizationId, projectId).then(() => { });
  }, [getStreams, isLoading, loaded, organizationId, projectId]);

  return (
    <div className="flex flex-wrap w-full gap-2">
      {streams && Object.values(streams).map((s) => (
        <SourceCard key={s.id} onClick={handleSourceClick} imgSrc={undefined} source={s} />
      ))}
    </div>
  );
};

export default SourcesSection;
