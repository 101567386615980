import React from 'react';

import { normalizeKey } from '@viize/common';
import { LocaleSwitch, LogoutBtn } from '@viize/views';
import { useTranslation } from 'react-i18next';

export type LiveHeaderProps = {
  /**
   * the header component component.
   */
  titleKey?: string;
  hidden?: boolean;
  menuPress?: () => void;
  children?: React.ReactElement;
};

export const LiveHeader = ({ titleKey, hidden, children }: LiveHeaderProps) => {
  const { t } = useTranslation();
  return (
    <header className={`${hidden ? 'hidden' : ''} navbar dark:bg-base-200 dark:text-white h-11 min-h-0 py-0 gap-0 border-b border-b-gray-200 dark:border-none w-full`}>
      <div className="max-w-xs justify-start items-start">
        <a href="" className="text-sm font-normal text-gray-600 dark:text-gray-300">{ titleKey && t(normalizeKey(`dashboard.${titleKey}`))}</a>
      </div>
      {children && children}
      <div className="flex-none ml-auto gap-3">
        <LocaleSwitch />
        <LogoutBtn />
      </div>
    </header>
  );
};
