export const ROUTES = {
  landing: {
    path: '/',
  },
  login: {
    path: '/login',
  },
  register: {
    path: '/register',
  },
  resetPassword: {
    path: '/login-help',
  },
  welcome: {
    path: '/welcome',
  },
  live: {
    base: '/live',
    path: '/live/:streamId',
  },
  quote: {
    path: 'quote',
  },
  notFound: {
    path: '*',
  },
};
