/* eslint-disable unused-imports/no-unused-vars */
import React, { useMemo, useState, CSSProperties } from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  filterDetectionsByQuery, getMiniatureUrl, formatReportDetections, formatReportDetectionsByActions,
} from '@viize/common';
import { Dialog, useAppContext } from '@viize/views';

import styles from './.module.css';
import HistoryItem from './HistoryItem';
import HistoryItemPreview from './HistoryItemPreview';

export type HistoryProps = {
/**
 * History main component
 */
  style?: CSSProperties,
};

const History = (props: HistoryProps) => {
  const {
    state: streamState, selectStreamById,
  } = useAppContext().streamContext;
  const actionId = useMemo(
    () => (streamState && streamState?.actionsIds
      ? streamState?.actionsIds[streamState.actionsIds.length - 1] : undefined),
    [streamState],
  );
  const headActionId = useMemo(
    () => (streamState && streamState?.actionsIds
      ? streamState?.actionsIds[0] : undefined),
    [streamState],
  );
  const selectedActionId = useMemo(
    () => streamState?.selectedActionId,
    [streamState],
  );
  const selectedReport = useMemo(
    () => streamState?.selectedReport,
    [streamState],
  );
  const isLoading = streamState?.isLoading;
  const isError = streamState?.error;
  const streamId = streamState?.selected;
  const stream = useMemo(
    () => ((streamId && selectStreamById)
      ? selectStreamById(streamId) : undefined),
    [selectStreamById, streamId],
  );
  const reports = useMemo(() => stream?.reports ?? [], [stream?.reports]);
  const report = useMemo(
    () => (
      reports.length ? reports.find((r) => r.id === selectedReport) : undefined),
    [reports, selectedReport],
  );
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [selected, setSelected] = useState<DetectionObject>();
  const formatedReport = useMemo(
    () => report
    && report.data
    && headActionId
    && report.data[headActionId]
    && report.data[headActionId].dets
    && formatReportDetections(report.data[headActionId].dets, report.session_start),
    [report, headActionId],
  );

  const formatedReportForActionId = useMemo(() => report?.data && formatedReport
    && formatReportDetectionsByActions(report.data, formatedReport), [report, formatedReport]);

  const historyList = useMemo(() => formatedReportForActionId
    && filterDetectionsByQuery(
      filterQuery,
      formatedReportForActionId,
    ).reverse(), [filterQuery, formatedReportForActionId]);

  return (
    <div
      className="h-[80vh] w-full gap-2 flex-col flex-wrap px-4 py-4 overflow-y-auto overflow-x-hidden bg-gray-50"
    >
      <div className="form-control flex">
        <div className="input-group text-gray-900 inline-flex">
          <input onChange={(e) => setFilterQuery(e.target.value)} type="text" placeholder="Search an event" className="input text-xs lg:w-2/6 h-8 shadow-none rounded-none border-dashed border-1 border-gray-400 dark:border-black focus-within:border-black focus-within:text-base focus-within:border-2 focus-within:border-double" />
          <button className="btn btn-sm ml-2 btn-square btn-ghost ">
            <MagnifyingGlassIcon className="h-6 w-6 px-0" />
          </button>
        </div>
      </div>

      <div className="w-full max-h-fit mt-3 gap-4 justify-center flex flex-wrap stats bg-transparent rounded-none">
        {streamId && actionId
          && historyList && historyList.map((det) => (
            <div key={det.file_name} className={styles.detection}>
              <HistoryItem
                id={det.id}
                item={det}
                miniature={getMiniatureUrl(
                  streamId,
                  actionId,
                  det,
                  false,
                )}
                showImg={setSelected}
              />
            </div>
        ))}
      </div>
      <Dialog
        title={selected?.name}
        visible={selected !== undefined}
        onClose={() => setSelected(undefined)}
      >
        {streamId && selected && actionId && (
          <HistoryItemPreview
            id={selected.id}
            item={selected}
            miniature={getMiniatureUrl(
              streamId,
              actionId,
              selected,
              false,
            )}
          />
        ) }
      </Dialog>
    </div>
  );
};

export default History;
