/* eslint-disable unused-imports/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState, CSSProperties, useMemo,
} from 'react';

import { EventSystem } from '@pixi/events';
import {
  getConfig, isWSSupported,
} from '@viize/common';
import {
  drawPointShape as drawCursorShape, Splash,
  useAppContext,
  useDrawing, useResizeObserver, useSignaling, VideoPlayer,
} from '@viize/views';
import {
  Application, Renderer, IApplicationOptions, ISystemConstructor, utils,
} from 'pixi.js';

// Skill Hello banner from the console
utils.skipHello();

// eslint-disable-next-line no-underscore-dangle
delete Renderer.__plugins.interaction;

export type CamvasProps = {
  /**
   * Camvas main component
   */
  width?: number,
  height?: number,
  onSetup?: () => void,
  style?: CSSProperties,
};

const {
  LIVE_URL, API_URL, STREAM_TYPE,
} = getConfig();

const config: IApplicationOptions & any = {
  backgroundColor: 0x2c3e50, // Get this from config
  resolution: window.devicePixelRatio || 1,
  antialias: true,
  autoDensity: true,
  autoStart: false,
  sharedTicker: true,
  backgroundAlpha: 0,
  // clearBeforeRender: true,
};

const HIDDEN_CURSOR_BREAKPOINT = 768;

const app = new Application({ ...config });
const STATUS_TEXT = {
  wait: 'Please wait...',
  empty: 'loading...',
  browser: 'Browser not supported',
  unknown: 'Disconnected',
};

const initialStatusText = isWSSupported() ? STATUS_TEXT.empty : STATUS_TEXT.browser;

let drawingListeners: EventListenerOrEventListenerObject[] = [];
let currentStreamId: string | undefined;
let streamConnecting = false;

type VideoPlayerElement = HTMLVideoElement & HTMLImageElement;
type StreamCfg = { image_size: WidthHeight, frame_stream: boolean };

const Camvas: React.FC<CamvasProps> = ({ onSetup }) => {
  const {
    camvasContext, streamContext, countingContext,
  } = useAppContext();
  const {
    state, checkIfCanDraw, resetState,
  } = camvasContext;
  const {
    state: streamState,
    selectStreamById,
    setIsLoading,
    setIsPlaying,
  } = streamContext;
  const { updateStats } = countingContext;

  const containerRef = useRef<HTMLDivElement>(document.createElement('div'));
  const playerRef = useRef<VideoPlayerElement>(null);
  const splashRef = useRef<HTMLDivElement>(document.createElement('div'));

  const cursorType = state?.cursorType;
  const canDraw = checkIfCanDraw && checkIfCanDraw();

  const isLoading = streamState?.isLoading;
  const isPlaying = streamState?.isPlaying;
  const selectedStreamId = streamState?.selected;
  const actionsIds = streamState?.actionsIds;
  const currentStream = (selectStreamById && selectedStreamId)
    ? selectStreamById(selectedStreamId) : undefined;
  const [streamCfg, setStreamCfg] = useState<StreamCfg | undefined>(undefined);

  const {
    cursor, cursorShape, handlePointerDown, handlePointerOut, handleCursorEffect,
  } = useDrawing(app.stage);

  const [isMounted, setIsMounted] = useState(false);
  const [statusText, setStatusText] = useState<string | undefined>(initialStatusText);

  const streamUrl = useMemo(() => {
    if (!currentStream) return '';
    setStatusText(STATUS_TEXT.wait);
    return `${LIVE_URL}/${currentStream.id}.${STREAM_TYPE}`;
  }, [currentStream]);

  const streamPreview = useMemo(() => {
    if (!currentStream?.id) return undefined;
    return `${API_URL}/streams/${currentStream.id}/preview`;
  }, [currentStream?.id]);

  const { initConnection, isConnexionOpened } = useSignaling();

  const onOpen = useCallback(() => {
    console.debug('streamClient: onOpen');
    // if (!isPlaying && setIsPlaying) setIsPlaying(true);
  }, []);

  const onReady = useCallback((cfg: StreamCfg) => {
    console.debug('streamClient: onReady', cfg);
    setStreamCfg(cfg);
    if (cfg.frame_stream && !isPlaying && setIsPlaying) {
      setIsPlaying(true);
      if (playerRef.current && streamPreview) {
        playerRef.current.src = streamPreview;
      }
    }
  }, [isPlaying, setIsPlaying, streamPreview]);

  const onUnknown = useCallback(() => {
    console.debug('streamClient: onUnknown');
    setStatusText(STATUS_TEXT.unknown);
    if (setIsPlaying) setIsPlaying(false);
    if (isLoading && setIsLoading) setIsLoading(false);
  }, [isLoading, setIsLoading, setIsPlaying]);

  const onClose = useCallback(() => {
    console.debug('streamClient: onClose');
    if (setIsPlaying) setIsPlaying(false);
  }, [setIsPlaying]);

  const onError = useCallback((error: any) => {
    console.error('streamClient: onError', error);
    if (setIsPlaying) setIsPlaying(false);
    if (error.msg && error.msg.detail) setStatusText(error.msg.detail);
    if (isLoading && setIsLoading) setIsLoading(false);
  }, [isLoading, setIsLoading, setIsPlaying]);

  const onFrame = useCallback((frame) => {
    if (playerRef.current) {
      URL.revokeObjectURL(playerRef.current.src);
      const blob = new Blob([frame], { type: 'image/webp' });
      playerRef.current.src = URL.createObjectURL(blob);
    }
  }, []);

  const onData = useCallback((data) => updateStats
    && updateStats(data, actionsIds ?? []), [actionsIds, updateStats]);

  useEffect(() => {
    if (!initConnection || !isConnexionOpened || isConnexionOpened()
      || streamConnecting) return;
    streamConnecting = true;
    initConnection(
      selectedStreamId,
      {},
      {
        onOpen, onReady, onData, onFrame, onUnknown, onError, onClose,
      },
      true,
    ).then(() => {
      streamConnecting = false;
      setStatusText(STATUS_TEXT.empty);
    });
  }, [initConnection, isConnexionOpened, onOpen, onReady, onFrame, onData,
    onError, onUnknown, onClose, selectedStreamId]);

  const onResize = useCallback((size: WidthHeight) => {
    if (!streamCfg || !containerRef.current.contains(playerRef.current)) return;
    // if (playerRef.current) {
    //   playerRef.current.style.width = '640px';
    //   playerRef.current.style.height = '480px';
    // }
    app.resize();
    const [w, h] = streamCfg.image_size;
    const stageScale = {
      x: Math.round(size[0]) / w,
      y: Math.round(size[1]) / h,
    };
    app.stage.scale.x = stageScale.x;
    app.stage.scale.y = stageScale.y;

    app.screen.width = w;
    app.screen.height = h;
  }, [streamCfg]);

  const onPlayerReady = useCallback((_player) => {
    console.info('[p] ready');
    if (isLoading && setIsLoading) setIsLoading(false);
  }, [isLoading, setIsLoading]);

  const onPlayerPlay = useCallback(() => {
    console.info('[p] play');
    if (!isPlaying && setIsPlaying) setIsPlaying(true);
  }, [isPlaying, setIsPlaying]);

  const onPlayerError = useCallback((data) => {
    console.warn('[p] error: ', data);
  }, []);

  const internalPlayer = useMemo(() => {
    if (!streamCfg) return playerRef.current;
    if (streamCfg.frame_stream) return (<img ref={playerRef} alt="" />);
    return (
      <VideoPlayer
        playerRef={playerRef}
        onPlay={onPlayerPlay}
        onCanPlay={onPlayerReady}
        onError={onPlayerError}
        src={streamUrl}
        width="100%"
        height="auto"
        autoPlay
        playsInline
        muted
      />
    );
  }, [onPlayerError, onPlayerPlay, onPlayerReady, streamCfg, streamUrl]);

  const { size: camvasSize, setSize } = useResizeObserver(
    playerRef.current as HTMLElement,
    onResize,
  );

  const setup = useCallback(() => {
    if (!('events' in app.renderer)) {
      // @ts-expect-error: Let's pixi js typescript addSystem propery not exist on renderer error
      app.renderer.addSystem(EventSystem as unknown as ISystemConstructor<Renderer>, 'events');
    }
    app.resizeTo = playerRef.current as HTMLElement;
    app.view.className = 'absolute';
    app.stage.hitArea = app.screen;
    app.stage.interactive = true;

    cursor.name = 'cursor';
    app.stage.addChild(cursor);
    drawCursorShape(cursorShape, app.stage.position, 'cursor_shape');
    app.stage.addChild(cursorShape);
    app.stage.addEventListener('pointermove', handleCursorEffect as never);

    if (!containerRef.current.contains(app.view)) {
      containerRef.current.insertBefore(app.view, splashRef.current);
    }
    setIsMounted(true);
    if (onSetup) onSetup();
    console.debug('camvas mounted !');
  }, [cursor, cursorShape, handleCursorEffect, onSetup]);

  const unSetup = useCallback(() => {
    if (!containerRef.current.contains(playerRef.current)) return;
    app.stage.removeAllListeners();
    app.stage.removeChildren();
    app.stage.interactive = false;
    if (containerRef.current.contains(app.view)) containerRef.current.removeChild(app.view);
    setIsMounted(false);
    if (resetState) resetState();
    if (onSetup) onSetup();
    console.info('camvas unmounted !');
  }, [onSetup, resetState]);

  useEffect(() => {
    if (!internalPlayer || isMounted || !streamCfg
      || !containerRef.current.contains(playerRef.current)) return;
    setup();
  }, [streamCfg, internalPlayer, isMounted, setup]);

  useEffect(() => {
    if (!isMounted || !cursorType) return;
    if (cursorType === 'None' || canDraw === false) {
      if (app.stage.listeners('pointerdown').length && drawingListeners.length) {
        app.stage.removeEventListener('pointerdown', drawingListeners[0]);
        app.stage.removeEventListener('pointerupoutside', drawingListeners[1]);
        drawingListeners = [];
      }
      cursorShape.visible = false;
    } else {
      if (!app.stage.listeners('pointerdown').length) {
        drawingListeners = [];
        app.stage.addEventListener('pointerdown', handlePointerDown as EventListenerOrEventListenerObject);
        app.stage.addEventListener('pointerupoutside', handlePointerOut as EventListenerOrEventListenerObject);
        drawingListeners.push(handlePointerDown as EventListenerOrEventListenerObject);
        drawingListeners.push(handlePointerOut as EventListenerOrEventListenerObject);
      }
      // show cursor only on desktop
      if (window.innerWidth > HIDDEN_CURSOR_BREAKPOINT) {
        cursorShape.visible = true;
      }
    }
    // app.ticker.update();
  }, [canDraw, cursorShape, cursorType, handlePointerDown, handlePointerOut, isMounted]);

  useEffect(() => {
    if (!streamCfg || !isMounted) return;
    if (!camvasSize) {
      setSize(undefined);
    } else {
      onResize(camvasSize);
    }
  }, [camvasSize, streamCfg, isMounted, onResize, setSize]);

  useEffect(() => {
    if (currentStream) {
      if (currentStreamId === currentStream.id) return;
      if (isMounted) unSetup();
      currentStreamId = currentStream.id;
    } else if (currentStreamId !== undefined) {
      if (isMounted) unSetup();
      currentStreamId = undefined;
    }
  }, [currentStream, camvasSize, unSetup,
    isMounted, setup, resetState]);

  return (
    <div
      ref={containerRef}
      className="relative bg-black p-0 flex flex-col h-full w-full align-middle items-center justify-center"
    >
      { internalPlayer }
      <Splash
        ref={splashRef}
        visible={!isPlaying || isLoading}
        loading={isLoading || currentStream !== undefined}
        status={statusText}
        transparent
        background={streamPreview}
      />
    </div>

  );
};

export default Camvas;
