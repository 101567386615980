import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import styles from './.module.css';

const Error: FC = () => (
  <div className={styles.container}>
    <main className={styles.main}>

      <b className="text-lg text-primary animate-pulse">Error</b>
      <p className={styles.message}>
        <b className="text-sm text-black animate-pulse">
          Unknown unexpected error
        </b>
      </p>
      <Link to="/" className="text-sm">Back</Link>
    </main>
  </div>
);

export default Error;
