import {
  AuthContextStore, GroupContextStore, OrganizationContextStore, ProjectContextStore,
  StreamContextStore, DrawingContextStore as CamvasContextStore, CountingContextStore,
} from '@viize/common';
import { SignalingContextType } from '@viize/views';
import { useOutletContext } from 'react-router-dom';

export type AppContext = {
  authContext: AuthContextStore,
  organizationContext: OrganizationContextStore,
  projectContext: ProjectContextStore,
  groupContext: GroupContextStore,
  streamContext: StreamContextStore,
  camvasContext: CamvasContextStore,
  signalingContext: SignalingContextType,
  countingContext: CountingContextStore,
};

export function useAppContext() {
  return useOutletContext<AppContext>();
}
