/* eslint-disable unused-imports/no-unused-vars */
import React, {
  useEffect, useMemo, useState, useCallback, CSSProperties, useRef,
} from 'react';

import {
  ChevronLeftIcon, ChevronRightIcon, ArrowPathIcon, ClockIcon, CalendarIcon,
} from '@heroicons/react/24/outline';
import {
  dateFromIso, formatDateInterval, groupReportsByDates, today,
} from '@viize/common';
import { Select, useAppContext } from '@viize/views';

import styles from './.module.css';

export type StatsProps = {
/**
 * Timeline main component
 */
  style?: CSSProperties,
};

let currentActionName: ActionId | undefined;
// let currentSelectedReport: string | undefined;

const reverseSafe = (reports: StreamReport[]) => {
  const reps = [...reports];
  return reps.reverse();
};

const Timeline = (props: StatsProps) => {
  const { streamContext, countingContext } = useAppContext();
  const {
    state: streamState, selectStreamById, getStreamReports,
    getStreamReport, saveStreamSession, setSelectedReport,
  } = streamContext;

  const actionId = useMemo(
    () => (streamState && streamState?.actionsIds
      ? streamState?.actionsIds[streamState.actionsIds.length - 1] : undefined),
    [streamState],
  );
  const selectedActionId = useMemo(
    () => streamState && streamState.selectedActionId,
    [streamState],
  );
  const { state: countingState } = countingContext;
  const currentSession = countingState?.currentSession;
  const isLoading = streamState?.isLoading;
  const isError = streamState?.error;
  const streamId = streamState?.selected;
  const selectedReport = useMemo(
    () => streamState?.selectedReport,
    [streamState?.selectedReport],
  );
  const stream = useMemo(
    () => ((streamId && selectStreamById)
      ? selectStreamById(streamId) : undefined),
    [selectStreamById, streamId],
  );
  const [selectedDate, setSelectedDate] = useState<string>(today());

  const reports = useMemo(() => stream?.reports ?? [], [stream?.reports]);
  const groupedReports = useMemo(
    () => (actionId ? groupReportsByDates(reports, actionId) : {}),
    [reports, actionId],
  );
  const reportsForDate = useMemo(() => {
    const reps: StreamReport[] = [];
    const group = groupedReports[selectedDate];
    if (group) {
      group.ids.forEach(
        (id) => {
          const rep = reports.find((r) => r.id === id);
          if (rep) reps.push(rep);
        },
      );
    }
    return reps;
  }, [groupedReports, reports, selectedDate]);

  const getReports = useCallback(() => {
    if (isLoading || isError || !getStreamReports
      || !streamId || !actionId) return;
    if (currentActionName === actionId) return;
    getStreamReports(streamId, actionId).then((reps) => {
      if (reps.length) {
        setSelectedDate(
          dateFromIso(reps[0].session_start).toDateString(),
        );
      }
    });
    currentActionName = actionId;
  }, [getStreamReports, isError, isLoading, actionId, streamId]);

  const saveSession = useCallback(() => {
    if (isLoading || isError || !saveStreamSession
        || !streamId || !selectStreamById || !setSelectedReport) return;
    saveStreamSession(streamId);
  }, [saveStreamSession, isError, isLoading, selectStreamById,
    setSelectedReport, streamId]);

  useEffect(getReports, [getReports]);

  useEffect(() => {
    if (isLoading || isError || !getStreamReport
      || !streamId || !selectedActionId || !setSelectedReport) return;
    const isLoaded = reports.find((r) => r.id === selectedReport
      && r.data && r.data[selectedActionId] !== undefined);
    if (!selectedReport || isLoaded) return;
    getStreamReport(streamId, selectedReport, undefined) // selectedActionId
      .then(() => {});
  }, [getStreamReport, isError, isLoading, selectedActionId,
    selectedReport, setSelectedReport, streamId, reports]);

  const leftRef = useRef<HTMLElement>(null);
  const rightRef = useRef<HTMLElement>(null);
  const scrollTo = useCallback((right = false) => {
    if (right) return rightRef.current?.scrollIntoView({ behavior: 'smooth' });
    return leftRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div
      className="w-full h-full gap-3 grid grid-flow-row-dense bg-gray-50 dark:bg-white dark:text-black"
    >
      <div className="w-full overflow-x-auto overflow-y-clip grid align-middle grid-flow-col-dense text-center place-items-center">
        <button
          className="btn btn-sm btn-square btn-ghost"
          onClick={saveSession}
        >
          <ClockIcon className={`h-6 w-6 px-0 col-span-1 ${isLoading ? 'animate-spin' : ''}`} />
        </button>
        <div className="col-span-9 flex items-center align-middle dark:text-black">
          <CalendarIcon className="h-6 w-6 px-0 col-span-1 pr-1" />
          <Select
            title="Select a session date"
            onChange={(date) => setSelectedDate(date)}
            value={selectedDate}
            idKey="date"
            valKey="date"
            displayKey="date"
            items={Object.values(groupedReports)}
            className="lg:w-auto"
          />
        </div>
        <div className="col-span-1">
          <button
            className="btn btn-sm btn-square btn-ghost"
            onClick={() => {
              currentActionName = undefined;
              // currentSelectedReport = undefined;
              getReports();
            }}
          >
            <ArrowPathIcon className={`h-6 w-6 px-0 ${isLoading ? 'animate-spin' : ''}`} />
          </button>
        </div>
      </div>
      <div className="flex flex-nowrap w-9/12 md:w-full lg:w-full xl:w-full 2xl:w-full border text-black">
        <button className="btn btn-square btn-ghost mx-1 btn-xs mt-1" onClick={() => scrollTo()}>
          <ChevronLeftIcon className="h-6 w-6 px-0" />
        </button>
        <div className={styles.timeline}>
          <span ref={leftRef} className={styles.space} />
          { reportsForDate.length > 7 ? (
            <div className={styles.space} />) : null }
          { reverseSafe(reportsForDate).map((r) => (
            <div key={r.id} className={`${r.id === selectedReport ? 'bg-gray-200 font-semibold' : 'bg-white'} text-black`}>
              <div className={styles.time}>
                <button onClick={() => setSelectedReport && setSelectedReport(r.id)}>
                  {formatDateInterval(r.session_start, r.session_end)}
                </button>
              </div>
            </div>
          ))}
          <span ref={rightRef} className={styles.space} />
        </div>
        <button className="btn btn-square btn-ghost mx-1 btn-xs mt-1" onClick={() => scrollTo(true)}>
          <ChevronRightIcon className="h-6 w-6 px-0" />
        </button>
      </div>
    </div>
  );
};

export default Timeline;
