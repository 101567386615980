/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { CSSProperties } from 'react';

import './.module.css';

export type DialogProps = {
  /**
   * a dialog component
   */
  title?: string,
  visible: boolean,
  onClose: () => void,
  children?: React.ReactNode,
  style?: CSSProperties
  className?: string,
};

const Dialog = ({
  title,
  children,
  onClose,
  visible,
}: DialogProps) => (
  <>
    <input checked={visible} onChange={() => {}} type="checkbox" className="modal-toggle" />
    <div className="modal inset-0 absolute z-50">
      <div className="modal-box relative h-5/6 border-2 border-gray-200 dark:border-gray-400 rounded-none bg-white dark:bg-base-300 dark:text-gray-200">
        <h1>{title}</h1>
        <button onClick={onClose} className="btn btn-sm btn-circle absolute right-2 top-2">✕</button>
        {children && children}
      </div>
    </div>

  </>
);

export default Dialog;
