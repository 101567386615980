/* eslint-disable unused-imports/no-unused-vars */
import React, {
  BaseSyntheticEvent,
  CSSProperties, forwardRef, Ref, useEffect, useState,
} from 'react';

import { loadSecureImageFromUrl } from '@viize/common';

import { ReactComponent as SpinnerActive } from '../../assets/loader-active.svg';
import { ReactComponent as Spinner } from '../../assets/loader.svg';

export type SplashProps = {
  /**
   * Camvas screen splash component.
   */
  visible?: boolean,
  loading?: boolean,
  transparent?: boolean,
  background?: string | null,
  status?: string,
  style?: CSSProperties,
};

const Splash = forwardRef(({
  visible, loading, transparent, background, status, style,
}: SplashProps, ref: Ref<HTMLDivElement>) => {
  const toggleBgImage = (evt: BaseSyntheticEvent, show: boolean) => {
    const img = evt.target as HTMLImageElement;
    if (show) img.classList.remove('hidden');
    else img.classList.add('hidden');
  };

  const [bgUrl, setBgUrl] = useState<string>();

  useEffect(() => {
    if (!background) return;
    loadSecureImageFromUrl(background).then((url) => {
      setBgUrl(url);
      // alert('ici');
    });
  }, [background]);

  return (
    <div
      ref={ref}
      className={
      `splash overflow-hidden h-full w-full p-0 z-30 cursor-wait absolute ${visible ? 'visible' : 'hidden'} 
      ${transparent ? 'bg-opacity-50' : 'bg-gray-700'}
      `
    }
      style={style}
    >
      {bgUrl && (
      <img
        className="splash-image hidden transition ease-in-out delay-75
        duration-150 object-contain h-full w-full"
        src={bgUrl}
        alt=""
        onLoad={(e) => toggleBgImage(e, true)}
        onError={(e) => toggleBgImage(e, false)}
      />
      )}

      <div className="splash-overlay absolute z-50 inset-x-0 inset-y-0 flex flex-col items-center justify-center">
        { loading ? <SpinnerActive className="animate-ping" height="36" /> : <Spinner height="36" />}
        { status && (
        <span className="mt-4 text-center text-white text-xs font-sans font-bold">
          { status }
        </span>
        )}
      </div>

    </div>
  );
});

export default Splash;
