/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, RefObject } from 'react';

import { delay, maxPlayerFailsCount } from '@viize/common';
import Hls from 'hls.js';

import { hlsOptions } from './config';

export interface HlsPlayerProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  hlsConfig?: any;
  playerRef: RefObject<HTMLVideoElement>;
  src: string;
}

export const playerActions = {
  load: (player: HTMLVideoElement | null, _?: string) => {
    player?.load();
  },
  play: (player: HTMLVideoElement | null) => {
    player?.play();
  },
  stop: (player: HTMLVideoElement | null) => {
    player?.pause();
  },
};

const RETRY_DELAY = 7000;
let playerFailsCount = 0;

export const VideoPlayer = ({
  hlsConfig = hlsOptions,
  playerRef = React.createRef<HTMLVideoElement>(),
  src,
  autoPlay,
  ...props
}: HlsPlayerProps) => {
  useEffect(() => {
    let hls: Hls;

    function initPlayer() {
      if (hls != null) {
        hls.destroy();
      }

      const newHls = new Hls({
        enableWorker: false,
        ...hlsConfig,
      });

      if (playerRef.current != null) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (autoPlay && playerFailsCount < maxPlayerFailsCount) {
            playerRef?.current
              ?.play()
              .catch((e) => {
                playerFailsCount += 1;
                console.log(
                  e,
                  'Unable to autoplay prior to user interaction with the dom.',
                );
              });
          }
        });
      });

      newHls.on(Hls.Events.ERROR, (_event, data) => {
        if (data.fatal) {
          delay(() => {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                newHls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                newHls.recoverMediaError();
                break;
              default:
                initPlayer();
                break;
            }
          }, RETRY_DELAY);
        }
      });

      hls = newHls;
    }

    // Check for Media Source support
    if (Hls.isSupported()) {
      initPlayer();
    }

    return () => {
      if (hls != null) {
        hls.destroy();
      }
    };
  }, [autoPlay, hlsConfig, playerRef, src]);

  // If Media Source is supported, use HLS.js to play video
  if (Hls.isSupported()) return <video width={800} height={600} muted ref={playerRef} {...props} />;

  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  return (
    <video
      width={800}
      height={600}
      muted
      ref={playerRef}
      src={src}
      autoPlay={autoPlay}
      {...props}
    />
  );
};

export default VideoPlayer;
