import React from 'react';

interface AppLogoProps extends React.HTMLAttributes < HTMLAnchorElement > {
  width?: number,
  height?: number,
  titleClass?: string,
  subtitleClass?: string,
  imageClass?: string,
  tiny?: boolean,
  link?: string,
}

export const AppLogo = ({
  width = 26, height = 20, titleClass, subtitleClass, imageClass, tiny = false, link = '/', ...rest
}: AppLogoProps) => (
  <a href={link} {...rest} className={`inline-flex justify-start align-middle items-center lg:ml-3 gap-0 ${rest.className}`}>
    <img src="/logo.png" alt="Viize" width={width} height={height} className={`${imageClass}`} />
    {!tiny && (
    <p className="text-2xl font-thin inline-flex dark:text-inherit">
      <span className={`text-primary font-semibold ${titleClass}`}>Viize</span>
      <span className={subtitleClass}>Traffic</span>
    </p>
    )}
  </a>
);

export const AppLogoVariant = () => (
  <a className="flex rounded-full w-8 h-8 lg:w-36 lg:h-10" href="/">
    <img src="/viize.svg" alt="Viize" className="h-6 w-10 lg:h-8 lg:w-14" />
    <b className="absolute text-xs mt-1 lg:ml-2 font-bold self-end">Traffic</b>
  </a>
);
