import React, {
  FormEvent, useCallback, useEffect, useState,
} from 'react';

import {
  UserIcon, KeyIcon, AtSymbolIcon,
} from '@heroicons/react/24/outline';
import { ROUTES } from '@viize/common';
import { Input, useAppContext } from '@viize/views';
import { useTranslation } from 'react-i18next';
import { redirect, useNavigate } from 'react-router-dom';

export const RegisterForm = () => {
  const { state, isAuthenticated, register } = useAppContext().authContext;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const error = state?.error;

  const [form, setForm] = useState<UserBase>({
    firstname: '',
    username: '',
    lastname: '',
    password: '',
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => setForm((s: UserBase) => ({ ...s, [key]: event.target.value }));

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();
    navigate(ROUTES.login.path);
  };

  const handleSubmit = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    if (!register) return;
    try {
      await register(form);

      navigate(ROUTES.login.path);
    } catch (err) {
      console.log('Register failed!', err);
    }
  }, [form, navigate, register]);

  useEffect(() => {
    if (!isAuthenticated) return;
    redirect(ROUTES.welcome.path);
  }, [isAuthenticated]);

  return (
    <form onSubmit={handleSubmit} className="h-full lg:w-3/6 w-full flex flex-col items-center justify-center align-middle space-x-2 gap-2">
      <Input
        required
        name="firstname"
        type="text"
        placeholder={t('form.auth.firstname')}
        value={form.firstname}
        onChange={(e) => handleChange(e, 'firstname')}
        className="input-md"
        startIcon={<UserIcon className="w-6 h-5 opacity-70" />}
      />
      <Input
        required
        name="lastname"
        type="text"
        placeholder={t('form.auth.lastname')}
        value={form.lastname}
        onChange={(e) => handleChange(e, 'lastname')}
        className="input-md"
        startIcon={<UserIcon className="w-5 h-5 opacity-0" />}
      />
      <Input
        required
        name="username"
        type="email"
        placeholder={t('form.auth.email')}
        value={form.username}
        onChange={(e) => handleChange(e, 'username')}
        className="input-md"
        startIcon={<AtSymbolIcon className="w-5 h-5 opacity-70" />}
      />
      <Input
        required
        name="password"
        type="password"
        placeholder={t('form.auth.password')}
        value={form.password}
        onChange={(e) => handleChange(e, 'password')}
        className="input-md"
        startIcon={<KeyIcon className="w-5 h-5 opacity-70" />}
      />
      {error && <p className="text-error">{error}</p>}
      <div className="mt-5 btn-group-horizontal flex flex-row lg:w-4/6 lg:justify-between gap-2">
        <button
          onClick={handleLogin}
          type="button"
          className="btn-ghost btn-xl text-black hover:bg-gray-900 hover:text-white py-2 px-2 lg:px-8"
        >
          { t('form.auth.login') }
        </button>
        <button
          type="submit"
          className="btn-xl text-white bg-primary hover:bg-gray-900 font-normal py-2 px-2 lg:px-8 disabled:opacity-25"
          disabled={state?.isLoading}
        >
          { t('form.auth.create') }
        </button>
      </div>
    </form>
  );
};
