import React, { useEffect } from 'react';

import { ROUTES } from '@viize/common';
import { Loading, useAppContext } from '@viize/views';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  component: React.ComponentType<React.ReactNode>;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { isAuthenticated, state, getAuthUser } = useAppContext().authContext;
  const location = useLocation();
  const user = state?.user;
  const error = state?.error;
  const isLoading = state?.isLoading;

  useEffect(() => {
    if (isLoading || error || user || !isAuthenticated) return;
    async function fetchAuthUser() {
      if (getAuthUser) await getAuthUser();
    }
    fetchAuthUser();
  }, [getAuthUser, isAuthenticated, error, isLoading, user]);

  return isAuthenticated ? <React.Suspense fallback={<Loading />}><Component /></React.Suspense>
    : <Navigate to={ROUTES.login.path} state={{ from: location }} />;
};
