/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback, useRef, useState,
} from 'react';

import {
  CloudArrowUpIcon, VideoCameraIcon, ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { streamMedia } from '@viize/common';
import {
  Loading, useAppContext,
} from '@viize/views';
import { useNavigate } from 'react-router-dom';

export type VideoStreamStreamTabProps = {
  /**
   * video stream tab component.
   */
  active?: boolean,
  onClose?: () => void,
};

const MAX_FILE_SIZE = 1000; // MB

// return the size in MB
const getFileSize = (size: number): number => Math.ceil(size / 1024 / 1024);

const VideoStreamStreamTab = ({ active, onClose }: VideoStreamStreamTabProps) => {
  const { streamContext, projectContext, groupContext } = useAppContext();
  const { state: projectState } = projectContext;
  const { state: groupState } = groupContext;
  const { state: streamState, createStream, setIsLoading } = streamContext;
  const projectId = projectState?.selected;
  const groupId = groupState?.selected;
  const groups = groupState?.groups;
  const isLoading = streamState?.isLoading;
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const [group, setGroup] = useState<string | undefined>(groupId);
  const [isFileDragging, setFileDragging] = useState(false);
  const [statusText, setStatusText] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [fileToStream, setFileToStream] = useState<File>();

  const streamVideoStream = useCallback(async (e) => {
    e.preventDefault();
    if (!createStream || !setIsLoading || !fileToStream
      || !group || !projectId || isLoading) return;
    const stream: Partial<Stream> = {
      project_id: projectId,
      group_id: group,
      name: fileToStream.name.trim(),
      config: {
        type: 'camera',
      },
      actions: { object_detection: {} },
    };

    createStream(stream)
      .then(async ({ id: streamId, dispatch }) => {
        const onStart = () => {
          setStatusText('buffering...');
        };
        const onEnd = () => {
          setFileToStream(undefined);
          dispatch(); // wait and play the video
          setIsLoading();
          if (onClose) onClose();
          navigate(`/live/${projectId}/${group}/${streamId}`, { replace: true });
        };
        const onErr = () => {
          setStatusText(undefined);
          setErrorMsg('Stream: Interrupted, please try again later');
        };

        const onProgress = (status: string | number) => {
          setStatusText(String(status));
        };

        await streamMedia(streamId, fileToStream, {
          onStart, onEnd, onErr, onProgress,
        });
        setErrorMsg(undefined);
      })
      .catch(() => {
        setErrorMsg('Connexion or key error');
        setFileToStream(undefined);
      })
      .finally(() => {
        formRef.current?.reset();
        setStatusText(undefined);
      });
  }, [createStream, fileToStream, isLoading, navigate, onClose,
    projectId, setIsLoading, group]);

  const handleFileSelect = useCallback(async (files: FileList | null) => {
    if (isLoading) return;
    setErrorMsg(undefined);
    setStatusText(undefined);
    if (files && files.length) {
      const f = files[0];
      if (getFileSize(f.size) < MAX_FILE_SIZE) setFileToStream(f);
      else setErrorMsg(`The video size exceeds the allowed size: ${MAX_FILE_SIZE} MB`);
    }
  }, [isLoading]);

  const handleGroupSelect = useCallback((e) => setGroup(e.target.value), []);

  return (
    <form ref={formRef} className={`flex justify-center items-center flex-col my-2 pt-6 gap-3 ${active ? 'block' : 'hidden'}`}>
      <select
        className="select _select-disabled rounded-lg border-none bg-gray-100 select-sm w-full max-w-xs text-center"
        onChange={handleGroupSelect}
        value={group}
      >
        <option>Select streams group</option>
        {groups && Object.values(groups).map((g: Group) => (
          <option key={g.id} value={g.id} disabled={!g.is_editable}>
            {g.description ?? g.name}
          </option>
        ))}
      </select>

      <p className={`p-2 gap-1 flex text-sm ${errorMsg ? 'text-warning' : ''}`}>
        {`${errorMsg ?? 'Stream from an uploaded video file'}`}
        <VideoCameraIcon className="h-5 w-5" />
      </p>

      <div
        className={`grid place-content-center place-items-center border border-dashed
         border-gray-500 p-24 gap-6 drop-shadow ${isFileDragging ? 'bg-gray-100' : ''}
        `}
        onDrop={(e) => { e.preventDefault(); handleFileSelect(e.dataTransfer.files); }}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={() => setFileDragging(true)}
        onDragLeave={() => setFileDragging(false)}
      >
        <Loading visible={fileToStream !== undefined || isLoading} status={statusText ?? 'Ready !'}>
          <>
            <CloudArrowUpIcon className="h-16 w-16 mx-0 border border-dashed border-gray-500 p-3 rounded-full" />
            <label className="block" htmlFor="video">
              <span className="sr-only">Choose video file</span>
              <input
                type="file"
                name="video"
                accept="video/mp4,video/x-m4v,video/x-flv,video/*"
                onChange={(e) => handleFileSelect(e.target.files)}
                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-red-50 file:text-red-700
                  hover:file:bg-red-100
                "
              />
            </label>
          </>
        </Loading>
      </div>
      <div className="inline-flex items-center justify-center w-full lg:mt-8 gap-4">
        <a
          className={`${isLoading ? '_animate-bounce' : ''} btn btn-sm mr-auto bg-gray-500 hover:bg-secondary-focus border-none rounded-none`}
          onClick={onClose}
          role="button"
          type="reset"
          tabIndex={0}
          onKeyDown={undefined}
        >
          Back
        </a>
        <a className="form-control">
          <div className="flex space-x-2">
            <button disabled type="submit" onClick={streamVideoStream} className={`${isLoading ? 'btn-disabled' : ''} btn rounded-none btn-sm btn-primary`}>
              <ExclamationTriangleIcon className="h-5 w-5 mr-1" />
              {isLoading ? 'Uploading' : 'Add stream'}
            </button>
          </div>
          <small>
            Unavailable ;)
          </small>
        </a>
      </div>
    </form>
  );
};

export default VideoStreamStreamTab;
