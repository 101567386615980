import { createContext, useReducer } from 'react';

import {
  StreamActionsTypes, StreamContextStore, streamReducer,
  initialStreamState, sortReportBySessionDate, handleAPIError,
} from '@viize/common';
import * as streamActions from '@viize/common/api/streams';

export const useStreams = () => {
  const [state, dispatch] = useReducer(streamReducer, initialStreamState());

  const setIsLoading = (loading?: boolean) => dispatch({
    type: StreamActionsTypes.SET_IS_LOADING, payload: loading,
  });

  const setError = (error?: any) => dispatch({
    type: StreamActionsTypes.SET_ERROR, payload: String(error),
  });

  const setIsPlaying = (playing?: boolean) => dispatch({
    type: StreamActionsTypes.SET_IS_PLAYING, payload: playing,
  });

  const resetState = () => dispatch({ type: StreamActionsTypes.RESET_STATE });

  const createStream = async (values: Partial<Stream>):
  Promise<{ id: string, dispatch: () => void }> => {
    setIsLoading(true);
    try {
      const stream = {
        ...values,
        created_at: new Date().toString(),
        is_editable: true,
        is_active: true,
      } as Stream;
      const id = await streamActions.createStream(stream);

      return {
        id,
        dispatch: () => dispatch({
          type: StreamActionsTypes.CREATE_STREAM,
          payload: { ...stream, id },
        }),
      };
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`Unable to create stream: ${detail}`);
    }
  };

  const updateStream = async (values: Partial<Stream>, persist?: boolean):
  Promise<void> => {
    try {
      if (persist) {
        setIsLoading(true);
        await streamActions.updateStream(values);
      }
      dispatch({
        type: StreamActionsTypes.UPDATE_STREAM,
        payload: values,
      });
    } catch (error) {
      setError(error);
      throw new Error(`Unable to update stream ${String(error)}`);
    }
  };

  const getStreams = async (organizationId: string, projectId?: string, groupId?: string): Promise<{
    [id: string]: Stream
  } | undefined> => {
    setIsLoading(true);
    try {
      const res = await streamActions.getStreams(organizationId, projectId, groupId);
      const vals: { [id: string]: Stream } = {};
      res.forEach((v) => { vals[v.id] = v; });
      dispatch({ type: StreamActionsTypes.GET_STREAMS, payload: vals });
      return vals;
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`${detail}`);
    }
  };

  const getStreamById = async (
    id: string,
  ): Promise<Stream | undefined> => {
    setIsLoading(true);
    return streamActions.getStream(id)
      .then((stream) => {
        dispatch({ type: StreamActionsTypes.UPDATE_STREAM, payload: stream });
        return stream;
      }).catch((error: ApiErrorResponse) => {
        const { detail } = handleAPIError(error, setError);
        throw new Error(`${detail}`);
      });
  };

  const setSelectedStream = (id: string) => dispatch({
    type: StreamActionsTypes.SET_SELECTED_STREAM, payload: id,
  });

  const setSelectedAction = (id: ActionId) => dispatch({
    type: StreamActionsTypes.SET_SELECTED_ACTION, payload: id,
  });

  const setSelectedReport = (id: string) => dispatch({
    type: StreamActionsTypes.SET_SELECTED_REPORT, payload: id,
  });

  const selectStreamById = (
    id: string,
  ): Stream | undefined => Object.values(state.streams).find((v) => v.id === id || v.slug === id);

  const getStreamReports = async (
    id: string,
    actionId: ActionId,
  ): Promise<StreamReport[] | []> => {
    setIsLoading(true);
    const stream = selectStreamById(id);
    let reports: StreamReport[] | [] = [];
    if (!stream) return reports;
    try {
      reports = await streamActions.getReports(id, actionId);
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`${detail}`);
    }
    dispatch({
      type: StreamActionsTypes.UPDATE_STREAM,
      payload: {
        ...stream,
        reports: reports.sort(sortReportBySessionDate),
      },
    });
    return reports;
  };

  const getStreamReport = async (
    streamId: string,
    id: string,
    actionId: ActionId | undefined,
  ): Promise<StreamReport | undefined> => {
    setIsLoading(true);
    const stream = selectStreamById(streamId);
    let report: StreamReport | undefined;
    if (!stream || !stream.reports) return undefined;
    try {
      report = await streamActions.getReport(streamId, id, actionId);
      stream.reports = stream.reports.map((r) => (r.id === id ? {
        ...r,
        ...report,
        data: {
          ...r.data,
          ...report?.data,
        },
      } : r)) as StreamReport[];
      dispatch({ type: StreamActionsTypes.UPDATE_STREAM, payload: { ...stream } });
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      report = undefined;
      throw new Error(`${detail}`);
    }
    return report;
  };

  const saveStreamSession = async (
    id: string,
  ): Promise<void> => {
    setIsLoading(true);
    const stream = selectStreamById(id);
    if (!stream) return;
    try {
      await streamActions.saveSession(id);
      setIsLoading(false);
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`${detail}`);
    }
    dispatch({ type: StreamActionsTypes.SAVE_SESSION });
  };

  return {
    state,
    createStream,
    updateStream,
    selectStreamById,
    setSelectedStream,
    setSelectedAction,
    setSelectedReport,
    getStreamById,
    getStreams,
    getStreamReports,
    getStreamReport,
    saveStreamSession,
    setIsLoading,
    setError,
    setIsPlaying,
    resetState,
  };
};

export const StreamContext = createContext<Partial<StreamContextStore>>({});

export default useStreams;
