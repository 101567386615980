import {
  useCallback, useEffect, useMemo,
} from 'react';

import { FederatedPointerEvent } from '@pixi/events';
import { getROIName } from '@viize/common/helpers';
import {
  addDefaultListeners, addShapeTitle,
  drawLineShape, drawPolygonShape, addEdges, MAX_SHAPE_POINTS, useAppContext,
} from '@viize/views';
import {
  Container, Graphics, Point as PixiPoint,
} from 'pixi.js';

const cursor = new Graphics();
const cursorShape = new Graphics();
let selectedShape: Graphics | undefined;
let history: PixiPoint[] = [];

const useDrawing = (stage: Container) => {
  const { state, createROI } = useAppContext().camvasContext;
  const cursorType = state?.cursorType;
  const rois = useMemo(() => state?.rois, [state]);

  const clearStage = useCallback(() => {
    stage.children.forEach((c) => {
      if (!c.name.startsWith('cursor')) c.destroy();
    });
  }, [stage]);

  const handleDraw = useCallback((points: PixiPoint[]) => {
    if (points.length === 0 || !stage) return;
    const len = points.length;
    addEdges(cursor, points);
    if (len < 3) {
      drawLineShape(cursor, points[0], points[len - 1]);
      return;
    }
    if (len >= MAX_SHAPE_POINTS) {
      history = [];
      cursor.clear();
      cursor.removeChildren();
      if (createROI) createROI({ points });
      return;
    }
    drawPolygonShape(cursor, points);
  }, [createROI, stage]);

  const handlePointerDown = useCallback((event: FederatedPointerEvent) => {
    event.stopPropagation();
    if (history.length >= MAX_SHAPE_POINTS) return;
    const p = new PixiPoint().copyFrom(event.global);
    p.x /= stage.scale.x;
    p.y /= stage.scale.y;
    if (selectedShape?.containsPoint(p)) return;
    if (history.length && p.equals(history[history.length - 1] as PixiPoint)) return;
    history = [...history, p];
    handleDraw(history);
  }, [handleDraw, stage.scale.x, stage.scale.y]);

  const handlePointerOut = useCallback((event: FederatedPointerEvent) => {
    event.stopPropagation();
    selectedShape = undefined;
    if (history.length && history.length < MAX_SHAPE_POINTS) {
      cursor.clear();
      cursor.removeChildren();
      history = [];
    }
  }, []);

  useEffect(() => {
    if (!stage) return;
    clearStage();
    if (!rois?.length) return;
    rois.forEach((r) => {
      if (r.points && r.points.length) {
        const R = new Graphics();
        R.name = r.id;
        const childExist = stage.children.find((c) => c.name === R.name);
        if (childExist) childExist.destroy();
        const points = r.points.map(
          (p) => new PixiPoint(p.x, p.y),
        );
        drawPolygonShape(R, points);
        addDefaultListeners(R);
        addShapeTitle(R, getROIName(r), points);
        R.visible = (r.enabled === true && cursorType === 'polygon');
        addEdges(R, points, R.visible);
        stage.addChild(R);
      }
    });
  }, [clearStage, cursorType, rois, stage]);

  const handleCursorEffect = (event: FederatedPointerEvent) => cursorShape.position.set(
    (event.global.x / stage.scale.x),
    (event.global.y / stage.scale.y),
  );

  return {
    cursor,
    cursorShape,
    selectedShape,
    handlePointerDown,
    handlePointerOut,
    handleCursorEffect,
  };
};

export default useDrawing;
