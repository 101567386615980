import React, { CSSProperties, useEffect, useState } from 'react';

import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { scrollTo } from '@viize/common/helpers';

export type ScrollTopBtnProps = {
  target: string;
  style?: CSSProperties;
  threshold?: number; // Threshold for scroll visibility, defaults to 100
};

export const ScrollTopBtn = ({ target, style, threshold = 100 }: ScrollTopBtnProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsVisible(scrollTop > threshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [threshold]);

  const handleClick = () => {
    scrollTo(target);
  };

  return isVisible ? (
    <a href="#" onClick={handleClick} className="btn btn-sm btn-circle fixed bottom-5 right-5" style={style}>
      <ArrowUpIcon className="h-6 w-6" />
    </a>
  ) : null;
};
