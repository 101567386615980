import { createContext, useReducer } from 'react';

import {
  ProjectActionsTypes, ProjectContextStore, projectReducer, initialProjectState, handleAPIError,
} from '@viize/common';
import * as projectActions from '@viize/common/api/projects';

export const useProjects = () => {
  const [state, dispatch] = useReducer(projectReducer, initialProjectState());

  const setIsLoading = (loading?: boolean) => dispatch({
    type: ProjectActionsTypes.SET_IS_LOADING, payload: loading,
  });

  const setError = (error?: any) => dispatch({
    type: ProjectActionsTypes.SET_ERROR, payload: String(error),
  });

  const resetState = () => dispatch({ type: ProjectActionsTypes.RESET_STATE });

  const createProject = async (values: Partial<Project>): Promise<{
    id: string, dispatch: () => void
  }> => {
    setIsLoading(true);
    try {
      const project = { // according to the API specs
        name: values.name,
        description: values.description,
      } as Project;
      const id = await projectActions.createProject(project);

      return {
        id,
        dispatch: () => dispatch({
          type: ProjectActionsTypes.CREATE_PROJECT,
          payload: { ...project, id },
        }),
      };
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`Unable to create project: ${detail}`);
    }
  };

  const updateProject = async (values: Partial<Project>, persist?: boolean): Promise<void> => {
    setIsLoading(true);
    try {
      if (persist) await projectActions.updateProject(values);
      dispatch({
        type: ProjectActionsTypes.UPDATE_PROJECT,
        payload: values,
      });
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`Unable to update the project: ${detail}`);
    }
  };

  const getProjects = async (
    organizationId: string,
  ): Promise<{ [id: string]: Project } | undefined> => {
    setIsLoading(true);
    try {
      const res = await projectActions.getProjects(organizationId);
      const vals: { [id: string]: Project } = {};
      res.forEach((v) => { vals[v.id] = v; });
      dispatch({ type: ProjectActionsTypes.GET_PROJECTS, payload: vals });
      return vals;
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`${detail}`);
    }
  };

  const getProjectById = async (
    id: string,
  ): Promise<Project | undefined> => {
    setIsLoading(true);
    try {
      const project = await projectActions.getProject(id);
      dispatch({ type: ProjectActionsTypes.UPDATE_PROJECT, payload: project });
      return project;
    } catch (error: ApiErrorResponse) {
      const { detail } = handleAPIError(error, setError);
      throw new Error(`${detail}`);
    }
  };

  const setSelectedProject = (id?: string) => dispatch({
    type: ProjectActionsTypes.SET_SELECTED_PROJECT, payload: id,
  });

  const selectProjectById = (
    id: string,
  ): Project | undefined => Object.values(state.projects)
    .find((v: Project) => v.id === id || v.slug === id);

  return {
    state,
    createProject,
    updateProject,
    selectProjectById,
    setSelectedProject,
    getProjectById,
    getProjects,
    setIsLoading,
    setError,
    resetState,
  };
};

export const ProjectContext = createContext<Partial<ProjectContextStore>>({});

export default useProjects;
