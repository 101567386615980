export type GroupState = {
  groups: { [id: string]: Group };
  selected?: string;
  isLoading?: boolean;
  error?: any;
};

export enum GroupActionsTypes {
  GET_GROUPS = 'GET_GROUPS',
  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  SET_SELECTED_GROUP = 'SET_SELECTED_GROUP',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  RESET_STATE = 'RESET_STATE',
}

export type GroupActionType = {
  type: GroupActionsTypes;
  payload?: { [id: string]: Group } | Partial<Group> | string | { id: string } | boolean;
};

export const initialGroupState = (): GroupState => ({
  groups: {},
  selected: undefined,
  isLoading: false,
  error: undefined,
});

export const groupReducer = (
  state: GroupState,
  action: GroupActionType,
): GroupState => {
  console.debug(action.type, action.payload);
  switch (action.type) {
    case GroupActionsTypes.GET_GROUPS: {
      const groups = action.payload as { [id: string]: Group };
      const selected = (Object.keys(groups).length && !state.selected)
        ? Object.keys(groups)[0] : state.selected;
      return {
        ...state,
        groups,
        selected,
        isLoading: false,
      };
    }
    case GroupActionsTypes.CREATE_GROUP: {
      const group = action.payload as Group;
      if (!group.id) return state;
      return {
        ...state,
        groups: {
          ...state.groups,
          [group.id]: group,
        },
        selected: group.id,
        isLoading: false,
      };
    }
    case GroupActionsTypes.UPDATE_GROUP: {
      const group = action.payload as Group;
      if (!group.id) return state;
      return {
        ...state,
        groups: {
          ...state.groups,
          [group.id]: {
            ...state.groups[group.id],
            ...group,
          },
        },
        selected: group.id,
        isLoading: false,
      };
    }
    case GroupActionsTypes.SET_SELECTED_GROUP: {
      return {
        ...state,
        selected: action.payload as string,
        // isPlaying: false,
      };
    }
    case GroupActionsTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload as boolean, error: undefined };
    case GroupActionsTypes.SET_ERROR:
      return { ...state, isLoading: false, error: action.payload as any };
    case GroupActionsTypes.RESET_STATE:
      return initialGroupState();
    default:
      return state;
  }
};

export interface GroupContextStore {
  state: GroupState;
  createGroup?: (values: Partial<Group>)
  => Promise<{ id: string, dispatch: () => void }>;
  updateGroup?: (values: Partial<Group>, persist?: boolean)
  => Promise<void>;
  getGroupById?: (id: string) => Promise<Group | undefined>;
  getGroups?: (projectId: string) => Promise<{ [id: string]: Group } | undefined>;
  selectGroupById?: (id: string) => Group | undefined;
  setSelectedGroup?: (id: string) => void;
  setIsLoading?: (loading?: boolean) => void;
  setError?: (error?: any) => void;
  resetState?: () => void;
}
