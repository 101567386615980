export const MAX_ROIS = 4;

export type DrawingState = {
  cursorType?: DrawType;
  rois: ROI[];
  selectedROiId?: string;
};

export enum DrawingActionsTypes {
  LOAD_ROIS = 'LOAD_ROIS',
  CREATE_ROI = 'CREATE_ROI',
  UPDATE_ROI = 'UPDATE_ROI',
  DELETE_ROI = 'DELETE_ROI',
  SET_SELECTED_ROI = 'SET_SELECTED_ROI',
  SET_CURSOR_TYPE = 'SET_CURSOR_TYPE',
  RESET_STATE = 'RESET_STATE',
}

export type DrawingActionType = {
  type: DrawingActionsTypes;
  payload?: Partial<ROI>[] | Partial<ROI> | string;
};

export const initialDrawingState = (): DrawingState => ({
  cursorType: undefined,
  rois: [],
  selectedROiId: undefined,
});

const getAvailableRoiId = (rois: ROI[]): string => {
  let id: string | undefined;
  let i = 0;
  let exist: boolean;
  do {
    id = `R${i}`;
    exist = false;
    for (let j = 0; j < rois.length; j += 1) {
      if (rois[j].id === id) exist = true;
    }
    i += 1;
  } while (exist);
  return id;
};

export const drawingReducer = (
  state: DrawingState,
  action: DrawingActionType,
): DrawingState => {
  console.debug(action.type, action.payload);
  switch (action.type) {
    case DrawingActionsTypes.LOAD_ROIS: {
      const rois = action.payload as ROI[];
      return {
        ...state,
        selectedROiId: rois.length ? rois[0].id : undefined,
        rois,
      };
    }
    case DrawingActionsTypes.CREATE_ROI: {
      if (!action.payload) return state;
      const id = getAvailableRoiId(state.rois);
      const name = id;
      const description = name;
      const direction = 1; // TB
      const enabled = true;
      const roi = {
        ...action.payload as ROI,
        id,
        name,
        direction,
        enabled,
        description,
      };
      return {
        ...state,
        rois: [...state.rois, roi],
        selectedROiId: id,
      };
    }
    case DrawingActionsTypes.UPDATE_ROI: {
      const roi = action.payload as ROI;
      return roi ? {
        ...state,
        rois: state.rois.map((r) => (r.id === roi.id ? {
          ...r,
          ...roi,
        } : r)),
      } : state;
    }
    case DrawingActionsTypes.DELETE_ROI: {
      const id = action.payload;
      if (id === undefined) return state;
      return {
        ...state,
        rois: state.rois.filter((r) => r.id !== id),
        selectedROiId: undefined,
      };
    }
    case DrawingActionsTypes.SET_SELECTED_ROI:
      return {
        ...state,
        selectedROiId: action.payload as string,
      };
    case DrawingActionsTypes.SET_CURSOR_TYPE:
      return action.payload ? {
        ...state,
        cursorType: action.payload as DrawType,
      } : state;
    case DrawingActionsTypes.RESET_STATE:
      return initialDrawingState();
    default:
      return state;
  }
};

export interface DrawingContextStore {
  state: DrawingState;
  loadROIs?: (values: Stream, actionId: ActionId) => ROI[];
  createROI?: (values: Partial<ROI>) => void;
  updateROI?: (values: Partial<ROI>) => void;
  deleteROI?: (id: string) => void;
  selectROIById?: (id: string) => ROI | undefined;
  setCursorType?: (cursorType?: DrawType) => void;
  setSelectedROI?: (id?: string) => void;
  resetState?: () => void;
  checkIfCanDraw?: () => boolean;
}
