export enum Labels {
  CAR,
  TRUCK,
  BUS,
  MOTORCYCLE,
  PERSON,
  BICYLE,
}

export enum LabelNames {
  CAR = 'car',
  TRUCK = 'truck',
  BUS = 'bus',
  MOTORCYCLE = 'motorcycle',
  PERSON = 'person',
  BICYLE = 'bicycle',
  UNKNOWN = 'unknown',
}

export const CountsUnits: CountsUnits = {
  object_detection: '',
  number_plate: '',
  speed_track: ' km/h',
  carbon_emission: 'gCO2e ☁',
  weight_pressure: 'kg𐄷',
  face_id: '[i]',
  face_id2: '[i]',
  incident_report: '⚠️',
};

export enum Directions {
  TB, // 0
  BT, // 1
  LR, // 2
  RT, // 3
}

export const maxActivitiesDisplay = 255;
