export type AuthState = {
  user?: User;
  isLoading?: boolean;
  error?: string;
};

export enum AuthActionsTypes {
  SET_AUTH_USER = 'SET_AUTH_USER',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  RESET_STATE = 'RESET_STATE',
}

export type AuthActionType = {
  type: AuthActionsTypes;
  payload?: Partial<UserBase> | Partial<User> | string | boolean;
};

export const initialAuthState = (): AuthState => ({
  user: undefined,
  isLoading: false,
  error: undefined,
});

export const authReducer = (
  state: AuthState,
  action: AuthActionType,
): AuthState => {
  console.debug(action.type, action.payload);
  switch (action.type) {
    case AuthActionsTypes.SET_AUTH_USER: {
      const user = action.payload as User;
      return {
        ...state,
        user,
        isLoading: false,
        error: undefined,
      };
    }
    case AuthActionsTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload as boolean, error: undefined };
    case AuthActionsTypes.SET_ERROR:
      return { ...state, isLoading: false, error: action.payload as string };
    case AuthActionsTypes.RESET_STATE:
      return initialAuthState();
    default:
      return state;
  }
};

export interface AuthContextStore {
  state: AuthState;
  login?: (auth: AuthCredential) => Promise<void>;
  logout?: () => void;
  register?: (values: Partial<UserBase>, persist?: boolean) => Promise<void>;
  getAuthUser?: () => Promise<void>;
  setAuthUser?: (user: User) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (status: boolean) => void;
  setIsLoading?: (loading?: boolean) => void;
  setError?: (error?: string) => void;
  resetState?: () => void;
}
