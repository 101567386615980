import React from 'react';

import {
  ArrowPathRoundedSquareIcon, ArrowLongUpIcon,
  ArrowLongRightIcon, ArrowLongDownIcon, ArrowLongLeftIcon,
} from '@heroicons/react/24/outline';
import { LabelNames } from '@viize/common';
import { ReactComponent as BicyleIcon } from '@viize/views/assets/icons/bicyle.svg';
import { ReactComponent as BusIcon } from '@viize/views/assets/icons/bus.svg';
import { ReactComponent as CarIcon } from '@viize/views/assets/icons/car.svg';
import { ReactComponent as MotorCycleIcon } from '@viize/views/assets/icons/motorcycle.svg';
import { ReactComponent as PedestrianIcon } from '@viize/views/assets/icons/pedestrian.svg';
import { ReactComponent as TruckIcon } from '@viize/views/assets/icons/truck.svg';
import { ReactComponent as Unknown } from '@viize/views/assets/icons/unknown.svg';

export const countingIcons: {
  [label: string]: { default: string, icon: React.ReactElement }
} = {
  [LabelNames.CAR]: {
    default: '000000', icon: (<CarIcon className="h-7 w-7 border rounded-full p-1 mx-1 mask mask-squircle dark:bg-base-100 dark:text-white" />),
  },
  [LabelNames.TRUCK]: {
    default: '000000', icon: (<TruckIcon className="h-7 w-7 border rounded-full p-1 mx-1 mask mask-squircle dark:bg-base-100 dark:text-white" />),
  },
  [LabelNames.BUS]: {
    default: '000000', icon: (<BusIcon className="h-7 w-7 border rounded-full p-1 mx-1 mask mask-squircle dark:bg-base-100 dark:text-white" />),
  },
  [LabelNames.MOTORCYCLE]: {
    default: '000000', icon: (<MotorCycleIcon className="h-7 w-7 border rounded-full p-1 mx-1 mask mask-squircle dark:bg-base-100 dark:text-white" />),
  },
  [LabelNames.BICYLE]: {
    default: '000000', icon: (<BicyleIcon className="h-7 w-7 border rounded-full p-1 mx-1 mask mask-squircle dark:bg-base-100 dark:text-white" />),
  },
  [LabelNames.PERSON]: {
    default: '000000', icon: (<PedestrianIcon className="h-7 w-7 border rounded-full p-1 mx-1 mask mask-squircle dark:bg-base-100 dark:text-white" />),
  },
  [LabelNames.UNKNOWN]: {
    default: '000000', icon: (<Unknown className="h-6 w-6 mx-1 mask mask-squircle dark:bg-base-100 dark:text-white" />),
  },
};

export const directions = [
  {
    name: 'ALL',
    value: 0,
    enabled: true,
    icon: (cls = 'h-7 w-7 border rounded-full p-1 mx-0') => (<ArrowPathRoundedSquareIcon className={cls} />),
  },
  {
    name: 'TB', value: 1, enabled: true, icon: (cls = 'h-7 w-7 border rounded-full p-1 mx-0') => (<ArrowLongDownIcon className={cls} />),
  },
  {
    name: 'BT', value: 2, enabled: true, icon: (cls = 'h-7 w-7 border rounded-full p-1 mx-0') => (<ArrowLongUpIcon className={cls} />),
  },
  {
    name: 'LR', value: 3, enabled: true, icon: (cls = 'h-7 w-7 border rounded-full p-1 mx-0') => (<ArrowLongRightIcon className={cls} />),
  },
  {
    name: 'RL', value: 4, enabled: true, icon: (cls = 'h-7 w-7 border rounded-full p-1 mx-0') => (<ArrowLongLeftIcon className={cls} />),
  },
];

export default countingIcons;
