import React, { useCallback } from 'react';

import { Bars3Icon, BoltIcon } from '@heroicons/react/24/outline';
import { ROUTES, scrollTo } from '@viize/common';
import {
  AppLogo, LocaleSwitch, useAppContext,
} from '@viize/views';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const LandingHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAppContext().authContext;

  const handleWelcome = useCallback(() => {
    if (isAuthenticated) navigate(ROUTES.welcome.path);
    else navigate(ROUTES.login.path);
  }, [isAuthenticated, navigate]);

  return (
    <div id="landing-header" className="navbar z-50 sticky top-0">
      <div className="navbar-start"><AppLogo /></div>

      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal [&>*]:rounded-none">
          <li><a className="rounded-none" href="#" onClick={() => scrollTo('overview')}>{ t('header.landing.overview')}</a></li>
          <li><a className="rounded-none" href="#" onClick={() => scrollTo('solutions')}>{ t('header.landing.solution')}</a></li>
          <li><a className="rounded-none" href="#" onClick={() => scrollTo('get-in-touch')}>{ t('header.landing.get-in-touch')}</a></li>
        </ul>
      </div>
      <div className="navbar-end flex flex-row gap-2 justify-end items-center align-middle">
        <LocaleSwitch />
        <button
          type="button"
          onClick={handleWelcome}
          className="btn-sm w-36 hidden lg:inline-flex justify-center items-center gap-1 text-white bg-primary hover:bg-gray-900 font-normal hover:text-white py-2 px-2 borderrounded"
        >
          {t('header.landing.live')}
          <BoltIcon className="h-4 w-4 animate-pulse" />
        </button>
        <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
          <Bars3Icon className="h-5 w-5" />
        </div>
      </div>
    </div>
  );
};
