import React, { useCallback, useState } from 'react';

import { setLocalStorage, getLocalStorage } from '@viize/common';

const initialValue = getLocalStorage('authToken');

const tokenRegex = /[^A-z]/g;
const tokenMinLength = 7;

export type TokenInputProps = {
  /**
   * the token input component.
   */
  onChange?: () => void;
};

const TokenInput = () => {
  const [value, setValue] = useState(initialValue);
  const handleChange = useCallback((e) => {
    const val = String(e.target.value).replace(tokenRegex, '');
    if (val.length >= tokenMinLength) setLocalStorage('authToken', val);
    setValue(val);
  }, []);
  return (
    <div className="flex space-x-2">
      <input
        name="demo-key"
        type="text"
        tabIndex={0}
        required
        placeholder="Enter a demo key"
        onChange={handleChange}
        value={value}
        className="w-40 bg-transparent border-gray-400 rounded-none input border-dashed input-secondary input-bordered input-sm"
      />
    </div>
  );
};

export default TokenInput;
