import React from 'react';

export type StreamsListTabProps = {
  /**
   * video stream tab component.
   */
  active?: boolean,
  onClose?: () => void,
};

const StreamsListTab = ({ active, onClose }: StreamsListTabProps) => (
  <div className={`flex justify-center items-center flex-col  my-2 pt-8 ${active ? 'block' : 'hidden'}`}>
    <p>Not available</p>
    <div className="inline-flex items-center justify-center w-full mt-8 px-8">
      <a
        className="btn btn-sm bg-gray-500 hover:bg-secondary-focus border-none rounded-none"
        onClick={onClose}
        role="button"
        tabIndex={0}
        onKeyDown={undefined}
      >
        Back
      </a>
    </div>
  </div>
);

export default StreamsListTab;
