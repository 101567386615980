import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import styles from './.module.css';

const Help: FC = () => (
  <div className={styles.container}>
    <main className={styles.main}>

      <p className="text-xl text-base-content">Help !</p>
      <p className={styles.message}>
        <b className="text-lg text-black animate-pulse">
          Need help ? Send us a mail, contact@buttertech.fr
        </b>
      </p>
      <Link to="/" className="text-sm">Back</Link>
    </main>
  </div>
);

export default Help;
