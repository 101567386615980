import { config, setHeaders } from '@viize/common';

const { API_URL } = config;

export const getOrganization = (
  id: string,
): Promise<Organization> => fetch(`${API_URL}/organizations/${id}`, {
  method: 'GET',
  headers: setHeaders({}),
}).then((res) => res.json())
  .then((json) => Promise.resolve(json.data))
  .catch((e) => Promise.reject(e));

export const updateOrganization = async (values: Partial<OrganizationBase>):
Promise<Organization> => {
  const body = JSON.stringify(values);
  return fetch(`${API_URL}/organizations/${values.id}`, {
    body,
    method: 'POST',
    headers: setHeaders({}),
  }).then((res) => res.json())
    .then((json) => Promise.resolve(json.data))
    .catch((e) => Promise.reject(e));
};
