import React, { FC } from 'react';

import { ROUTES } from '@viize/common';
import { RegisterForm, Footer } from '@viize/views';
import { useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';

import styles from '../.module.css';

const Register: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <a className={styles.logo} href={ROUTES.landing.path}>
          <img src="/viize.svg" alt="Viize" width={150} height={80} />
          <b className="absolute text-xl text-black self-end ml-6 -mt-4" />
        </a>
        <div className={styles.typing}>
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString(t('form.auth.register-title'))
                .start();
            }}
            options={{
              delay: 10,
            }}
          />
        </div>
        <RegisterForm />
      </main>
      <Footer />
    </div>
  );
};

export default Register;
