import { ActionNames } from '@viize/common';

export const updateStreamValues = (values: Stream, stream: Stream): Stream => {
  const update = { ...stream };
  if (values.actions && update.actions) {
    // update rois
    const roisUpdate = values.actions.object_detection?.counter_config?.rois?.map((rn) => {
      const r = update.actions?.object_detection
        .counter_config?.rois?.find((ro) => ro.id === rn.id);
      return r ? { ...r, ...rn } : rn;
    });
    update.actions = {
      ...update.actions,
      object_detection: {
        ...update.actions.object_detection,
        counter_config: {
          ...update.actions.object_detection.counter_config ?? {},
          rois: roisUpdate,
        },
      },
    };
  }
  return update;
};

export const sortActions = (actions: Actions): ActionId[] => Object.entries(actions)
  .map(([k, v], index) => {
    const vo = {
      ...v,
      order: k === ActionNames.OBJECT_DETECTION ? 0 : v.order ?? index + 1,
    };
    return [k, vo] as [string, any];
  })
  .sort(([, a], [, b]) => a.order - b.order)
  .map(([key]) => key as ActionId);

export const maxPlayerFailsCount = 16;

export const sortReportBySessionDate = (a: StreamReport, b: StreamReport) => {
  if (new Date(a.session_end) < new Date(b.session_end)) {
    return 1;
  } if (new Date(a.session_end) > new Date(b.session_end)) {
    return -1;
  }
  return 0;
};
