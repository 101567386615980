import React from 'react';

import styles from './.module.css';

const Footer = () => (
  <footer className={styles.footer}>
    <a
      href="https://buttertech.ca"
      target="_blank"
      className="text-black inline-block flex-row"
      rel="noopener noreferrer"
    >
      <span className={styles.logo}>
        <img src="/logo-buttertech.png" alt="Buttertech Logo" width={36} height={36} />
      </span>
      <span className="absolute text-xs -mt-6 ml-8">Powered by</span>
      <span>Butter</span>
      tech
    </a>
  </footer>
);

export default Footer;
