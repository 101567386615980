import { setHeaders } from '@viize/common';

export const loadSecureImage = async (
  url: string,
): Promise<Blob> => fetch(url, {
  method: 'GET',
  headers: setHeaders({}),
}).then(async (res) => {
  if (!res.ok) return Promise.reject(await res.json());
  return res.blob();
})
  .catch((e) => Promise.reject(e));

export const loadSecureImageFromUrl = async (
  url: string,
): Promise<string> => {
  const res = await fetch(url, {
    method: 'GET',
    headers: setHeaders({}),
  });
  const blob = await res.blob();
  const imgUrl = URL.createObjectURL(blob);
  return imgUrl;
};
