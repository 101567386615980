/* eslint-disable unused-imports/no-unused-vars */
import React, {
  FC, useEffect, useState,
} from 'react';

import {
  useAppContext,
  DashboardHeader as Header,
} from '@viize/views';
import { Outlet } from 'react-router-dom';

const Dashboard: FC = () => {
  const appContext = useAppContext();
  const { authContext, organizationContext, projectContext } = appContext;

  const { state: authState } = authContext;
  const { state: organizationState, setSelectedOrganization } = organizationContext;
  const { state: projectState, getProjects } = projectContext;

  const user = authState?.user;

  const isLoading = projectState?.isLoading;
  const isError = projectState?.error;
  const projects = projectState?.projects;
  const [loadedOrg, setLoadedOrg] = useState<string | undefined>();

  useEffect(() => {
    if (loadedOrg || isLoading || isError || !getProjects
      || !setSelectedOrganization || !user?.organizations
      || !Object.keys(user.organizations).length) return;
    const organizationId = organizationState.selected ?? Object.keys(user.organizations)[0];
    setLoadedOrg(organizationId);
    getProjects(organizationId).then()
      .finally(() => {
        if (organizationId !== organizationState.selected) {
          setSelectedOrganization(organizationId);
        }
      });
  }, [loadedOrg, projects, isLoading, isError, getProjects,
    setSelectedOrganization, user?.organizations, organizationState.selected]);

  useEffect(() => {
    if (isLoading || isError) return;
    if (loadedOrg && organizationState.selected && organizationState.selected !== loadedOrg) {
      setLoadedOrg(undefined);
    }
  }, [isError, isLoading, loadedOrg, organizationState.selected]);

  return (
    <div className="flex flex-col h-screen w-screen overflow-hidden">
      <Header titleKey="welcome" />
      <main className="h-[100vh] w-screen overflow-y-auto px-4 lg:px-24 py-2 lg:py-3 bg-base-200 dark:bg-base-content text-black">
        <Outlet context={appContext} />
      </main>
    </div>
  );
};

export default Dashboard;
