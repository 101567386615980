import React, { useState } from 'react';

import {
  ListBulletIcon,
} from '@heroicons/react/24/outline';

import {
  StreamsListTab, // IPCameraStreamTab, ViizeBoxStreamTab,
  CustomStreamTab, VideoStreamTab, // VideoUploadStreamTab,
} from './tabs';

export type StreamDialogProps = {
  /**
   * a modal component to add stream streams.
   */
  visible?: boolean,
  onClose?: () => void,
};

const DEFAULT_TAB = 2; // video demo
const tabs = [
  { name: 'All streams', disbaled: false, icon: <ListBulletIcon className="h-5 w-5 mx-0 mr-1" /> },
  // { name: 'Register IP Camera', disbaled: false },
  { name: 'Remote video stream', disbaled: false },
  // { name: 'Register a Viize box', disbaled: false },
  { name: 'Local video stream (demo)', disbaled: false },
  // { name: 'Upload a video (demo)', disbaled: false },
];
const StreamDialog = ({ visible, onClose }: StreamDialogProps) => {
  const [tab, setTab] = useState<number>(DEFAULT_TAB);
  return (
    <div className={`bg-white ${visible ? 'block' : 'hidden'}`}>
      <div
        id="stream-dialog"
        className={`fixed top-16 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white
      flex flex-col items-center justify-start pt-5`}
      >
        <div className="tabs px-4">
          {tabs.map((t, i) => (
            <a
              key={String(t.name)}
              className={`tab tab-bordered ${tab === i ? 'tab-active' : ''}`}
              onClick={t.disbaled ? undefined : () => setTab(i)}
              role="button"
              tabIndex={i - tabs.length}
              onKeyDown={undefined}
            >
              {t.icon && t.icon}
              {t.name}
            </a>
          ))}
        </div>

        <StreamsListTab active={tab === 0} onClose={onClose} />
        {/* <IPCameraStreamTab active={tab === 1} onClose={onClose} /> */}
        <CustomStreamTab active={tab === 1} onClose={onClose} />
        {/* <ViizeBoxStreamTab active={tab === 3} onClose={onClose} /> */}
        <VideoStreamTab active={tab === 2} onClose={onClose} />
        {/* <VideoUploadStreamTab active={tab === 5} onClose={onClose} /> */}

      </div>
    </div>
  );
};

export default StreamDialog;
