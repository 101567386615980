import { FederatedPointerEvent } from '@pixi/events';
import {
  Graphics, Point as PixiPoint, Text, TextStyle,
} from 'pixi.js';

export const DEFAULT_COLOR = 0xF3F4F6;
export const DEFAULT_ALPHA = 0.6;
export const DEFAULT_HOVER_ALPHA = 0.5;
export const DEFAULT_LINE_STYLE = { width: 1, color: DEFAULT_COLOR };
export const DEFAULT_CURSOR_LINE_STYLE = { color: 0xffffff, alpha: 0.87, width: 1 };
export const DEFAULT_TEXT_POLICE = {
  fontSize: 12, fill: 0xffffff, align: 'center', fontFamily: 'Arial',
};
export const MAX_SHAPE_POINTS = 4;

// eslint-disable-next-line unused-imports/no-unused-vars
export function getCenterPoint(p: PixiPoint[]) {
  // Set initial min and max values
  let minX = p[0].x; let maxX = p[0].x; let minY = p[0].y; let
    maxY = p[0].y;

  p.forEach((pi) => {
    if (pi.x < minX) { minX = pi.x; }
    if (pi.x > maxX) { maxX = pi.x; }
    if (pi.y < minY) { minY = pi.y; }
    if (pi.y > maxY) { maxY = pi.y; }
  });
  return new PixiPoint((maxX + minX) / 2, (maxY + minY) / 2);
}

export const createShapeFrom = (type: DrawType, id: string, points: PixiPoint[]): ROI => {
  const roi = {
    id,
    points,
    type,
  };
  switch (type) {
    case 'polygon':
      return {
        ...roi,
        name: `R${id}`,
        color: 'white',
      };
    default:
      return {
        ...roi,
        name: `${id}`,
        color: 'gray',
        type: 'polygon',
      };
  }
};

export const drawLineShape = (
  cursor: Graphics,
  source: PixiPoint,
  target: PixiPoint,
) => {
  if (!cursor) return;
  const g = cursor;
  g.clear();
  g.alpha = DEFAULT_ALPHA;
  g.lineStyle(DEFAULT_LINE_STYLE);
  g.buttonMode = true;
  g.moveTo(source.x, source.y);
  g.lineTo(target.x, target.y);
};

export const addDefaultListeners = (shape: Graphics) => {
  const g = shape;
  g.on('pointerover', () => {
    g.alpha = DEFAULT_HOVER_ALPHA;
  });
  g.on('pointerout', () => {
    g.alpha = DEFAULT_ALPHA;
  });
};

export const addSelectListener = (
  shape: Graphics,
  selectCb: (event: FederatedPointerEvent, g: Graphics) => void,
) => {
  shape.on('pointerdown', (e) => selectCb(e, shape));
};

export const addShapeTitle = (shape: Graphics, title: string, points?: PixiPoint[]) => {
  const g = shape;
  const text = g.addChild(new Text(title, DEFAULT_TEXT_POLICE as TextStyle));
  if (points) text.position.copyFrom(getCenterPoint(points));
  text.anchor.set(0.5, 0.5);
};

export const drawPointShape = (g: Graphics, origin: PixiPoint, name?: string) => {
  const c = g;
  c.visible = false;
  if (name) c.name = name;
  c.beginFill(DEFAULT_COLOR);
  c.lineStyle(DEFAULT_CURSOR_LINE_STYLE);
  c.drawCircle(origin.x, origin.y, 4);
  c.endFill();
  return c;
};

export const drawPolygonShape = (
  cursor: Graphics,
  points: PixiPoint[],
) => {
  const g = cursor;
  g.clear();
  g.alpha = DEFAULT_ALPHA;
  g.lineStyle(DEFAULT_LINE_STYLE);
  g.beginFill(DEFAULT_COLOR);
  g.interactive = true;
  g.buttonMode = true;
  g.drawPolygon(points);
  g.endFill();
  return g;
};

export const addEdges = (
  g: Graphics,
  points: PixiPoint[],
  visible = true,
) => {
  points.forEach((p) => {
    const pg = drawPointShape(new Graphics(), p, undefined);
    pg.visible = visible;
    g.addChild(pg);
  });
};
