import { ActionNames } from '@viize/common';

export const delay = (f: () => void, time: number) => new Promise((rs) => {
  setTimeout(() => rs(f()), time);
});

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// format date for displaying in stats
export const dateFromIso = (isoStr: string, local = false): Date => (
  local ? new Date(isoStr.slice(0, -1)) : new Date(isoStr)
);

export const formatDateInterval = (start: string, end: string): string => {
  const fstart = dateFromIso(start);
  const fend = dateFromIso(end);
  return `${fstart.getHours()}:${fstart.getMinutes()}-${fend.getHours()}:${fend.getMinutes()}`;
};

export const groupReportsByDates = (reps: StreamReport[], actionId: string):
{ [date: string]: GroupedReport } => {
  const group: { [date: string]: GroupedReport } = {};
  reps.filter((v) => v.action_name === actionId).forEach((r) => {
    const date = dateFromIso(r.session_start).toDateString(); // .split(' ')[0];
    const ids = group[date] ? group[date].ids : [];
    if (!ids.includes(date)) ids.push(r.id);
    group[date] = { date, ids };
  });
  // { new Date().toISOString().split('T')[0]}
  return group;
};

export const formatDetection = (det: DetectionObject, session: string) => {
  let fileName = session.trim().slice(0, 19).replace(' ', '@').replace('T', '@');
  fileName = `${fileName}@${det.time}_${det.id}_${det.name}_${det.conf}_${det.rid}`;
  // console.log('formatDetection', fileName);
  // const key = crypto.randomUUID();
  const duration = det.duration ? Math.ceil(det.duration * 100) / 100 : undefined;
  const speed = det.speed ? Math.ceil(det.speed * 100) / 100 : undefined;
  return {
    ...det, file_name: fileName, duration, speed,
  }; // , key
};

export const formatDetections = (
  dets: DetectionObject[],
  session: string,
) => dets.map((d) => formatDetection(d, session));

export const formatReportDetections = (rid_dets: Detections, session: string) => {
  const vals: DetectionObject[] = [];
  Object.values(rid_dets).forEach((dets) => Array.isArray(dets) && dets
    .forEach((_det) => vals.push(formatDetection(_det, session))));
  return vals;
};

export const formatReportDetectionsByActions = (
  reportData: StreamReportData,
  dets: DetectionObject[],
): DetectionObject[] => dets.map((det) => {
  const d = { ...det };
  Object.keys(reportData).forEach((id) => {
    if (det.id && reportData[id].dets && reportData[id].dets[det.id]) {
      if (id === ActionNames.CARBON_EMISSION) d.co2 = (reportData[id].dets as any)[det.id];
      if (id === ActionNames.NUMBER_PLATE) d.vin = (reportData[id].dets as any)[det.id];
    }
  });
  return d;
});

/**
 * @name filterPartsByKey
 * @param query string
 * @param items Array<{code: string; label: string}>
 * @description filter a list of items from a given string query
 */
export const filterDetectionsByQuery = (
  query: string,
  dets: DetectionObject[],
): DetectionObject[] => {
  if (query.length >= 2) {
    const regex = new RegExp(`${query.trim()}`, 'i');
    return dets.filter((d) => `${d.name}_${d.file_name}_${d.conf}`.search(regex) >= 0);
  }
  return dets;
};

export const getTimestamp = (f: string) => f.split('_')[1]; // 49_05:25:21_truck_0.26.jpg => 05:25:21

export const today = () => new Date().toDateString(); // .split('T')[0];

export const getKey = (det: DetectionObject) => `${det.time}_${det.id}_${det.name}_${det.conf}_${det.rid}`;

export const isChrome = () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const camelCase = (str: string) => str.replace(str[0], str[0].toUpperCase());

// For Intl18-next passing variable keys typescript complain
export const normalizeKey = (key: string) => key as unknown as TemplateStringsArray;
