import {
  ApiStatusCodes, getConfig, getBearerToken, Status,
} from '@viize/common';

const { API_HOST, WS_PREFIX, API_VERSION } = getConfig();

export const StreamClient = (
  streamId: string,
  customParams: {
    url?: string | undefined,
  },
  callbacks: {
    onOpen?: (connection: WebSocket) => void,
    onReady?: (streamCfg: any) => void,
    onUnknown?: () => void,
    onFrame?: (frame: any) => void,
    onData?: (data: any) => void,
    onPreload?: (preloadFrame: any) => void,
    onError?: (error: any) => void,
    onClose?: () => void,
  },
): { socket: WebSocket, sendData: (data:any
  ) => void } | undefined => {
  const {
    onOpen, onReady, onFrame, onData, onUnknown, onPreload, onError, onClose,
  } = callbacks;

  // Signaling methods
  const url = `${API_VERSION}/ws_api/${streamId}`;
  let socket: WebSocket;

  try {
    socket = new WebSocket(`${WS_PREFIX}://${API_HOST}/${customParams.url ?? url}`);
  } catch (e) {
    return undefined;
  }

  const sendData = (data: any) => {
    if (socket.readyState === WebSocket.OPEN) socket.send(JSON.stringify(data));
  };

  const closePeerConnection = () => {
    sendData({
      type: Status.DISCONNECT,
    });
    socket.close();
    console.log('Connection closed');
  };

  const handleSocketError = (error: Event) => {
    console.error('handleSocketError', error);
    if (onError) onError(error);
    closePeerConnection();
  };

  const handleSocketClose = () => {
    if (onClose) onClose();
  };

  socket.onopen = () => {
    const token = getBearerToken();
    if (!token) throw new Error('Requested auth header, with missing bearer');
    sendData({
      type: Status.TOKEN,
      data: { token },
    });
    console.info(`Connected signal - (${streamId})`);
    if (onOpen) onOpen(socket);
  };

  const handleSignalingData = (data: any) => {
    switch (data.type) {
      case Status.DATA:
        if (onData) onData(data.value);
        break;
      case Status.READY:
        if (onReady) onReady(data.msg);
        break;
      case Status.OK:
        console.warn(Status.OK, data);
        break;
      case Status.PRELOAD:
        if (onPreload) {
          // for now we preload like this
          const preload = `data:image/jpeg;charset=utf-8;base64,${data.value}`;
          onPreload(preload);
        }
        break;
      case Status.UNKNOWN:
        if (onUnknown) onUnknown();
        break;
      case Status.ERROR:
        if (
          data.status_code === ApiStatusCodes.NOT_AUTHORIZED
          || data.status_code === ApiStatusCodes.FORBIDEN) {
          console.error('AUTH error', data);
          break;
        }
        if (onError) onError(data);
        break;
      default:
        console.warn('UNKNOWN SIGNALING DATA TYPE', data);
        break;
    }
  };

  socket.onmessage = (event: MessageEvent) => {
    try {
      if (event.data instanceof Object && onFrame) onFrame(event.data);
      else {
        const data = JSON.parse(event.data);
        console.debug('Data received: ', data);
        handleSignalingData(data);
      }
    } catch (error) {
      console.error('error handling message', event.data, error);
    }
  };

  socket.onerror = handleSocketError;
  socket.onclose = handleSocketClose;

  // Call 'close_connection' endpoint to inform server that we are refreshing page
  window.onbeforeunload = closePeerConnection;

  return { sendData, socket };
};

export default StreamClient;
