import React from 'react';

import { ROUTES } from '@viize/common';
import { ProtectedRoute, Loading } from '@viize/views';
import { createBrowserRouter } from 'react-router-dom';

import App from './App';
import {
  Landing, Dashboard, NotFound, Error, Login, Register, Help,
} from './screens';
import { Welcome } from './screens/Dashboard/pages';

const Live = React.lazy(() => import('./screens/Live'));

const router = createBrowserRouter([
  {
    element: <React.Suspense fallback={<Loading />}><App /></React.Suspense>,
    path: ROUTES.landing.path,
    children: [
      {
        path: ROUTES.landing.path,
        element: <Landing />,
      },
      {
        path: ROUTES.login.path,
        element: <Login />,
      },
      {
        path: ROUTES.register.path,
        element: <Register />,
      },
      {
        path: ROUTES.resetPassword.path,
        element: <Help />,
      },
      {
        path: ROUTES.quote.path,
        element: <Help />,
      },
      {
        path: ROUTES.welcome.path,
        element: <ProtectedRoute component={Dashboard} />,
        children: [
          {
            path: ROUTES.welcome.path,
            element: <Welcome />,
          },
        ],
      },
      {
        path: ROUTES.live.base,
        element: (
          <React.Suspense fallback={<Loading />}>
            <ProtectedRoute component={Live} />
          </React.Suspense>
        ),
        children: [
          {
            path: ROUTES.live.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <ProtectedRoute component={Live} />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
    errorElement: <Error />,
  },
  {
    path: ROUTES.notFound.path,
    element: <NotFound />,
  },
]);

export default router;
