import { createContext, useReducer } from 'react';

import {
  CountingActionsTypes, CountingContextStore, countingReducer,
  formatDetections,
  initialCountingState, maxActivitiesDisplay,
} from '@viize/common';

export const useCounting = () => {
  const [state, dispatch] = useReducer(countingReducer, initialCountingState());

  const setIsCounting = (value?: boolean): void => {
    dispatch({ type: CountingActionsTypes.SET_IS_COUNTING, payload: value });
  };

  const updateStats = async (results: ActionResults, actionIds: ActionId[]) => {
    const { stats, detections } = state;
    const currentSession = results.session_start;
    actionIds.forEach((actionId, order: number) => {
      Object.entries(results[order].counts).forEach(([roidId, values]) => {
        stats[actionId] = stats[actionId] ?? { [roidId]: {} };
        const currentStats = stats[actionId][roidId] ?? {};
        Object.entries(values).forEach(([label, value]) => {
          const currentLabel = currentStats[label] ?? {};
          currentLabel.count = value as number;
          currentStats[label] = currentLabel;
        });
        stats[actionId][roidId] = currentStats;
        dispatch({
          type: CountingActionsTypes.SYNC_COUNTING_STATS,
          payload: { stats, currentSession },
        });
      });
      if (results[order].dets) {
        const formatedDetections = formatDetections(results[order].dets, currentSession).reverse();
        const currentDetections = detections[actionId] ?? [];
        detections[actionId] = currentDetections.length > maxActivitiesDisplay
          ? formatedDetections : [
            ...formatedDetections,
            ...currentDetections,
          ];
      }
    });
    dispatch({
      type: CountingActionsTypes.SYNC_ACTIVITIES,
      payload: { detections, currentSession },
    });
  };

  const resetState = () => {
    dispatch({ type: CountingActionsTypes.RESET_STATE });
  };

  return {
    state,
    updateStats,
    setIsCounting,
    resetState,
  };
};

export const CountingContext = createContext<Partial<CountingContextStore>>({});

export default useCounting;
