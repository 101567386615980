/* eslint-disable max-len */
import React, {
  FormEvent, useCallback, useEffect, useState,
} from 'react';

import {
  UserIcon, KeyIcon,
} from '@heroicons/react/24/outline';
import { ROUTES } from '@viize/common';
import { Input, useAppContext } from '@viize/views';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const LoginForm = () => {
  const { state, isAuthenticated, login } = useAppContext().authContext;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const error = state?.error;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = useCallback((event: FormEvent) => {
    event.preventDefault();
    navigate(ROUTES.register.path);
  }, [navigate]);

  const handleSubmit = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    if (!login) return;
    try {
      const creds: AuthCredential = { username, password };
      await login(creds);
    } catch (err) {
      console.log('Login failed!', err);
    }
  }, [login, password, username]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const from = location.state?.from || ROUTES.welcome.path;
    navigate(from);
  }, [isAuthenticated, location.state?.from, navigate]);

  return (
    <form onSubmit={handleSubmit} className="h-full lg:w-3/6 w-full flex flex-col items-center justify-center align-middle space-x-2 gap-2">
      <Input
        required
        name="username"
        type="email"
        placeholder={t('form.auth.email')}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="input-md"
        startIcon={<UserIcon className="w-5 h-5 opacity-70 mr-1" />}
      />
      <Input
        required
        name="password"
        type="password"
        placeholder={t('form.auth.password')}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="input-md"
        startIcon={<KeyIcon className="w-5 h-5 opacity-70" />}
        bottomChild={(
          <div className="label">
            <a className="label-text-alt dark:text-gray-600 ml-auto" href={ROUTES.resetPassword.path}>{ t('form.auth.help') }</a>
          </div>
)}
      />
      {error && <p className="text-error">{error}</p>}
      <div className="mt-5 btn-group-horizontal flex flex-row lg:w-3/6 lg:justify-between align-middle items-center gap-2">
        <button
          onClick={handleRegister}
          type="button"
          className="btn-ghost btn-xl text-black hover:bg-gray-900 hover:text-white py-2 px-2 lg:px-8"
        >
          { t('form.auth.register') }
        </button>
        <button
          type="submit"
          className="btn-xl text-white bg-primary hover:bg-gray-900 font-normal py-2 px-2 lg:px-8 disabled:opacity-25"
          disabled={state?.isLoading}
        >
          { t('form.auth.continue') }
        </button>
      </div>
    </form>
  );
};
