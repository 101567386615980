export type ProjectState = {
  projects: { [id: string]: Project };
  selected?: string;
  isLoading?: boolean;
  error?: any;
};

export enum ProjectActionsTypes {
  GET_PROJECTS = 'GET_PROJECTS',
  CREATE_PROJECT = 'CREATE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  RESET_STATE = 'RESET_STATE',
}

export type ProjectActionType = {
  type: ProjectActionsTypes;
  payload?: { [id: string]: Project } | Partial<Project> | string | { id: string } | boolean;
};

export const initialProjectState = (): ProjectState => ({
  projects: {},
  selected: undefined,
  isLoading: false,
  error: undefined,
});

export const projectReducer = (
  state: ProjectState,
  action: ProjectActionType,
): ProjectState => {
  console.debug(action.type, action.payload);
  switch (action.type) {
    case ProjectActionsTypes.GET_PROJECTS: {
      const projects = action.payload as { [id: string]: Project };
      const selected = (Object.keys(projects).length && !state.selected)
        ? Object.keys(projects)[0] : state.selected;
      return {
        ...state,
        projects,
        selected,
        isLoading: false,
      };
    }
    case ProjectActionsTypes.CREATE_PROJECT: {
      const project = action.payload as Project;
      if (!project.id) return state;
      return {
        ...state,
        projects: {
          ...state.projects,
          [project.id]: project,
        },
        selected: project.id,
        isLoading: false,
      };
    }
    case ProjectActionsTypes.UPDATE_PROJECT: {
      const project = action.payload as Project;
      if (!project.id) return state;
      return {
        ...state,
        projects: {
          ...state.projects,
          [project.id]: {
            ...state.projects[project.id],
            ...project,
          },
        },
        selected: project.id,
        isLoading: false,
      };
    }
    case ProjectActionsTypes.SET_SELECTED_PROJECT: {
      return {
        ...state,
        selected: action.payload as string,
        // isPlaying: false,
      };
    }
    case ProjectActionsTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload as boolean, error: undefined };
    case ProjectActionsTypes.SET_ERROR:
      return { ...state, isLoading: false, error: action.payload as any };
    case ProjectActionsTypes.RESET_STATE:
      return initialProjectState();
    default:
      return state;
  }
};

export interface ProjectContextStore {
  state: ProjectState;
  createProject?: (values: Partial<Project>) => Promise<{
    id: string, dispatch: () => void
  }>;
  updateProject?: (values: Partial<Project>, persist?: boolean) => Promise<void>;
  getProjectById?: (id: string) => Promise<Project | undefined>;
  getProjects?: (organizationId: string) => Promise<{ [id: string]: Project } | undefined>;
  selectProjectById?: (id: string) => Project | undefined;
  setSelectedProject?: (id?: string) => void;
  setIsLoading?: (loading?: boolean) => void;
  setError?: (error?: any) => void;
  resetState?: () => void;
}
