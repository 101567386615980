import React, { useCallback } from 'react';

import { UserCircleIcon } from '@heroicons/react/24/outline';
import { AppContext, Select } from '@viize/views';
import { useOutletContext } from 'react-router-dom';

interface SwitcherProps {
  /**
   * a component to display projects list and allow project and organization selection
   */
  onSwitch?: () => void,
}

export const Switcher = ({ onSwitch }: SwitcherProps) => {
  const { authContext, organizationContext, projectContext } = useOutletContext<AppContext>();
  const { state: authState } = authContext;
  const { state: organizationState, setSelectedOrganization } = organizationContext;
  const { state: projectState, setSelectedProject } = projectContext;

  const { user } = authState;
  const organizations = user?.organizations;
  const projects = Object.values(projectState.projects);

  const handleSelectOrganization = useCallback((id: string) => {
    if (setSelectedOrganization && setSelectedProject) {
      setSelectedOrganization(id);
      // setSelectedProject(undefined);
    }
  }, [setSelectedOrganization, setSelectedProject]);

  const handleSelectProject = useCallback((id: string) => {
    if (setSelectedProject) {
      setSelectedProject(id);
      if (onSwitch) onSwitch();
    }
  }, [setSelectedProject, onSwitch]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="max-w-fit justify-start items-start border border-gray-200 dark:border-gray-500">
        <Select
          title="Select organization"
          displayKey="name"
          idKey="id"
          valKey="id"
          onChange={handleSelectOrganization}
          value={organizationState.selected}
          items={organizations ? Object.values(organizations) : []}
          className="mx-auto h-7"
        />
      </div>
      <hr className="my-3" />
      <p className="text-sm mb-2">
        Select a project
      </p>
      <ul className="menu w-full gap-2 p-0 [&_li>*]:rounded-none">
        {projects.map((p) => (
          <li
            key={p.id}
          >
            <a
              onClick={() => handleSelectProject(p.id)}
              href="#"
              className={`border-l-4 ${p.id === projectState.selected
                ? 'border-gray-400' : 'border-transparent'}`}
            >
              {p.name}
            </a>
          </li>
        )) }
      </ul>
      <hr className="my-3 mt-auto" />
      {user && (
      <p className="text-sm inline-flex items-center gap-2">
        <UserCircleIcon className="h-6 w-6" />
          {user.firstname}
          {' '}
          {user.lastname}
      </p>
      )}

    </div>
  );
};
