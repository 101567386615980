export enum Status {
  OK = 'ok',
  DATA = 'data',
  READY = 'ready',
  ERROR = 'error',
  TOKEN = 'token',
  PRELOAD = 'preload',
  DISCONNECT = 'disconnect',
  UPDATE_STREAM = 'update_stream',
  UNKNOWN = 'unknown',
  PRELOAD_STREAM = 'preload_stream',
}

export enum ActionNames {
  OBJECT_DETECTION = 'object_detection',
  NUMBER_PLATE = 'number_plate',
  CARBON_EMISSION = 'carbon_emission',
  WEIGHT_PRESSURE = 'weight_pressure',
  FACE_ID = 'face_id',
  FACE_ID2 = 'face_id2',
  SPEED_TRACK = 'speed_track',
  INCIDENT_REPORT = 'incident_report',
}
