import React from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // Add any specific props you want to accept (e.g., label, type)
  type?: string; // "text", "password", etc.
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  bottomChild?: React.ReactElement;
  labelClasses?: string;
}

export const Input: React.FC<InputProps> = ({
  type, name, startIcon, endIcon, bottomChild, labelClasses, ...rest
}) => (
  <label
    htmlFor={name}
    className={`form-control w-full max-w-xs ${labelClasses ?? ''}`}
  >
    <div className="label gap-2">
      {startIcon && startIcon}
      <input id={name} type={type || 'text'} {...rest} className={`${rest.className} w-full max-w-xs border border-primary-focus`} />
      {endIcon && endIcon}
    </div>
    { bottomChild && bottomChild }
  </label>
);
